import { AccountingPeriod } from "../models/AccountingPeriod.model";
import { getMoment } from ".";
import { RealEstateAmortisation } from "../models/RealEstateAmortisation.model";
import { RealEstateAsset } from "../models/RealEstateAsset.model";
import { TaxRegime } from "../models/TaxRegime.enum";
import Decimal from "decimal.js-light";

type amortizationDaysStatsForThisAccountingPeriod = {
  amortizedDays: number;
  daysInThisAccountingPeriod: number;
  remainingDaysToAmortize: number;
};

export type amortizationValuesStatsForThisAccountingPeriodDetail = {
  valueAmortized: number;
  valueToAmortizedForThisAccountingPeriod: number;
  remainingValueToAmortize: number;
} & Pick<RealEstateAmortisation, "type" | "realEstateAssetId">;

type amortizationValuesStatsForThisAccountingPeriod = {
  valueAmortized: number;
  valueToAmortizedForThisAccountingPeriod: number;
  remainingValueToAmortize: number;
  details: amortizationValuesStatsForThisAccountingPeriodDetail[];
};

const amortizationDaysStatsForAnAccountingPeriodRealEstate = (
  realEstateAsset: RealEstateAsset,
  accountingPeriod: AccountingPeriod,
  yearsAmortization: number
) => {
  const res: amortizationDaysStatsForThisAccountingPeriod = {
    amortizedDays: 0,
    daysInThisAccountingPeriod: 0,
    remainingDaysToAmortize: 0,
  };
  const referenceDate =
    accountingPeriod.taxRegime === TaxRegime.LMNP_2031
      ? realEstateAsset.entryDateActivityLmnp
      : realEstateAsset.commissioningAt;

  if (referenceDate) {
    if (getMoment(referenceDate) < getMoment(accountingPeriod.startAt)) {
      res.daysInThisAccountingPeriod =
        getMoment(accountingPeriod.endAt).diff(getMoment(accountingPeriod.startAt), "days") + 1;
    } else {
      res.daysInThisAccountingPeriod = getMoment(accountingPeriod.endAt).diff(getMoment(referenceDate), "days") + 1;
    }

    res.amortizedDays = getMoment(accountingPeriod.startAt).diff(getMoment(referenceDate), "days");
    if (res.amortizedDays < 0) {
      res.amortizedDays = 0;
    }
    res.remainingDaysToAmortize = getMoment(referenceDate)
      .add(yearsAmortization, "years")
      .diff(getMoment(referenceDate).add(res.amortizedDays, "days"), "days");
    if (res.remainingDaysToAmortize < 0) {
      res.remainingDaysToAmortize = 0;
    }
    if (res.daysInThisAccountingPeriod < 0) {
      res.daysInThisAccountingPeriod = 0;
    }
    return res;
  }
};

export const sumAllAmortizationValueStatsForAnAccountingPeriodRealEstate = (
  accountingPeriods: AccountingPeriod[],
  accountingPeriodIdToSum: string,
  realEstateAssets: RealEstateAsset[],
  realEstateAmortisations: RealEstateAmortisation[]
) => {
  const targetedAccountingPeriod = accountingPeriods.find(
    (accountingPeriod) => accountingPeriod.id === accountingPeriodIdToSum
  );
  if (!targetedAccountingPeriod) throw new Error("Accounting period not found");

  const res: amortizationValuesStatsForThisAccountingPeriod = {
    valueAmortized: 0,
    valueToAmortizedForThisAccountingPeriod: 0,
    remainingValueToAmortize: 0,
    details: [],
  };
  for (const realEstateAsset of realEstateAssets) {
    const referenceDate =
      targetedAccountingPeriod.taxRegime === TaxRegime.LMNP_2031
        ? realEstateAsset.entryDateActivityLmnp
        : realEstateAsset.commissioningAt;
    if (referenceDate) {
      const amortizations = realEstateAmortisations.filter(
        ({ realEstateAssetId }) => realEstateAssetId === realEstateAsset.id
      );
      if (amortizations.length > 0) {
        for (const amortization of amortizations) {
          const detail: typeof res["details"][number] = {
            type: amortization.type,
            realEstateAssetId: amortization.realEstateAssetId,
            valueAmortized: 0,
            valueToAmortizedForThisAccountingPeriod: 0,
            remainingValueToAmortize: 0,
          };

          const daysStats = amortizationDaysStatsForAnAccountingPeriodRealEstate(
            realEstateAsset,
            targetedAccountingPeriod,
            amortization.durationInYear
          );
          if (daysStats) {
            const allDays = daysStats.amortizedDays + daysStats.remainingDaysToAmortize;
            // res.valueAmortized =
            //   res.valueAmortized + Math.round((amortization.value / allDays) * daysStats.amortizedDays);
            res.valueAmortized = new Decimal(amortization.value)
              .div(allDays)
              .mul(daysStats.amortizedDays)
              .toInteger()
              .add(res.valueAmortized)
              .toNumber();
            detail.valueToAmortizedForThisAccountingPeriod = new Decimal(amortization.value)
              .div(allDays)
              .mul(daysStats.daysInThisAccountingPeriod)
              .toInteger()
              .toNumber();
            if (daysStats.daysInThisAccountingPeriod < daysStats.remainingDaysToAmortize) {
              res.valueToAmortizedForThisAccountingPeriod = new Decimal(amortization.value)
                .div(allDays)
                .mul(daysStats.daysInThisAccountingPeriod)
                .toInteger()
                .add(res.valueToAmortizedForThisAccountingPeriod)
                .toNumber();
              detail.valueToAmortizedForThisAccountingPeriod = new Decimal(amortization.value)
                .div(allDays)
                .mul(daysStats.daysInThisAccountingPeriod)
                .toInteger()
                .toNumber();
            } else {
              res.valueToAmortizedForThisAccountingPeriod = new Decimal(amortization.value)
                .div(allDays)
                .mul(daysStats.remainingDaysToAmortize)
                .toInteger()
                .add(res.valueToAmortizedForThisAccountingPeriod)
                .toNumber();
              detail.valueToAmortizedForThisAccountingPeriod = new Decimal(amortization.value)
                .div(allDays)
                .mul(daysStats.remainingDaysToAmortize)
                .toInteger()
                .toNumber();
            }
            res.remainingValueToAmortize = new Decimal(amortization.value)
              .div(allDays)
              .mul(daysStats.remainingDaysToAmortize)
              .toInteger()
              .add(res.remainingValueToAmortize)
              .toNumber();
            detail.remainingValueToAmortize = new Decimal(amortization.value)
              .div(allDays)
              .mul(daysStats.remainingDaysToAmortize)
              .toInteger()
              .toNumber();
          }
          res.details.push(detail);
        }
      }
    }
  }
  return res;
};

export const sumAmortizationValueStatsForARealEstateAsset = ({
  realEstateAmortisations,
  accountingPeriods,
  accountingPeriodIdToSum,
  realEstateAssets,
  realEstateAssetIdToSum,
}: {
  realEstateAmortisations: RealEstateAmortisation[];
  accountingPeriods: AccountingPeriod[];
  accountingPeriodIdToSum: string;
  realEstateAssets: RealEstateAsset[];
  realEstateAssetIdToSum: string;
}) => {
  const realEstateAssetsTemp = realEstateAssets.filter(({ id }) => id === realEstateAssetIdToSum);
  const realEstateAmortisationsTemp = realEstateAmortisations.filter(
    ({ realEstateAssetId }) => realEstateAssetId === realEstateAssetIdToSum
  );
  return sumAllAmortizationValueStatsForAnAccountingPeriodRealEstate(
    accountingPeriods,
    accountingPeriodIdToSum,
    realEstateAssetsTemp,
    realEstateAmortisationsTemp
  );
};
export const sumAllAmortisationsExpenseRealEstate = ({
  realEstateAmortisations,
  accountingPeriods,
  accountingPeriodIdToSum,
  realEstateAssets,
}: {
  realEstateAmortisations: RealEstateAmortisation[];
  accountingPeriods: AccountingPeriod[];
  accountingPeriodIdToSum: string;
  realEstateAssets: RealEstateAsset[];
}): number => {
  const targetedAccountingPeriod = accountingPeriods.find(
    (accountingPeriod) => accountingPeriod.id === accountingPeriodIdToSum
  );
  if (!targetedAccountingPeriod) throw new Error("Accounting period not found");

  const targetedAccountingPeriodStartAt = getMoment(targetedAccountingPeriod.startAt);
  const targetedAccountingPeriodEndAt = getMoment(targetedAccountingPeriod.endAt);

  // Sum all amortisations for the current accounting periods
  return realEstateAmortisations
    .filter((amortisation) => {
      const realEstate = realEstateAssets.find(({ id }) => id === amortisation.realEstateAssetId);
      const referenceDate =
        targetedAccountingPeriod.taxRegime === TaxRegime.LMNP_2031
          ? realEstate?.entryDateActivityLmnp
          : realEstate?.commissioningAt;
      const amortisationStartAt =
        (amortisation.startAt || referenceDate) && getMoment(amortisation.startAt || referenceDate);

      // Must not exist
      if (!amortisationStartAt) {
        console.warn(
          "Amortisation without startAt and realEstateAssets without commissioningAt",
          amortisation.id,
          realEstate?.id
        );
        return false;
      }

      // We add 1 year because the amortisation should be valid one year after the end of the amortisation if the first year is half amortisation
      const amortisationEndAt = getMoment(amortisationStartAt).add(amortisation.durationInYear + 1, "years");

      // Check if the amortisation is in the targeted accounting period
      return (
        amortisationStartAt.isSameOrBefore(targetedAccountingPeriodEndAt) &&
        amortisationEndAt.isSameOrAfter(targetedAccountingPeriodStartAt)
      );
    })
    .reduce((acc, amortisation) => {
      const realEstate = realEstateAssets.find(({ id }) => id === amortisation.realEstateAssetId);
      const referenceDate =
        targetedAccountingPeriod.taxRegime === TaxRegime.LMNP_2031
          ? realEstate?.entryDateActivityLmnp
          : realEstate?.commissioningAt;
      const amortisationStartAt =
        (amortisation.startAt || referenceDate) && getMoment(amortisation.startAt || referenceDate);
      if (!amortisationStartAt) return acc;

      const amortisationEndAt = getMoment(amortisationStartAt).add(amortisation.durationInYear, "years");

      // Get the accounting period when declare, could be different from the startAt
      const accountingPeriod = accountingPeriods.find(({ id }) => id === amortisation.accountingPeriodIdAllowedToEdit);

      const currentAccountingPeriodStartAt = getMoment(accountingPeriod?.startAt);
      const currentAccountingPeriodEndAt = getMoment(accountingPeriod?.endAt);

      // Calculate the date of the middle of the accounting period
      const currentAccountingPeriodHalfYear = currentAccountingPeriodStartAt.add(
        currentAccountingPeriodEndAt.diff(currentAccountingPeriodStartAt, "days") / 2,
        "days"
      );
      const firstYearMustBeHalfAmortisation =
        amortisationStartAt &&
        currentAccountingPeriodHalfYear.isValid() &&
        amortisationStartAt.isValid() &&
        amortisationStartAt.isBetween(
          getMoment(currentAccountingPeriodHalfYear).set("year", amortisationStartAt.year()),
          getMoment(currentAccountingPeriodEndAt).set("year", amortisationStartAt.year()),
          undefined,
          "[]"
        );

      // If the amortisation is not finished, we sum it
      if (targetedAccountingPeriodStartAt.isSameOrBefore(amortisationEndAt)) {
        // If it is the first year, we must take into account only half of the amortisation
        if (
          amortisationStartAt.isBetween(
            currentAccountingPeriodStartAt,
            currentAccountingPeriodEndAt,
            undefined,
            "[]"
          ) &&
          firstYearMustBeHalfAmortisation
        ) {
          return new Decimal(acc)
            .add(amortisation.value)
            .div(new Decimal(2).mul(amortisation.durationInYear))
            .toNumber();
        }
        return new Decimal(acc).add(amortisation.value).div(amortisation.durationInYear).toNumber();
      }

      // If we are at the last + 1 year of the amortisation, we must check if first year has been half amortisation
      if (firstYearMustBeHalfAmortisation && targetedAccountingPeriodStartAt.year() === amortisationEndAt.year() + 1) {
        return new Decimal(acc).add(amortisation.value).div(new Decimal(2).mul(amortisation.durationInYear)).toNumber();
      }

      return acc;
    }, 0);
};

export const sumAmortisationsExpenseForRealEstateAsset = ({
  realEstateAmortisations,
  accountingPeriods,
  accountingPeriodIdToSum,
  realEstateAssets,
  realEstateAssetIdToSum,
}: {
  realEstateAmortisations: RealEstateAmortisation[];
  accountingPeriods: AccountingPeriod[];
  accountingPeriodIdToSum: string;
  realEstateAssets: RealEstateAsset[];
  realEstateAssetIdToSum: string;
}): number => {
  return sumAllAmortisationsExpenseRealEstate({
    realEstateAmortisations: realEstateAmortisations.filter(
      ({ realEstateAssetId }) => realEstateAssetId === realEstateAssetIdToSum
    ),
    accountingPeriods,
    realEstateAssets: realEstateAssets.filter(({ id }) => id === realEstateAssetIdToSum),
    accountingPeriodIdToSum,
  });
};
