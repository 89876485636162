import { downloadFile } from "@/utils";
import {
  RentalAgreementsService,
  RentalBuildingsService,
  RentalUnitsService,
} from "@edmp/api";
import { DocumentFileType, mimeTypes } from "@edmp/api/src";
import axios from "axios";

const api = "/api/v1/rentals";

class RentalsService {
  agreements = {
    async create(rentalAgreementCreate: RentalAgreementsService.CreateIn) {
      return axios
        .post<RentalAgreementsService.CreateOut>(
          `${api}/agreements`,
          rentalAgreementCreate
        )
        .then((res) => {
          return res.data;
        });
    },

    async list(params: RentalAgreementsService.ListIn) {
      return axios
        .get<RentalAgreementsService.ListOut>(`${api}/agreements`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async getRentalAgreement(params: RentalAgreementsService.GetIn) {
      return axios
        .get<RentalAgreementsService.GetOut>(`${api}/${params.id}`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async update(rentalAgreementUpdate: RentalAgreementsService.UpdateIn) {
      return axios
        .put<RentalAgreementsService.UpdateOut>(
          `${api}/agreements/${rentalAgreementUpdate.id}`,
          rentalAgreementUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    async remove(rentalAgreementDelete: RentalAgreementsService.DeleteIn) {
      return axios
        .delete<void>(`${api}/agreements/${rentalAgreementDelete.id}`)
        .then((res) => {
          return res.data;
        });
    },

    async downloadReceipt(params: RentalAgreementsService.ReceiptIn) {
      return axios
        .post<RentalAgreementsService.ReceiptOut>(
          `${api}/agreements/${params.id}/receipt`,
          params,
          {
            responseType: "blob", // Important : Format Blob !
            headers: {
              Accept: "application/pdf",
            },
          }
        )
        .then((res) => {
          const fileName = (res.headers["content-disposition"] || "")
            .split("filename=")[1]
            .trim()
            .replace(/"/g, "");
          downloadFile(res.data, fileName, DocumentFileType.PDF);
        });
    },

    async downloadDocumentModel(
      params: RentalAgreementsService.DocumentModelIn
    ) {
      const fileType: DocumentFileType = params.fileType;

      return axios
        .post<RentalAgreementsService.DocumentModelOut>(
          `${api}/agreements/document-model`,
          params,
          {
            responseType: "blob", // Important : Format Blob !
            headers: {
              Accept: mimeTypes[fileType],
            },
          }
        )
        .then((res) => {
          const fileName = (res.headers["content-disposition"] || "")
            .split("filename=")[1]
            .trim()
            .replace(/"/g, "");
          downloadFile(res.data, fileName, fileType);
        });
    },
  };

  buildings = {
    async create(rentalBuildingCreate: RentalBuildingsService.CreateIn) {
      return axios
        .post<RentalBuildingsService.CreateOut>(
          `${api}/buildings`,
          rentalBuildingCreate
        )
        .then((res) => {
          return res.data;
        });
    },

    async list(params: RentalBuildingsService.ListIn) {
      return axios
        .get<RentalBuildingsService.ListOut>(`${api}/buildings`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async get(params: RentalBuildingsService.GetIn) {
      return axios
        .get<RentalBuildingsService.ListOut>(`${api}/buildings/${params.id}`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async update(rentalBuildingUpdate: RentalBuildingsService.UpdateIn) {
      return axios
        .put<RentalBuildingsService.UpdateOut>(
          `${api}/buildings/${rentalBuildingUpdate.id}`,
          rentalBuildingUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    async remove(params: RentalBuildingsService.DeleteIn) {
      return axios
        .delete<RentalBuildingsService.DeleteOut>(
          `${api}/buildings/${params.id}`,
          { params }
        )
        .then((res) => {
          return res.data;
        });
    },
  };

  units = {
    async create(rentalUnitCreate: RentalUnitsService.CreateIn) {
      return axios
        .post<RentalUnitsService.CreateOut>(`${api}/units`, rentalUnitCreate)
        .then((res) => {
          return res.data;
        });
    },

    async list(params: RentalUnitsService.ListIn) {
      return axios
        .get<RentalUnitsService.ListOut>(`${api}/units`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async get(params: RentalUnitsService.GetIn) {
      return axios
        .get<RentalUnitsService.GetOut>(`${api}/units/${params.id}`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async update(rentalUnitUpdate: RentalUnitsService.UpdateIn) {
      return axios
        .put<RentalUnitsService.UpdateOut>(
          `${api}/units/${rentalUnitUpdate.id}`,
          rentalUnitUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    // async move(params: RentalUnitsService.MoveIn) {
    //   return axios
    //     .post<RentalUnitsService.MoveOut>(`${api}/units/${params.id}/move`, {
    //       params,
    //     })
    //     .then((res) => {
    //       return res.data;
    //     });
    // },
  };
}

// Export a singleton instance in the global namespace
export const rentalsService = new RentalsService();
