


















































































































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
import { VForm } from "@/models";
import { AccountingPeriodCreateInternal, TaxRegime } from "@edmp/api";
import { accountingPeriodsStore, productsStore, usersStore } from "@/store";
import DatePicker from "@/components/atom/DatePicker.vue";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";

export default defineComponent({
  name: "RegisterActivityDetailsIR",
  components: {
    DatePicker,
  },
  props: {
    step: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const validateInProgress: Ref<boolean> = ref(false);

    const exerciceStartChoices = ref([
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
    ]);
    const exerciceStartSelect: Ref<number | undefined> = ref(); // TODO add the parameter 2023 to force the 2023 value for one accounting period
    const acceptationGerantStatutaire: Ref<boolean> = ref(false);

    const firstExercice = ref(undefined);
    const startExerciceDateValue = ref(
      `${exerciceStartSelect.value ?? new Date().getFullYear()}-01-01`
    );
    const endExerciceDateValue = ref(
      `${exerciceStartSelect.value ?? new Date().getFullYear()}-12-31`
    );

    // Fiscal exercice date
    const formatDate = (date: string | undefined) => {
      if (!date) {
        return "";
      } else {
        const [month, day] = date.split("-").slice(1);
        return `${day}/${month}`;
      }
    };

    const onChangeExercice = () => {
      startExerciceDateValue.value = `${exerciceStartSelect.value}-01-01`;
      endExerciceDateValue.value = `${exerciceStartSelect.value}-12-31`;
    };
    watch([exerciceStartSelect, firstExercice], () => onChangeExercice());

    const rules = ref({
      exerciceDateMin: (): string => {
        const [year] = startExerciceDateValue.value?.split("-") ?? [undefined];
        const [month, day] = `${new Date().getFullYear()}-01-01`
          .split("-")
          .slice(1);
        return `${exerciceStartSelect.value ?? year}-${month}-${day}`;
      },
      exerciceDateMax: (): string => {
        const [year] = endExerciceDateValue.value?.split("-") ?? [undefined];
        const [month, day] = `${new Date().getFullYear()}-12-31`
          .split("-")
          .slice(1);
        return `${exerciceStartSelect.value ?? year}-${month}-${day}`;
      },
    });

    const displayDates = () => {
      if (firstExercice) {
        return true;
      }
      return false;
    };

    // Send
    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;
        try {
          const createAccountingPeriod = async (
            data: AccountingPeriodCreateInternal
          ) => {
            await accountingPeriodsStore.createAccountingPeriod(data);
          };
          const data: AccountingPeriodCreateInternal = {
            productId: productsStore.currentId,
            taxRegime: TaxRegime.IR_2072,
            firstYear: !!firstExercice.value,
            startAt: startExerciceDateValue.value,
            endAt: endExerciceDateValue.value,
          };
          if (exerciceStartSelect.value == new Date().getFullYear() - 1) {
            // Create year-1
            await createAccountingPeriod(data);

            // Create year
            data.startAt = `${new Date().getFullYear()}-01-01`;
            data.endAt = `${new Date().getFullYear()}-12-31`;
            data.firstYear = false;
            await createAccountingPeriod(data);
          } else {
            // Create year
            await createAccountingPeriod(data);
          }
          dispatchOnBoardingEvent({
            step: OnBoardingEventSteps.ACTIVITY_DETAILS,
            userId: usersStore.loggedInUser.id,
            productId: productsStore.currentId,
            taxRegime: "IR",
          });
          validateInProgress.value = false;
          context.emit("validate");
        } catch (err) {
          validateInProgress.value = false;
        }
      }
    }

    return {
      rules,
      firstExercice,
      exerciceStartChoices,
      exerciceStartSelect,
      acceptationGerantStatutaire,
      startExerciceDateValue,
      endExerciceDateValue,
      validate,
      validateInProgress,
      displayDates,
      formatDate,
    };
  },
});
