

















































































































import {
  defineComponent,
  ref,
  Ref,
  computed,
  reactive,
  watch,
} from "@vue/composition-api";
import { VForm } from "@/models";
import {
  getMoment,
  AccountingPeriodCreateInternal,
  TaxRegime,
} from "@edmp/api";
import { accountingPeriodsStore, productsStore, usersStore } from "@/store";

import { CustomLabelSwitch } from "@/components/atom/switch";
import DatePicker from "@/components/atom/DatePicker.vue";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";

export default defineComponent({
  name: "RegisterActivityDetailsIS",
  components: { CustomLabelSwitch, DatePicker },
  props: {
    step: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const startExerciceDateValue: Ref<string> = ref(
      `${new Date().getFullYear()}-01-01`
    );
    const endExerciceDateValue: Ref<string> = ref(
      `${new Date().getFullYear()}-12-31`
    );
    const isFirstExercice = ref(false);
    const acceptationGerantStatutaire: Ref<boolean> = ref(false);

    // Exercice date picker
    const startExerciceDateText = computed(() =>
      getMoment(startExerciceDateValue.value).format("L")
    );
    const endExerciceDateText = computed(() =>
      getMoment(endExerciceDateValue.value).format("L")
    );

    const rules = reactive({
      isValidCurrentExerciceDate: computed(
        () =>
          getMoment().isBetween(
            getMoment(startExerciceDateValue.value),
            getMoment(endExerciceDateValue.value)
          ) || "La période sélectionnée ne comprend pas la date d'aujourd'hui"
      ),
      startExercice: {
        exerciceDateMin: () =>
          getMoment(`${new Date().getFullYear()}-01-01`)
            .subtract(1, "years")
            .format("YYYY-MM-DD"),
        exerciceDateMax: () => `${new Date().getFullYear()}-12-31`,
      },
      endExercice: {
        exerciceDateMin: () => `${new Date().getFullYear()}-01-01`,
        exerciceDateMax: () =>
          getMoment(`${new Date().getFullYear()}-12-31`)
            .add(1, "year")
            .format("YYYY-MM-DD"),
      },
    });

    // Open previous exercise
    const isOpenPreviousExercise: Ref<boolean> = ref(false);
    const previousStartExerciseDate = computed(() =>
      getMoment(startExerciceDateValue.value).subtract(1, "year")
    );
    const previousEndExerciseDate = computed(() =>
      getMoment(endExerciceDateValue.value).subtract(1, "year")
    );
    const isAutosizeOpenPreviousExercise = computed(() =>
      getMoment(previousEndExerciseDate.value)
        .add(6, "months")
        .isSameOrAfter(getMoment().startOf("day"))
    );
    const previousExerciseDateLabel = computed(
      () =>
        `Du ${previousStartExerciseDate.value.format(
          "L"
        )} au ${previousEndExerciseDate.value.format("L")}`
    );
    const currentExerciseDateLabel = computed(
      () => `Du ${startExerciceDateText.value} au ${endExerciceDateText.value}`
    );
    watch(
      [isFirstExercice, isAutosizeOpenPreviousExercise],
      () => {
        if (isFirstExercice || !isAutosizeOpenPreviousExercise) {
          isOpenPreviousExercise.value = false;
        }
      },
      {
        deep: true,
      }
    );

    // Send
    const validateInProgress: Ref<boolean> = ref(false);
    function validate(e: Event): void {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;

        const createAccountingPeriod = (
          data: AccountingPeriodCreateInternal
        ) => {
          try {
            accountingPeriodsStore.createAccountingPeriod(data).then(() => {
              dispatchOnBoardingEvent({
                step: OnBoardingEventSteps.ACTIVITY_DETAILS,
                userId: usersStore.loggedInUser.id,
                productId: productsStore.currentId,
                taxRegime: "IS",
              });
              context.emit("validate");
            });
          } catch (err) {
            validateInProgress.value = false;
          }
        };

        const previousAccountingPeriod: AccountingPeriodCreateInternal = {
          productId: productsStore.currentId,
          taxRegime: TaxRegime.IS_2065,
          firstYear: isFirstExercice.value,
          startAt: previousStartExerciseDate.value.format("YYYY-MM-DD"),
          endAt: previousEndExerciseDate.value.format("YYYY-MM-DD"),
        };
        const currentAccountingPeriod: AccountingPeriodCreateInternal = {
          productId: productsStore.currentId,
          taxRegime: TaxRegime.IS_2065,
          firstYear: isFirstExercice.value,
          startAt: startExerciceDateValue.value,
          endAt: endExerciceDateValue.value,
        };

        if (
          !isFirstExercice.value &&
          isAutosizeOpenPreviousExercise.value &&
          isOpenPreviousExercise.value
        ) {
          createAccountingPeriod(previousAccountingPeriod);
        }
        createAccountingPeriod(currentAccountingPeriod);
      }
    }

    return {
      startExerciceDateValue,
      startExerciceDateText,
      endExerciceDateValue,
      endExerciceDateText,
      rules,
      isFirstExercice,
      isAutosizeOpenPreviousExercise,
      isOpenPreviousExercise,
      previousExerciseDateLabel,
      currentExerciseDateLabel,
      acceptationGerantStatutaire,
      validateInProgress,
      validate,
      test: computed(() =>
        getMoment(previousEndExerciseDate.value)
          .add(2, "months")
          .startOf("day")
          .toISOString()
      ),
    };
  },
});
