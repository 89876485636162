
































































import { defineComponent, Ref, ref } from "@vue/composition-api";
import { Address, RealEstateAsset, TenantTypeEnum } from "@edmp/api";

import { VForm } from "@/models";
import { rentalAgreementsStore } from "@/store";

export default defineComponent({
  name: "RentalAgreementCreate",
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
    rentalUnitId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const firstname: Ref<string> = ref("");
    const lastname: Ref<string> = ref("");
    const phone: Ref<string> = ref("");

    const validateInProgress: Ref<boolean> = ref(false);

    function resetForm() {
      firstname.value = "";
      lastname.value = "";
      phone.value = "";
      (context.refs.form as VForm).resetValidation();
    }

    async function validate() {
      validateInProgress.value = true;
      if ((context.refs.form as VForm).validate()) {
        try {
          const rentalAgreement =
            await rentalAgreementsStore.createRentalAgreement({
              product: {
                id: props.productId,
                realEstateAsset: {
                  id: props.realEstateAsset.id,
                  rentalUnit: {
                    id: props.rentalUnitId,
                  },
                },
              },
              tenant: {
                type: TenantTypeEnum.NATURAL_PERSON,
                name: lastname.value,
                firstName: firstname.value,
                phone: phone.value,
                address: { ...props.realEstateAsset.address } as Address,
              },
            });
          resetForm();
          context.emit("validate", rentalAgreement.id);
        } catch (error) {
          console.error(Error);
        }
      }
      validateInProgress.value = false;
    }

    function closeDialog() {
      resetForm();
      context.emit("close");
    }

    return {
      firstname,
      lastname,
      phone,
      validateInProgress,
      validate,
      resetForm,
      closeDialog,
    };
  },
});
