// List of error types
import { ErrorInterface } from "./ErrorInterface";

export const CATEGORIZATION_ERROR = "Categorization";
export const EVENT_ERROR = "Event";
export const IMPORT_ERROR = "Import";
export const SUBSCRIPTION_ERROR = "Subscription";
export const TAX_DECLARATION_ERROR = "TaxDeclaration";
export const ACCOUNTING_PERIOD_ERROR = "AccountingPeriodError";
export const LOAN_ERROR = "Loan";
export const RENTAL_UNIT_ERROR = "RENTAL_UNIT_ERROR";
export const USER_ERROR = "USER_ERROR";
export const ACCOUNTING_BALANCE_SHEET_ERROR = "ACCOUNTING_BALANCE_SHEET_ERROR";
export const ACCOUNTING_CARRY_FORWARD_ERROR = "ACCOUNTING_CARRY_FORWARD_ERROR";
export const ACCOUNTING_RESULT_ERROR = "ACCOUNTING_RESULT_ERROR";
export const TASK_ERROR = "TASK_ERROR";
export const BANK_ERROR = "BANK_ERROR";
export const ACCOUNTING_ERROR = "ACCOUNTING_ERROR";
export const RENT_ERROR = "RENT_ERROR";
export const OPERATOR_ERROR = "OPERATOR_ERROR";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const ACTIVITY_ERROR = "ACTIVITY_ERROR";
export const TASK_ACTIVITY_ERROR = "TASK_ACTIVITY_ERROR";
export const AMORTIZATION_ERROR = "AMORTIZATION_ERROR";
export const TELEDEC_ERROR = "TELEDEC_ERROR";
export const HUBSPOT_ERROR = "HUBSPOT_ERROR";

// List of errors
export enum EnumErrors {
  Required = "required",
  MoreThanOne = "more-than-one",

  // Technical errors
  InvalidParameter = "invalid-parameter",
  NotFound = "not-found",
  ForbiddenError = "forbidden-error",
  UnauthorizedError = "unauthorized-error",
  ValidationError = "validation-error",
  RethrowError = "rethrow-error",
  UnknownError = "unknown-error",

  // Bank
  BankProviderConnection = "bank-provider-connection",
  BankAccountAlreadyExist = "bank-account-already-exist",

  // Operator
  TypeLegalPersonIsNotSupporting = "type-legal-person-is-not-supporting",
  RequiredOperatorAddress = "required-operator-address",

  // Categorization errors
  InvalidDecimal = "invalid-decimals", // Amounts must have two or less decimal places.
  InvalidDebitsSum = "invalid-debits-sum", // The sum of debits should be strictly positive
  InvalidCreditsSum = "invalid-credits-sum", // The sum of credits should be strictly positive
  InvalidExactSum = "invalid-exact-sum",
  InvalidPartialSum = "invalid-partial-sum", // The sum of amounts should be strictly less than the transaction amount when you do not provide all of the amounts.
  InvalidMissingSum = "invalid-missing-sum", // At most one amount might be missing.
  InvalidBeforeDate = "invalid-before-period-start", // Date is before start period
  InvalidAfterDate = "invalid-after-period-end", // Date is after end period
  InvalidCommission = "invalid-commission-transaction",
  NoCommission = "no-commission-transaction",
  UnequalSums = "unequal-sums",
  MissingRentalAgreement = "missing-rental-agreement", // Rental Agreement must be present in the given company
  MissingPartner = "missing-partner", // Partner must be present in the given company
  MissingRentalUnit = "missing-rental-unit", // Rental Unit must be present in the given company
  MissingRealEstateAsset = "missing-real-estate-asset", // Real Estate Asset must be present in the given company
  MissingField = "missing-field", // Missing field for account
  RequiredUniqueAccount = "required-unique-account", // Each category can only appear once.
  RequiredDate = "required-date", // A date must be provided
  RequiredEntries = "required-entries", // There should be at least one entry.
  RequiredPositive = "required-positive-amount",
  RequiredCategory = "required-category",
  RequiredAllAccountInPLan = "required-account-in-plan", // All categories must exist in the plan
  ExtraneousField = "extraneous-field", // Extra field for account

  // AccountingBalanceSheet error
  InvalidBalanced = "invalid-balanced",
  InvalidTaxRegime = "invalid-tax-regime",
  TypeRecoveryAlreadyExist = "type-recovery-already-exist",
  MoreThanOneTypeRecovery = "more-than-one-type-recovery",
  IsValidate = "is-validate",
  IsNotValidate = "is-not-validate",
  IsPreviousNotValidate = "is-previous-not-validate",
  IsSkip = "is-skip",
  RequiredSupportingDocument = "required-supporting-document",

  // AccountingCarryForward error
  TypeRecoveryNotEditable = "type-recovery-not-editable",

  // Task error
  InvalidRefUser = "invalid-ref-user",
  InvalidRefCompany = "invalid-ref-company",
  NotTransactionCategorized = "not-transaction-categorized",
  RemainRealEstateAssetToAllocateToRentalBuildings = "remain-real-estate-asset-to-allocate-to-rental-buildings",
  GroupsNotCompleted = "groups-not-completed",

  // Task activity error
  TasksCompanyAlreadyExist = "tasks-company-already-exist",
  TasksUserAlreadyExist = "tasks-user-already-exist",
  TasksAccountingPeriodExist = "tasks-accounting-period-exist",
  TasksClosureAlreadyExist = "tasks-closure-already-exist",
  TasksMultipleType = "tasks-multiple-type",
  TaskCodeExisting = "task-code-existing",
  TaskBadType = "task-bad-type",

  // Event Error
  InvalidStartDateEvent = "invalid-start-date-event",

  // Import Error
  InvalidDateFormat = "invalid-date-format",
  InvalidFileFormat = "invalid-file-format",
  EmptyFile = "empty-file",

  // Amortization Error
  NoRealEstateAmortized = "no-real-estate-amortized",
  AmortizationRecoveryAlreadyPassed = "amortization-recovery-already-passed",

  // Subscription Error
  NoActiveSubscription = "no-active-subscription",
  InsufficientSubscription = "insufficient-subscription",
  UpdatePlanDenyRealEstateAssets = "update-plan-deny-real-estate-assets",

  // Accounting Period Error
  ClosedAccountingPeriod = "accounting-period-is-close",
  DateOutOfRange = "date-out-of-range",

  // Rental Unit Error
  TaxTvaRequire = "tax-tva-require",
  TaxTvaProhibited = "tax-tva-prohibited",

  // Loan Error
  InvalidLoanFormat = "invalid-loan-format",

  // User Error
  TosAcceptRequired = "tos-accept-required",

  // Tax declaration
  RequiredProductEmail = "required-product-email",
  RequiredCompanySiret = "required-company-siret",
  RequiredCompanyAddress = "required-company-address",
  RequiredPartnerAddress = "required-partner-address",
  RequiredPartnerBirthAddress = "required-partner-birth-address",
  RequiredPartnerLegalStatus = "required-partner-legal-status",
  RequiredPartnerSiret = "required-partner-siret",
  RequiredProductSci = "required-product-sci",
  RequiredProductLmnp = "required-product-lmnp",
  BalanceSheetNotBalanced = "balance-sheet-not-balanced",
  AlreadyProcessing = "already-processing",
  NotSend = "not-send",
  NotSupported = "not-supported",
  IsValidateDeclaration = "is-validate-declaration",

  // Accounting period
  AccountingPeriodExist = "accounting-period-exist",
}

/**
 * This interface is used to throw error functional (4XX)
 *
 * Functional errors are business error used when rules are not validated
 */
export interface FunctionalError extends ErrorInterface {
  error: string; // See list of errors in EnumErrors
}
