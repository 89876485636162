import { SubscriptionsModel } from "../models/Subscription.model";

export const isPremiumSubscription = (subscription: SubscriptionsModel.Subscription) =>
  subscription.suspend === false &&
  subscription.status === SubscriptionsModel.SubscriptionStatus.active &&
  subscription.plan.type === SubscriptionsModel.PlanType.Premium;

export const isOptimumSubscription = (subscription: SubscriptionsModel.Subscription) =>
  subscription.suspend === false &&
  subscription.status === SubscriptionsModel.SubscriptionStatus.active &&
  subscription.plan.type === SubscriptionsModel.PlanType.Optimum;
