























































































import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { RentalAgreement } from "@edmp/api";
import RentalAgreementCard from "@/components/core/rentalAgreements/RentalAgreementCard.vue";
import DialogRight from "@/components/core/DialogRight.vue";
import RentalAgreementModal from "./RentalAgreementModal.vue";
import RentalAgreementServiceModal from "./RentalAgreementServiceModal.vue";
import {
  productsStore,
  coreStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  accountingPeriodsStore,
} from "@/store";
import router from "@/router";
import { ForbiddenError, subject } from "@casl/ability";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ability } from "@/services";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "RentalAgreements",
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAssetId: {
      type: String,
      required: true,
    },
    rentalAgreementId: {
      type: String,
      required: false,
    },
  },
  components: {
    DialogRight,
    RentalAgreementModal,
    RentalAgreementServiceModal,
    RentalAgreementCard,
  },
  setup(props) {
    const realEstateAsset = computed(() =>
      realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId)
    );
    const rentalAgreements = computed(() =>
      rentalAgreementsStore.getRentalAgreementsByRealEstateAssetId(
        props.realEstateAssetId
      )
    );
    const displayRentalAgreementModal: Ref<boolean> = ref(false);
    const setRentalAgreementServiceModal: Ref<boolean> = ref(false);

    const selectedRentalAgreement: Ref<RentalAgreement | null> = ref(null);

    function openDetails(ra: RentalAgreement) {
      selectedRentalAgreement.value = ra;
      displayRentalAgreementModal.value = true;
    }

    const closeDetails = () => {
      displayRentalAgreementModal.value = false;
      selectedRentalAgreement.value = null;

      // Remove props rentalUnitId
      router.replace({
        name: ROUTE_NAMES.RealEstate,
        params: {
          productId: props.productId,
          realEstateAssetId: props.realEstateAssetId,
        },
      });
    };

    async function navigateToDoc() {
      await productsStore.switchProduct({ id: props.productId });
      router.push({
        name: ROUTE_NAMES.Documents,
        query: { focus: "rent" },
      });
    }

    function openNew() {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "addTenant",
          subject("RentalManagement", {})
        );
        selectedRentalAgreement.value = null;
        displayRentalAgreementModal.value = true;
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    }

    // Init
    const init = async () => {
      await rentalAgreementsStore.fetchRentalAgreements({
        productId: props.productId,
        realEstateAssetId: props.realEstateAssetId,
      });
      if (props.rentalAgreementId) {
        const rentalAgreement = rentalAgreements.value.find(
          (rentalAgreement) => rentalAgreement.id === props.rentalAgreementId
        );
        if (rentalAgreement) {
          openDetails(rentalAgreement);
        }
      }
    };

    watch(props, async () => await init(), {
      deep: true,
    });

    onMounted(async () => await init());

    return {
      init,
      realEstateAsset,
      rentalAgreements,
      displayRentalAgreementModal,
      setRentalAgreementServiceModal,
      selectedRentalAgreement,
      isLMNP: computed(() => accountingPeriodsStore.isLMNP),
      openDetails,
      closeDetails,
      openNew,
      navigateToDoc,
    };
  },
});
