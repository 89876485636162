



































































































































































































































































































































import SignTemplate from "@/components/sign/SignTemplate.vue";
import RegisterUserAccount from "@/components/sign/signup/steps/RegisterUserAccount.vue";
import RegisterUserDetails from "@/components/sign/signup/steps/RegisterUserDetails.vue";
import RegisterActivity from "@/components/sign/signup/steps/RegisterActivity.vue";
import RegisterActivityDetails from "@/components/sign/signup/steps/RegisterActivityDetails.vue";
import RegisterBankAccount from "@/components/sign/signup/steps/RegisterBankAccount.vue";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { ProductsModel, TaxRegime } from "@edmp/api";
import { productsStore, coreStore } from "@/store";
import { useInit } from "@/composables";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

export default defineComponent({
  name: "Registration",
  components: {
    SignTemplate,
    RegisterUserAccount,
    RegisterUserDetails,
    RegisterActivity,
    RegisterActivityDetails,
    RegisterBankAccount,
  },
  props: {
    registrationIncomplete: {
      type: String,
      require: true,
      default: "false",
    },
    type: {
      type: String as PropType<ProductsModel.Tags.LRP>,
      require: false,
    },
  },
  setup(props, context) {
    const stepBi: Ref<boolean> = ref(false);
    const currentStep: ComputedRef<number> = computed(() => {
      if (context.root.$route.name === "RegisterUser") {
        stepBi.value = false;
        return 1;
      } else if (context.root.$route.name === "RegisterUserDetails") {
        stepBi.value = false;
        return 2;
      } else if (context.root.$route.name === "RegisterActivity") {
        stepBi.value = false;
        return 3;
      } else if (context.root.$route.name === "RegisterActivityDetails") {
        stepBi.value = false;
        return 4;
      } else {
        return stepBi.value ? 6 : 5;
      }
    });
    const onBoardingType = ref(props.type);
    const activityType: Ref<"SCI" | "LMNP"> = ref(
      productsStore.currentProduct?.type === ProductsModel.ProductTypes.SCI
        ? "SCI"
        : "LMNP"
    );
    const taxRegime: Ref<TaxRegime> = ref(
      productsStore.currentProduct?.type === ProductsModel.ProductTypes.SCI
        ? TaxRegime.IR_2072
        : TaxRegime.LMNP_2031
    );

    watch(activityType, () => {
      if (activityType.value === "SCI") {
        taxRegime.value = TaxRegime.IR_2072;
      }
      if (activityType.value === "LMNP") {
        taxRegime.value = TaxRegime.LMNP_2031;
      }
    });

    const { initOnBoardingStep } = useInit();

    watch(currentStep, (val) => {
      initOnBoardingStep(context, val);
    });

    function navigate(routeName: string): void {
      context.root.$router.push({ name: routeName });
    }

    onBeforeMount(async () => {
      try {
        await coreStore.getConfig();
        await initOnBoardingStep(context, 0);
      } catch (err) {
        // redirect error page or modal error
      }
    });

    onMounted(() => {
      useCrisp().closeApp();
    });

    return {
      currentStep,
      navigate,
      product: computed(() => productsStore.currentProduct),
      stepBi,
      openArticleRegistration: () =>
        useCrisp().openArticle(ArticleEnum.REGISTER_USER_ACCOUNT),
      openArticleWhoIsOwnily: () =>
        useCrisp().openArticle(ArticleEnum.WHO_IS_OWNILY),
      activityType,
      TaxRegime,
      taxRegime,
      onBoardingType,
    };
  },
});
