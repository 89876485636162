







































































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import Vue from "vue";
import {
  AccountingBalanceSheetUpdate,
  Direction,
  getAmortisationAccountByAccount,
  getGlobalAccountFromAccountWithReferenceCounter,
  LedgerAccountEnum,
  Suggestion,
  TaxRegime,
  TypeReference,
  isBidirectionalException,
  isUpdatableAccountCurrentBalanceSheet,
} from "@edmp/api";
import Decimal from "decimal.js-light";
import { cloneDeep, flatMap, merge } from "lodash";

import {
  AccountingBalanceSheetLinesLocal,
  AccountingBalanceSheetLocal,
  VForm,
} from "@/models";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  transactionsStore,
} from "@/store";

import { useTransactions } from "@/components/core/transactions/transactions.usable";

import { CustomLabelButton } from "@/components/atom/button";
import { HelpingMessage } from "@/components/atom/helping";
import EditableTable, {
  EditableTableRef,
  TableHeaderItems,
} from "@/components/atom/table/EditableTable.vue";
import Title from "@/components/core/title/Title.vue";
import AccountingBalanceSheetAddModal from "./AccountingBalanceSheetAddModal.vue";
import { FilterKeys } from "@/utils";

export default defineComponent({
  name: "AccountingBalanceSheetTable",
  components: {
    Title,
    HelpingMessage,
    CustomLabelButton,
    EditableTable,
    AccountingBalanceSheetAddModal,
  },
  props: {
    balanceSheet: {
      type: Object as PropType<AccountingBalanceSheetLocal>,
    },
    accountBalanceType: {
      type: String as PropType<Suggestion["accountBalanceType"] | "all">,
      required: true,
    },
    viewType: {
      type: String as PropType<"readOnly" | "editable">,
      default: "editable",
    },
  },
  setup(props, context) {
    const { getAttribute } = useTransactions();

    const taxRegime = computed(
      () => accountingPeriodsStore.currentAccountingPeriod?.taxRegime
    );

    const categories = computed(() =>
      flatMap(transactionsStore.categoriesList)
    );

    const goToTransactions = (line: AccountingBalanceSheetLinesLocal) => {
      const label = categories.value.find(
        (categorie) =>
          (line.reference
            ? getGlobalAccountFromAccountWithReferenceCounter(
                line.account,
                line.reference
              )
            : line.account) === categorie.number
      )?.name;
      transactionsStore.resetFilter();
      transactionsStore.addFilter({
        code: FilterKeys.CERFA,
        filterParams: {
          ids: line.transactionIds,
          label: `Bilan - ${label}`,
        },
      });
      context.root.$router.push({
        name: "Transactions",
      });
    };

    const showAddItemModal = ref(false);
    const hideAddButton = ref(false);

    const accountingBalanceSheet = computed(() =>
      props.balanceSheet
        ? accountingBalanceSheetsStore.getAccountingBalanceSheet(
            props.balanceSheet.id
          )
        : undefined
    );

    const accountingBalanceSheetLines = ref(
      props.balanceSheet ? cloneDeep(props.balanceSheet).lines : []
    );
    watch(
      () => props.balanceSheet?.lines,
      () => {
        // Hide add line button
        if (props.balanceSheet?.type === "closure") {
          hideAddButton.value = true;
        }
        // Init action column
        for (
          let index = 0;
          index < accountingBalanceSheetLines.value.length;
          index++
        ) {
          (context.refs.editableTable as EditableTableRef).updateHideAction(
            index,
            { edit: false, cancelEdit: false, delete: false, validate: false }
          );
        }
        // Update ref accounting balance sheet lines
        accountingBalanceSheetLines.value = props.balanceSheet
          ? cloneDeep(props.balanceSheet).lines
          : [];
        // Apply rules action column
        for (
          let index = 0;
          index < accountingBalanceSheetLines.value.length;
          index++
        ) {
          const balanceSheetLine = accountingBalanceSheetLines.value[index];

          const isAllowDeleted = !categories.value.find((categorie) =>
            balanceSheetLine.reference
              ? categorie.number ===
                getGlobalAccountFromAccountWithReferenceCounter(
                  balanceSheetLine.account,
                  balanceSheetLine.reference
                )
              : categorie.number === balanceSheetLine.account
          )?.referenceCounter;

          if (
            balanceSheetLine.amount &&
            balanceSheetLine.direction === Direction.debit &&
            isBidirectionalException(balanceSheetLine.account)
          ) {
            balanceSheetLine.amount = -balanceSheetLine.amount;
          }

          if (props.balanceSheet?.type === "recovery") {
            if (!isAllowDeleted) {
              (context.refs.editableTable as EditableTableRef).updateHideAction(
                index,
                { delete: true }
              );
            }
            if (
              balanceSheetLine.amount === undefined ||
              balanceSheetLine.amountAmortisation === undefined
            ) {
              (context.refs.editableTable as EditableTableRef).updateHideAction(
                index,
                { cancelEdit: true }
              );
            }
          }
          if (props.balanceSheet?.type === "closure") {
            const account: LedgerAccountEnum = (
              balanceSheetLine.reference
                ? getGlobalAccountFromAccountWithReferenceCounter(
                    balanceSheetLine.account,
                    balanceSheetLine.reference
                  )
                : balanceSheetLine.account
            ) as LedgerAccountEnum;
            if (account === LedgerAccountEnum.N512000) {
              if (!isAllowDeleted) {
                (
                  context.refs.editableTable as EditableTableRef
                ).updateHideAction(index, { delete: true });
              }
              if (balanceSheetLine.amount === undefined) {
                (
                  context.refs.editableTable as EditableTableRef
                ).updateHideAction(index, { cancelEdit: true });
              }
            } else if (
              taxRegime.value &&
              isUpdatableAccountCurrentBalanceSheet(account, taxRegime.value)
            ) {
              (context.refs.editableTable as EditableTableRef).updateHideAction(
                index,
                { delete: true }
              );
            } else {
              (context.refs.editableTable as EditableTableRef).updateHideAction(
                index,
                { edit: true, cancelEdit: true, delete: true, validate: true }
              );
            }
          }
        }
      },
      { deep: true }
    );

    const rules = computed(() => (rowIndex: number) => ({
      amount: [
        Number.isInteger(
          Number(accountingBalanceSheetLines.value[rowIndex].amount)
        ) || "Montant arrondi uniquement",
        accountingBalanceSheetLines.value[rowIndex].amount !== undefined ||
          "Le champ ne peut pas être null",
        new Decimal(
          Number(accountingBalanceSheetLines.value[rowIndex].amount) || 0
        ).isPositive() ||
          new Decimal(
            Number(accountingBalanceSheetLines.value[rowIndex].amount) || 0
          ).isZero() ||
          isBidirectionalException(
            accountingBalanceSheetLines.value[rowIndex].account
          ) ||
          "Le champ ne peut pas être négatif",
      ],
      amortisationAmount: [
        Number.isInteger(
          Number(accountingBalanceSheetLines.value[rowIndex].amountAmortisation)
        ) || "Montant arrondi uniquement",
        accountingBalanceSheetLines.value[rowIndex].amountAmortisation !==
          undefined || "Le champ ne peut pas être null",
        (accountingBalanceSheetLines.value[rowIndex].amount !== undefined &&
          accountingBalanceSheetLines.value[rowIndex].amountAmortisation !==
            undefined &&
          Number(accountingBalanceSheetLines.value[rowIndex].amount) >=
            Number(
              accountingBalanceSheetLines.value[rowIndex].amountAmortisation
            )) ||
          "Le montant de l'amortissement ne peut pas être supérieur au montant",
        (accountingBalanceSheetLines.value[rowIndex].amount !== undefined &&
          accountingBalanceSheetLines.value[rowIndex].amountAmortisation !==
            undefined &&
          (new Decimal(
            Number(
              accountingBalanceSheetLines.value[rowIndex].amountAmortisation
            )
          ).isPositive() ||
            new Decimal(
              Number(
                accountingBalanceSheetLines.value[rowIndex].amountAmortisation
              )
            ).isZero())) ||
          "Le champ ne peut pas être négatif",
      ],
    }));

    // Table
    const headers: ComputedRef<TableHeaderItems> = computed(() => {
      const headersOut = [
        { text: "N° du compte", value: "account" },
        { text: "Nom du compte", value: "accountName" },
        { text: "Attribut", value: "attribute" },
      ];
      const headersIS = [
        { text: "Brut", value: "amountText", width: "10rem" },
        { text: "Amorti.", value: "amountAmortisationText", width: "10rem" },
        { text: "Net", value: "amountNetText", width: "10rem" },
        { text: "", value: "goToTransactions", width: "10rem" },
      ];
      const headersLMNP = [
        { text: "Brut", value: "amountText", width: "10rem" },
        { text: "Amorti.", value: "amountAmortisationText", width: "10rem" },
        { text: "Net", value: "amountNetText", width: "10rem" },
      ];
      const headersIR = [
        { text: "Valeur", value: "amountText", width: "10rem" },
      ];
      switch (taxRegime.value) {
        case TaxRegime.IR_2072:
          headersOut.push(...headersIR);
          break;
        case TaxRegime.IS_2065:
          headersOut.push(...headersIS);
          break;
        case TaxRegime.LMNP_2031:
          headersOut.push(...headersLMNP);
          break;
      }
      return headersOut;
    });
    const headerValueTextToOriginalKey: {
      [key in typeof headers.value[number]["value"]]: keyof Pick<
        AccountingBalanceSheetLocal["lines"][number],
        "amount" | "amountAmortisation"
      >;
    } = { amountText: "amount", amountAmortisationText: "amountAmortisation" };
    const itemsKeyValueSuffix: {
      [key in
        | keyof Pick<
            AccountingBalanceSheetLocal["lines"][number],
            "amount" | "amountAmortisation"
          >
        | "amountNet"]: string;
    } = {
      amount: "€",
      amountAmortisation: "€",
      amountNet: "€",
    };
    const accountingBalanceSheetItems = computed(() => {
      const accountingBalanceSheetItemsOut = cloneDeep(
        accountingBalanceSheetLines.value
      )
        .map((line) => {
          if (
            line.amount !== undefined &&
            !isNaN(line.amount) &&
            !Number.isInteger(Number(line.amount))
          ) {
            line.amount = Number(new Decimal(line.amount).toFixed(0));
          }
          if (
            line.amountAmortisation !== undefined &&
            !isNaN(line.amountAmortisation) &&
            !Number.isInteger(Number(line.amountAmortisation))
          ) {
            line.amountAmortisation = Number(
              new Decimal(line.amountAmortisation).toFixed(0)
            );
          }
          return line;
        })
        .map((line) =>
          merge(line, {
            account: line.account,
            accountName: categories.value.find(
              (categorie) =>
                (line.reference
                  ? getGlobalAccountFromAccountWithReferenceCounter(
                      line.account,
                      line.reference
                    )
                  : line.account) === categorie.number
            )?.name,
            attribute: line.reference
              ? {
                  name: getAttribute(
                    line.reference.type,
                    line.reference.referredId
                  ),
                  icon:
                    line.reference.type === TypeReference.realEstateAsset
                      ? "mdi-home-outline"
                      : line.reference.type === TypeReference.rentalAgreement
                      ? "mdi-account-key-outline"
                      : line.reference.type === TypeReference.partner
                      ? "mdi-account-outline"
                      : line.reference.type === TypeReference.realEstateLoan
                      ? "mdi-home-percent-outline"
                      : line.reference.type === TypeReference.supportingDocument
                      ? "mdi-folder-outline"
                      : line.reference.type === TypeReference.bankAccount
                      ? "mdi-bank-outline"
                      : undefined,
                }
              : undefined,
            amountText: `${
              props.accountBalanceType === "shareholders_equity" ||
              props.accountBalanceType === "debts"
                ? line.direction === Direction.debit &&
                  !isBidirectionalException(line.account)
                  ? "-"
                  : ""
                : ""
            }${line.amount}${itemsKeyValueSuffix.amount}`,
            amountAmortisationText:
              line.amountAmortisation !== undefined
                ? `${line.amountAmortisation}${itemsKeyValueSuffix.amountAmortisation}`
                : undefined,
            amountNetText: line.amountAmortisation
              ? `${new Decimal(line.amount).sub(line.amountAmortisation)}${
                  itemsKeyValueSuffix.amountNet
                }`
              : `${
                  props.accountBalanceType === "shareholders_equity" ||
                  props.accountBalanceType === "debts"
                    ? line.direction === Direction.debit &&
                      !isBidirectionalException(line.account)
                      ? "-"
                      : ""
                    : ""
                }${line.amount}${itemsKeyValueSuffix.amount}`,
            transactionIds: line.transactionIds,
          })
        );
      return accountingBalanceSheetItemsOut;
    });

    // Methods
    const hasAmortisationAccount = (
      line: AccountingBalanceSheetLocal["lines"][number]
    ): boolean => {
      const account = (
        line.reference
          ? getGlobalAccountFromAccountWithReferenceCounter(
              line.account,
              line.reference
            )
          : line.account
      ) as LedgerAccountEnum;
      if (
        props.balanceSheet?.type === "recovery" &&
        account === LedgerAccountEnum.N512000
      ) {
        return false;
      }
      return !!getAmortisationAccountByAccount(account);
    };
    const validateItem = async ({ rowIndex }: { rowIndex: number }) => {
      if (
        (context.refs.form as VForm).validate() &&
        accountingBalanceSheet.value &&
        props.balanceSheet
      ) {
        const balanceSheetToSave = Object.assign(
          cloneDeep(props.balanceSheet),
          {
            lines: [
              ...cloneDeep(accountingBalanceSheet.value).lines.filter(
                (line) =>
                  !accountingBalanceSheetLines.value.find(
                    ({ id }) => id === line.id
                  )
              ),
              ...accountingBalanceSheetLines.value.map((line) => ({
                ...line,
                amount: Number(new Decimal(line.amount).abs().toFixed(0)),
                direction: (() => {
                  if (isBidirectionalException(line.account)) {
                    return new Decimal(line.amount).isNegative()
                      ? Direction.debit
                      : Direction.credit;
                  } else {
                    return line.direction;
                  }
                })(),
              })),
            ],
          }
        );

        // Find, update amortisation line and remove amountAmortisation key
        if (balanceSheetToSave.lines) {
          balanceSheetToSave.lines = balanceSheetToSave.lines.map(
            (line, index, lines) => {
              const amortisationAccount = getAmortisationAccountByAccount(
                (line.reference
                  ? getGlobalAccountFromAccountWithReferenceCounter(
                      line.account,
                      line.reference
                    )
                  : line.account) as LedgerAccountEnum
              );
              if (amortisationAccount) {
                const balanceSheetLineAmortisationIndex = lines.findIndex(
                  (lineFind) =>
                    (lineFind.reference
                      ? getGlobalAccountFromAccountWithReferenceCounter(
                          lineFind.account,
                          lineFind.reference
                        )
                      : lineFind.account) === amortisationAccount &&
                    lineFind.reference?.type === line.reference?.type &&
                    lineFind.reference?.referredId ===
                      line.reference?.referredId
                );
                if (
                  balanceSheetLineAmortisationIndex !== -1 &&
                  line.amountAmortisation
                ) {
                  lines[balanceSheetLineAmortisationIndex].amount = Number(
                    Number(line.amountAmortisation).toFixed(0)
                  );
                  delete line.amountAmortisation;
                }
              }
              return line;
            }
          );
        }

        try {
          await accountingBalanceSheetsStore.updateAccountingBalanceSheet(
            balanceSheetToSave as unknown as AccountingBalanceSheetUpdate
          );
          (context.refs.editableTable as EditableTableRef).updateIsEditing(
            rowIndex,
            false
          );
        } catch (error) {
          console.error(error);
          (context.refs.editableTable as EditableTableRef).updateIsEditing(
            rowIndex,
            true
          );
        }
      }
    };
    const addItem = ({ rowIndex }: { rowIndex: number }) => {
      (context.refs.editableTable as EditableTableRef).updateIsEditing(
        rowIndex,
        false
      );
      showAddItemModal.value = true;
    };
    const cancelEditItem = ({ rowIndex }: { rowIndex: number }) => {
      if (props.balanceSheet) {
        Vue.set(
          accountingBalanceSheetLines.value,
          rowIndex,
          cloneDeep(props.balanceSheet).lines[rowIndex]
        );
      }
    };
    const deleteItem = async ({ rowIndex }: { rowIndex: number }) => {
      if (accountingBalanceSheet.value && props.balanceSheet) {
        const accountingBalanceSheetLineDeleted =
          accountingBalanceSheetLines.value.splice(rowIndex, 1);
        const balanceSheetToSave = Object.assign(props.balanceSheet, {
          lines: accountingBalanceSheet.value.lines.filter(
            (line) => line.id !== accountingBalanceSheetLineDeleted[0].id
          ),
        });
        try {
          await accountingBalanceSheetsStore.updateAccountingBalanceSheet(
            balanceSheetToSave as unknown as AccountingBalanceSheetUpdate
          );
        } catch (error) {
          console.error(error);
          (context.refs.editableTable as EditableTableRef).updateIsEditing(
            accountingBalanceSheetLines.value.length - 1,
            true
          );
        }
      }
    };

    return {
      showAddItemModal,
      hideAddButton,
      accountingBalanceSheetLines,
      rules,
      headers,
      headerValueTextToOriginalKey,
      itemsKeyValueSuffix,
      accountingBalanceSheetItems,
      LedgerAccountEnum,
      hasAmortisationAccount,
      addItem,
      cancelEditItem,
      deleteItem,
      validateItem,
      goToTransactions,
    };
  },
});
