import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { IsoDate, RequireField } from "./Common.model";
import { AmortisationType } from "./Amortisation.model";

export type FixedAssetType = AmortisationType;

/**
 * `FixedAssetModel` — A fixed-asset is a long-term tangible piece of property
 * owned by a company
 */
export interface FixedAsset {
  id: string;
  productId: string;
  realEstateAssetIds: string[];
  type: FixedAssetType;
  name: string;
  commissioningAt: IsoDate;
  boughtAt: IsoDate;
  boughtPrice: number;
}
export type FixedAssetCreate = Omit<FixedAsset, "id" | "createdAt" | "updatedAt">;
export type FixedAssetList = Partial<
  Pick<FixedAsset, "productId"> & { realEstateAssetId: FixedAsset["realEstateAssetIds"][number] }
>;
export type FixedAssetListInternal = Partial<FixedAssetList & { productIds: string[] }>;
export type FixedAssetUpdate = RequireField<Partial<Omit<FixedAsset, "productId" | "createdAt" | "updatedAt">>, "id">;
export type FixedAssetUpdateInternal = RequireField<Partial<Omit<FixedAsset, "createdAt" | "updatedAt">>, "id">;

const fixedAssetTypeText = new Map<FixedAssetType, string>([
  ["roof", "Toiture"],
  ["small-work", "Installation générale technique (élec, pompes chaleur, etc..)"],
  ["facade", "Façade/Étanchéité"],
  ["elevators", "Ascenseur"],
  ["furniture", "Agencements intérieurs/Meubles"],
  ["structural-work", "Structure (gros œuvre)"],
]);

export const getFixedAssetTypeText = (fixedAssetType: FixedAssetType): string =>
  fixedAssetTypeText.get(fixedAssetType) ?? "Autre";
export const getFixedAssetTypeTexts = (): {
  text: string;
  value: FixedAssetType;
}[] => [
  { text: getFixedAssetTypeText("roof"), value: "roof" },
  {
    text: getFixedAssetTypeText("small-work"),
    value: "small-work",
  },
  { text: getFixedAssetTypeText("facade"), value: "facade" },
  { text: getFixedAssetTypeText("elevators"), value: "elevators" },
  { text: getFixedAssetTypeText("furniture"), value: "furniture" },
  {
    text: getFixedAssetTypeText("structural-work"),
    value: "structural-work",
  },
];

const fixedAssetSchema = new Schema<FixedAsset>(
  {
    _id: { type: String, default: () => ulid() },
    name: { type: String, required: true, maxlength: 200 },
    productId: { type: String, index: true, required: true },
    realEstateAssetIds: [{ type: String, index: true, required: true }],
    commissioningAt: String,
    boughtAt: String,
    boughtPrice: Number,
    type: { type: String, required: true },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: FixedAssetDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type FixedAssetDocument = FixedAsset & Document<string>;

// Name of the collection in third argument
export const FixedAssetModel = model<FixedAssetDocument>("FixedAsset", fixedAssetSchema, "FixedAsset");

// API
export namespace FixedAssetsService {
  export type CreateIn = FixedAssetCreate;
  export type CreateOut = FixedAsset;

  export type ListIn = FixedAssetList;
  export type ListOut = FixedAsset[];

  export type GetIn = Pick<FixedAsset, "id">;
  export type GetOut = FixedAsset;

  export type UpdateIn = FixedAssetUpdate;
  export type UpdateOut = FixedAsset;
}
