














































import { getMoment } from "@edmp/api";
import {
  defineComponent,
  ref,
  Ref,
  computed,
  watch,
  PropType,
} from "@vue/composition-api";
import { formatDate } from "@/utils";

export default defineComponent({
  name: "DatePicker",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
      validator(value) {
        try {
          return getMoment(value as string, "YYYY-MM-DD").isValid();
        } catch {
          return false;
        }
      },
    },
    min: {
      type: String,
      default: undefined,
      validator(value) {
        if (!value) return true;
        try {
          return getMoment(value as string, "YYYY-MM-DD").isValid();
        } catch {
          return false;
        }
      },
    },
    max: {
      type: String,
      default: undefined,
      validator(value) {
        if (!value) return true;
        try {
          return getMoment(value as string, "YYYY-MM-DD").isValid();
        } catch {
          return false;
        }
      },
    },
    error: {
      type: [String, Boolean],
      default: undefined,
    },
    label: {
      type: String,
      default: "Sélectionner une date",
    },
    refId: {
      type: String,
      default: "datePickerId",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    alwaysReopenOnYear: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Function, Array],
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    formatDate: {
      type: Function as PropType<(date: string) => string>,
      default: undefined,
    },
    "hide-details": {
      type: [String, Boolean],
      default: "auto",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  setup(props) {
    const picker = ref();
    const date = ref(props.value);
    const datePickerShowed: Ref<boolean> = ref(false);
    const computedValue = computed(() => {
      return props.formatDate
        ? props.formatDate(props.value)
        : formatDate(props.value);
    });

    if (props.alwaysReopenOnYear) {
      watch(datePickerShowed, (val) => {
        val && setTimeout(() => (picker.value.activePicker = "YEAR"));
      });
    }

    watch(date, () => {
      props.value = date.value;
    });

    return {
      date,
      picker,
      computedValue,
      datePickerShowed,
      inputClass: props.dense ? "shrink date-picker" : undefined,
    };
  },
});
