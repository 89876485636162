import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { PartialField, RequireField, Verified } from "./Common.model";
import { ProductStats } from "./ProductStats.model";
import { AccessModel } from "./Access.model";
import { ArchivedFields } from "./Archive.model";
import { AccountingPeriod, AccountingPeriodCreate } from "./AccountingPeriod.model";
import { ActivitiesModel } from "./Activities.model";
import { TaxRegime } from "./TaxRegime.enum";

export namespace ProductsModel {
  /**
   * * Types
   */
  export enum ProductTypes {
    SCI = "sci",
    LMNP = "lmnp",
  }

  export enum ProductStatus {
    pending = "pending",
    completed = "completed",
    end = "end",
  }

  export enum Tags {
    LRP = "LRP", // Onboarding type LRP (https://gitlab.com/edmp/fonctionnel/-/issues/2554)
  }

  // * Product build fields
  type ProductCommonFields<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes
  > = {
    id: string;
    type: ProductType;
    status: ProductStatus;
    accessUsers: AccessModel.AccessUser<AccessType>[];
    name: string;

    stripeCustomerId?: string;

    bankAccounts: string[];

    email: string | null;
    emailStatus: Verified;
    newEmail?: string | null;
    emailVerifyToken?: string;
    emailVerifyExpirationDate?: string;
    oldEmail?: string | null; // Old email to store email for canceled subscription

    tags: Tags[];

    createdAt: string;
    updatedAt: string;
  };

  type ProductTypeSciCommonFields = {
    type: ProductTypes.SCI;
    accountingPeriods: AccountingPeriod[]; // ! Not in BDD
    lastTaxRegime?: TaxRegime; // ! Not in BDD
  };
  type ProductTypeSciFields = ProductTypeSciCommonFields & {
    activity: ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.COMPANY>; // ! Not in BDD
  };

  type ProductTypeLMNPCommonFields = {
    type: ProductTypes.LMNP;
    dedicatedBankAccount: boolean;
    accountingPeriods: AccountingPeriod[]; // ! Not in BDD
    lastTaxRegime?: TaxRegime; // ! Not in BDD
  };
  type ProductTypeLmnpFields = ProductTypeLMNPCommonFields & {
    activity: ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.OPERATOR>; // ! Not in BDD
  };

  type ProductTypeIdFields = { activityId: string };

  // * Product
  export type Product<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
    IsArchived extends boolean = boolean
  > = ProductCommonFields<ProductType, AccessType> &
    ArchivedFields<IsArchived> &
    (ProductTypeSciFields | ProductTypeLmnpFields);

  export type ProductRepository<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
    IsArchived extends boolean = boolean
  > = ProductCommonFields<ProductType, AccessType> &
    ArchivedFields<IsArchived> &
    ProductTypeIdFields &
    (
      | Omit<ProductTypeSciCommonFields, "accountingPeriods" | "lastTaxRegime">
      | Omit<ProductTypeLMNPCommonFields, "accountingPeriods" | "lastTaxRegime">
    );

  export type ProductCreate<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes
  > = PartialField<
    Omit<
      ProductCommonFields<ProductType, AccessType>,
      | "id"
      | "status"
      | "accessUsers"
      | "stripeCustomerId"
      | "bankAccounts"
      | "email"
      | "emailStatus"
      | "emailVerifyToken"
      | "emailVerifyExpirationDate"
      | "oldEmail"
      | "companyId"
      | "operatorId"
      | "oldEmail"
      | "isArchived"
      | "createdAt"
      | "updatedAt"
    >,
    "tags"
  > &
    (
      | (Omit<ProductTypeSciCommonFields, "accountingPeriods" | "lastTaxRegime"> & {
          activity: ActivitiesModel.ActivityCreate<ActivitiesModel.ActivityTypes.COMPANY>;
          accountingPeriods: AccountingPeriodCreate[];
        })
      | (Omit<ProductTypeLMNPCommonFields, "accountingPeriods" | "lastTaxRegime"> & {
          activity: ActivitiesModel.ActivityCreate<ActivitiesModel.ActivityTypes.OPERATOR>;
          accountingPeriods: AccountingPeriodCreate[];
        })
    );
  export type ProductCreateInternal<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes
  > = PartialField<
    Omit<
      ProductCommonFields<ProductType, AccessType>,
      | "id"
      | "status"
      | "stripeCustomerId"
      | "bankAccounts"
      | "email"
      | "emailStatus"
      | "emailVerifyToken"
      | "emailVerifyExpirationDate"
      | "oldEmail"
      | "companyId"
      | "operatorId"
      | "oldEmail"
      | "isArchived"
      | "createdAt"
      | "updatedAt"
    >,
    "tags"
  > &
    (
      | (Omit<ProductTypeSciCommonFields, "accountingPeriods" | "lastTaxRegime"> & {
          activity: ActivitiesModel.ActivityCreate<ActivitiesModel.ActivityTypes.COMPANY>;
          accountingPeriods: AccountingPeriodCreate[];
        })
      | (Omit<ProductTypeLMNPCommonFields, "accountingPeriods" | "lastTaxRegime"> & {
          activity: ActivitiesModel.ActivityCreate<ActivitiesModel.ActivityTypes.OPERATOR>;
          accountingPeriods: AccountingPeriodCreate[];
        })
    );
  export type ProductCreateRepository<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes
  > = PartialField<
    Omit<
      ProductCommonFields<ProductType, AccessType>,
      "stripeCustomerId" | "email" | "oldEmail" | "isArchived" | "createdAt" | "updatedAt"
    >,
    "tags"
  > &
    ProductTypeIdFields &
    (
      | Omit<ProductTypeSciCommonFields, "accountingPeriods" | "lastTaxRegime">
      | Omit<ProductTypeLMNPCommonFields, "accountingPeriods" | "lastTaxRegime">
    );

  export type ProductUpdate<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes
  > = RequireField<
    Partial<
      Omit<
        ProductCommonFields<ProductType, AccessType>,
        | "status"
        | "accessUsers"
        | "stripeCustomerId"
        | "bankAccounts"
        | "email"
        | "emailStatus"
        | "emailVerifyToken"
        | "emailVerifyExpirationDate"
        | "oldEmail"
        | "isArchived"
        | "tags"
        | "createdAt"
        | "updatedAt"
      >
    >,
    "id"
  > &
    Partial<
      | Omit<ProductTypeSciCommonFields, "accountingPeriods" | "lastTaxRegime">
      | Omit<ProductTypeLMNPCommonFields, "accountingPeriods" | "lastTaxRegime">
    >;
  export type ProductUpdateInternal<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
    IsArchived extends boolean = boolean
  > = RequireField<
    Partial<
      Omit<
        ProductCommonFields<ProductType, AccessType>,
        | "bankAccounts"
        | "email"
        | "emailStatus"
        | "emailVerifyToken"
        | "emailVerifyExpirationDate"
        | "oldEmail"
        | "createdAt"
        | "updatedAt"
      >
    >,
    "id"
  > &
    Partial<
      ArchivedFields<IsArchived> &
        (
          | Omit<ProductTypeSciCommonFields, "accountingPeriods" | "lastTaxRegime">
          | Omit<ProductTypeLMNPCommonFields, "accountingPeriods" | "lastTaxRegime">
        ) & {
          addBankAccountId: string;
          deleteBankAccountId: string;
        }
    >;
  export type ProductUpdateRepository<
    ProductType extends ProductTypes = ProductTypes,
    AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
    IsArchived extends boolean = boolean
  > = RequireField<Partial<Omit<ProductCommonFields<ProductType, AccessType>, "createdAt" | "updatedAt">>, "id"> &
    Partial<
      ArchivedFields<IsArchived> &
        (
          | Omit<ProductTypeSciCommonFields, "accountingPeriods" | "lastTaxRegime">
          | Omit<ProductTypeLMNPCommonFields, "accountingPeriods" | "lastTaxRegime">
        )
    >;

  /**
   * * Mongo
   */
  export type ProductDocument = ProductRepository & Document<string>;
  const productSchema = new Schema<ProductDocument>(
    {
      _id: { type: String, default: () => ulid(), required: true },
      type: { type: String, enum: Object.values(ProductTypes), required: true, index: true },
      status: { type: String, enum: Object.values(ProductStatus), required: true },
      accessUsers: { type: [AccessModel.accessUserSchema], required: true },
      name: { type: String },

      stripeCustomerId: { type: String },

      bankAccounts: [{ type: String }],
      dedicatedBankAccount: { type: Boolean },

      email: { type: String },
      emailStatus: { type: String },
      newEmail: { type: String },
      emailVerifyToken: { type: String },
      emailVerifyExpirationDate: { type: String },
      oldEmail: { type: String },

      activityId: { type: String, index: true },

      tags: { type: [{ type: String }] },

      isArchived: { type: Boolean },
    },
    {
      timestamps: true,
      toJSON: {
        versionKey: false,
        virtuals: true,
        transform(doc, ret: ProductDocument) {
          ret.id = ret._id;
          delete ret._id;
          return ret;
        },
      },
    }
  );
  export const ProductModel = model<ProductDocument>("Product", productSchema, "Products");
}

/**
 * * API
 */
export namespace ProductsService {
  export type CreateIn<Type extends ProductsModel.ProductTypes = ProductsModel.ProductTypes> =
    ProductsModel.ProductCreate<Type>;
  export type CreateOut = ProductsModel.Product;

  export type ListIn = Partial<Pick<ProductsModel.Product, "type">>;
  export type ListOut = ProductsModel.Product[];

  export type ListPaginateIn = { page: number; limit: number };
  export type ListPaginateOut = ProductsModel.Product[];

  export type GetIn = Pick<ProductsModel.Product, "id">;
  export type GetOut<Type extends ProductsModel.ProductTypes = ProductsModel.ProductTypes> =
    ProductsModel.Product<Type>;

  export type ValidateEmailIn = Required<Pick<ProductsModel.Product, "emailVerifyToken">>;
  export type ValidateEmailOut = void;

  export type UpdateIn = ProductsModel.ProductUpdate;
  export type UpdateOut = ProductsModel.Product;

  export type ArchiveIn = Required<Pick<ProductsModel.Product, "id" | "isArchived">>;
  export type ArchiveOut = ProductsModel.Product;

  export type CountIn = never;
  export type CountOut = { count: number };

  export type StockIn = { date?: string };
  export type StockOut = ProductStats;
}

/**
 * * Lib
 */
export namespace ProductsLib {
  export const isProductType =
    <
      Type extends ProductsModel.ProductTypes,
      Product extends ProductsModel.Product | ProductsModel.ProductCreateInternal | ProductsModel.ProductRepository
    >(
      type: Type
    ) =>
    (product: Product): product is Product & { type: Type } =>
      product.type === type;

  export const isUserAccessType =
    <AccessType extends AccessModel.AccessTypes>(accessUserType: AccessType, userId: string) =>
    (product: ProductsModel.Product): product is ProductsModel.Product<ProductsModel.ProductTypes, AccessType> =>
      product.accessUsers.some((a) => a.accessType === accessUserType && a.userId === userId);

  export const isArchivedProduct = <Product extends ProductsModel.Product | ProductsModel.ProductUpdateRepository>(
    product: Product
  ): product is Product & { isArchived: true } => !!product.isArchived;
  export const isNotArchivedProduct = (
    product: ProductsModel.Product
  ): product is ProductsModel.Product<ProductsModel.ProductTypes, AccessModel.AccessTypes, false> =>
    !product.isArchived;

  export const getProductIds = (products: ProductsModel.Product[]) => products.map((p) => p.id);
  export const getProduct = (products: ProductsModel.Product[], id: ProductsModel.Product["id"]) =>
    products.find((p) => p.id === id);

  export const getMyUserAccessType = (product: ProductsModel.Product, userId: string) =>
    product.accessUsers.find((p) => p.userId === userId)!.accessType;
}
