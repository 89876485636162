














































































































import { useEmail, usePassword } from "@/composables";
import { VForm } from "@/models";
import { userAccountService, usersService } from "@/services";
import { coreStore, usersStore } from "@/store";
import { Utm } from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref,
} from "@vue/composition-api";
import { getUtm } from "@/utils";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";

export default defineComponent({
  name: "RegisterUserAccount",
  props: {
    step: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const email: Ref<string> = ref("");
    const password: Ref<string> = ref("");
    const showPassword: Ref<boolean> = ref(false);
    const acceptationCGU: Ref<boolean> = ref(false);
    const optinMarket: Ref<boolean> = ref(false);

    const validateInProgress: Ref<boolean> = ref(false);
    const error: Ref<boolean> = ref(false);

    const utm: ComputedRef<Utm> = computed(() =>
      getUtm(context.root.$route.query)
    );

    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      error.value = false;
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;
        try {
          await userAccountService
            .create({ username: email.value, password: password.value })
            .then(
              async () =>
                await userAccountService.login({
                  username: email.value,
                  password: password.value,
                })
            )
            .then(async () => {
              /// Create a User with same email
              const user = await usersService.updateUser({
                id: "me",
                email: email.value,
                newEmail: email.value, // To validate this email
                optinMarket: optinMarket.value,
                utm: utm.value,
              });
              dispatchOnBoardingEvent({
                step: OnBoardingEventSteps.USER_ACCOUNT,
                userId: user.id,
              });
              await usersService.acceptTos({
                id: "me",
                tos: {
                  version: coreStore.config.user.tosVersion,
                  accepted: acceptationCGU.value,
                },
              });
            })
            .then(() => {
              usersStore.fetchLoggedInUser();
              // Store UTM tracking
              coreStore.createUtm(utm.value);

              context.emit("validate");
            });
        } catch (err) {
          console.error(err);
          error.value = true;
          validateInProgress.value = false;
        }
      }
    }

    const { validEmailRule, emailLowerCase } = useEmail();

    async function openCGU(e: Event): Promise<void> {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      window.open(
        "https://www.ownily.fr/legal/conditions-generales-utilisation",
        "_blank"
      );
    }

    async function openPVP(e: Event): Promise<void> {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      window.open("https://www.ownily.fr/legal/politique-vie-privee", "_blank");
    }

    const { validPasswordRule, getPasswordRule } = usePassword();
    return {
      emailLowerCase: emailLowerCase(email),
      validEmailRule,
      password,
      showPassword,
      acceptationCGU,
      openCGU,
      optinMarket,
      error,
      validate,
      validateInProgress,
      validPasswordRule,
      getPasswordRule,
      openPVP,
    };
  },
});
