import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  bankAccountsStore,
  documentsStore,
  fixedAssetsStore,
  partnersStore,
  productsStore,
  realEstateAssetsStore,
  realEstateLoansStore,
  rentalAgreementsStore,
  transactionsStore,
} from "@/store";
import { FilterKeys, FiltersParameters } from "@/utils";
import {
  AnomalyError,
  CategorizationEntryParameters,
  TenantTypeEnum,
  TypeReference,
} from "@edmp/api";

export const useTransactions = () => {
  /**
   * * Store
   * Use for provide store action to component
   */
  const updateCategoriesList = async () => {
    return await transactionsStore.updateCategoriesList();
  };

  /**
   * * Use
   */
  /**
   * It adds a filter to the transactions store
   */
  const addFilter = <Keys extends FilterKeys>(
    code: Keys,
    params?: FiltersParameters[Keys]
  ) => {
    transactionsStore.removeFilter(code);
    transactionsStore.addFilter({ code: code, filterParams: params });
  };

  /**
   * It removes a filter from the transactions store
   * @param {FilterKeys} code - The code of the filter to remove.
   */
  const removeFilter = (code: FilterKeys) => {
    transactionsStore.removeFilter(code);
  };

  /**
   * It resets the filter
   */
  const resetFilter = () => {
    transactionsStore.resetFilter();
  };

  /**
   * It adds a filter to the transactions store, and then navigates to the transactions page
   * @param {{ anomaly?: AnomalyError; transactionsIds?: string[]; }} params -
   * - `anomaly`: Filter by anomaly
   * - `transactionsIds`: Filter by transaction ids, /!\ override `anomaly` filter but apply label
   */
  const goTransactionAnomaliesFilter = ({
    anomaly,
    transactionsIds,
  }: {
    anomaly?: AnomalyError;
    transactionsIds?: string[];
  }) => {
    const ANOMALY_LABEL_BY_REFERENCE: {
      [Key in AnomalyError]: string;
    } = {
      [AnomalyError.referenceTypeBankAccountRequired]:
        "À corriger : Compte bancaire",
      [AnomalyError.referenceTypeRealEstateAssetRequired]: "À corriger : Bien",
      [AnomalyError.referenceTypeRentalUnitRequired]: "À corriger : Lot",
      [AnomalyError.referenceTypeRentalAgreementRequired]:
        "À corriger : Locataire",
      [AnomalyError.referenceTypePartnerRequired]: "À corriger : Partenaire",
      [AnomalyError.referenceTypeRealEstateLoanRequired]:
        "À corriger : Emprunt",
      [AnomalyError.referenceTypeSupportingDocumentRequired]:
        "À corriger : justificatif",
      [AnomalyError.referenceTypeRentalUnitNotMatchRealEstateAsset]:
        "À corriger : Bien ou Lot",
      [AnomalyError.referenceTypeRentalAgreementNotMatchRentalUnit]:
        "À corriger : Lot ou Locataire",
      [AnomalyError.objectIdLinkRentalUnitRequired]: "À corriger : Lot",
      [AnomalyError.taxTvaEnableRequired]: "À corriger : TVA à retirer",
      [AnomalyError.taxTvaLedgerAccount445720Required]:
        "À corriger : TVA requise",
      [AnomalyError.taxTvaPositiveRequired]:
        "À corriger : Montant TVA collectée",
      [AnomalyError.loanInsuranceEnableRequired]:
        "À corriger : Assurances d'emprunt à retirer",
      [AnomalyError.loanLedgerAccount616600Required]:
        "À corriger : Assurances d'emprunt requis",
      [AnomalyError.loanLedgerAccount661110Required]:
        "À corriger : Intérêts d'emprunt requis",
      [AnomalyError.loanRepaymentDeadlineAmountEqualTransactionAmountRequired]:
        "À corriger : Montant d'emprunt",
      [AnomalyError.loanRepaymentDeadlineDateEqualTransactionDateRequired]:
        "À corriger : Date d'emprunt",
      [AnomalyError.realEstateAssetNotSameAcquisitionAmount]:
        "À corriger : Prix d'acquisition du bien est incorrecte d'après votre catégorisation",
      [AnomalyError.realEstateAssetNotSameBoughtFeeAmount]:
        "À corriger : Frais d'achat du bien sont incorrectes d'après votre catégorisation",
    };

    const label = anomaly ? ANOMALY_LABEL_BY_REFERENCE[anomaly] : undefined;

    transactionsStore.clearFilter();
    transactionsStore.addFilter({
      code: FilterKeys.ONLY_ANOMALIZED,
      filterParams: {
        label,
        anomaly: anomaly,
        transactionsIds: transactionsIds,
      },
    });

    router.push({ name: ROUTE_NAMES.Transactions });
  };

  const MISSING_VALUE = "Ø";
  /**
   * It returns the name of a real estate asset, rental unit, rental agreement, partner or real estate
   * loan, depending on the type and id passed as parameters
   */
  const getAttribute = (
    type: keyof CategorizationEntryParameters | TypeReference,
    id: string
  ): string => {
    if (type === "realEstateAsset") {
      const realEstateAsset = realEstateAssetsStore.realEstateAssets.find(
        (realEstateAsset) => realEstateAsset.id === id
      );
      return realEstateAsset ? realEstateAsset.name : MISSING_VALUE;
    }

    if (type === "rentalUnit") {
      const rentalUnit = realEstateAssetsStore.rentalUnits.find(
        (rentalUnit) => rentalUnit.id === id
      );
      return rentalUnit ? rentalUnit.name : MISSING_VALUE;
    }

    if (type === "rentalAgreement") {
      const rentalAgreement = rentalAgreementsStore.rentalAgreements.find(
        (rentalAgreement) => rentalAgreement.id === id
      );
      if (rentalAgreement?.tenant.type === TenantTypeEnum.NATURAL_PERSON) {
        return rentalAgreement
          ? `${rentalAgreement.tenant.firstName} ${rentalAgreement.tenant.name}`
          : MISSING_VALUE;
      } else if (
        rentalAgreement?.tenant.type === TenantTypeEnum.COMPANY ||
        rentalAgreement?.tenant.type === TenantTypeEnum.ASSOCIATION
      ) {
        return rentalAgreement
          ? `${rentalAgreement.tenant.denomination}`
          : MISSING_VALUE;
      }
    }

    if (type === "partner") {
      return partnersStore.getPartnerName(id);
    }

    if (type === "realEstateLoan") {
      const realEstateLoan = realEstateLoansStore.realEstateLoans.find(
        (realEstateLoan) => realEstateLoan.id === id
      );
      return realEstateLoan ? realEstateLoan.name : MISSING_VALUE;
    }

    if (type === "supportingDocument") {
      const document = documentsStore.documents.find(
        (document) => document.id === id
      );
      // Filter to ignore other documents than supporting documents even if already selected
      return document && document.tags?.includes("supportingDocument")
        ? document.metadata?.wording || MISSING_VALUE // There is always a wording for supporting documents
        : MISSING_VALUE;
    }

    if (type === "bankAccount") {
      const bankAccount = bankAccountsStore.bankAccounts.find(
        (bankAccount) => bankAccount.id === id
      );
      return bankAccount?.name || MISSING_VALUE;
    }

    if (type === "fixedAsset") {
      const fixedAsset = fixedAssetsStore.fixedAssets.find(
        (fixedAsset) => fixedAsset.id === id
      );
      return fixedAsset?.name || MISSING_VALUE;
    }

    if (type === "beneficiary") {
      const beneficiary = productsStore.products.find((p) => p.id === id);
      return beneficiary?.name || MISSING_VALUE;
    }
    return MISSING_VALUE;
  };

  return {
    //Store
    updateCategoriesList,
    // Use
    addFilter,
    removeFilter,
    resetFilter,
    goTransactionAnomaliesFilter,
    getAttribute,
  };
};
