




















































































import { computed, defineComponent, Ref, ref } from "@vue/composition-api";
import RealEstates from "../activities/RealEstates.vue";
import RealEstateLoanModal from "./realEstateLoans/RealEstateLoanModal.vue";
import RealEstateLoans from "./realEstateLoans/RealEstateLoans.vue";
import Title from "../../core/title/Title.vue";
import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import {
  productsStore,
  coreStore,
  realEstateAssetsStore,
  realEstateLoansStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "RealEstateAssetsAndLoans",
  components: { RealEstates, RealEstateLoans, RealEstateLoanModal, Title },
  props: {
    tabIndex: { type: String, required: false },
  },

  setup(props, context) {
    const tabItemIndex = ref(props.tabIndex ? parseInt(props.tabIndex) : 0);
    const productId = productsStore.currentId;

    const realEstateNew = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "addRealEstateAsset",
          subject("RentalManagement", {
            size: realEstateAssetsStore.realEstateAssets.length,
          })
        );
        const productId = productsStore.currentId;
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstate,
          params: { productId, realEstateAssetId: "new" },
        });
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };

    //Loan
    const realEstateLoanList = computed(() =>
      realEstateLoansStore.getRealEstateLoansByProductId(
        productsStore.currentId
      )
    );
    const selectedLoan = ref(undefined);
    function selectLoan(loan) {
      selectedLoan.value = loan;
      openLoanModal.value = true;
    }

    function closeLoanModal() {
      openLoanModal.value = false;
      selectedLoan.value = undefined;
    }
    function validateLoanModal() {
      closeLoanModal();
    }
    const openLoanModal: Ref<boolean> = ref(false);

    return {
      tabItemIndex,
      productId,
      realEstateLoanList,
      selectedLoan,
      openLoanModal,
      closeLoanModal,
      selectLoan,
      validateLoanModal,
      realEstateNew,
    };
  },
});
