import Vue from "vue";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  RentalBuilding,
  NewRentalBuilding,
  RealEstateAsset,
  getMoment,
  RentalBuildingUpdate,
} from "@edmp/api";
import store from "@/store/root";
import { rentalsService } from "@/services/";
import {
  accountingPeriodsStore,
  productsStore,
  realEstateAssetsStore,
} from "..";

export interface RentalBuildingsState {
  rentalBuildings: RentalBuilding[];
  loading: boolean;
}

@Module({
  name: "rental-building-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class RentalBuildingsStore
  extends VuexModule
  implements RentalBuildingsState
{
  rentalBuildings: RentalBuilding[] = [];
  loading = false;

  @Mutation
  reset(): void {
    this.rentalBuildings = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  // RentalBuildings
  @Action
  async fetchRentalBuildings(
    params: Pick<
      Partial<RentalBuilding>,
      "accountingPeriodId" | "realEstateAssetIds"
    >
  ): Promise<RentalBuilding[]> {
    this.setLoading(true);
    const rentalBuildings = await rentalsService.buildings.list({
      ...params,
      productId: productsStore.currentId,
    });
    this.setRentalBuildings(rentalBuildings);
    this.setLoading(false);
    return rentalBuildings;
  }

  // RentalBuilding
  @Mutation
  setRentalBuilding(rentalBuilding: RentalBuilding): void {
    const index = this.rentalBuildings.findIndex(
      ({ id }) => id == rentalBuilding.id
    );
    if (index !== -1) {
      Vue.set(this.rentalBuildings, index, rentalBuilding);
    } else {
      this.rentalBuildings.push(rentalBuilding);
    }
  }

  @Mutation
  setRentalBuildings(rentalBuildings: RentalBuilding[]): void {
    this.rentalBuildings = rentalBuildings;
  }

  @Mutation
  delRentalBuilding(rentalBuildingId: string): void {
    const rentalBuildingIndex = this.rentalBuildings.findIndex(
      ({ id }) => id == rentalBuildingId
    );
    if (rentalBuildingIndex !== -1) {
      this.rentalBuildings.splice(rentalBuildingIndex, 1);
    }
  }

  get getRentalBuilding() {
    return (rentalBuildingId: string): RentalBuilding | undefined => {
      return this.rentalBuildings.find(({ id }) => id === rentalBuildingId);
    };
  }

  get getRentalBuildingByProductId() {
    return (productId: string): RentalBuilding[] => {
      return this.rentalBuildings.filter(
        (rentalBuilding) => rentalBuilding.productId === productId
      );
    };
  }

  get getRentalBuildingByAccountingPeriodId() {
    return (
      accountingPeriodId: string = accountingPeriodsStore.currentId
    ): RentalBuilding[] => {
      return this.rentalBuildings.filter(
        (rentalBuilding) =>
          rentalBuilding.accountingPeriodId === accountingPeriodId
      );
    };
  }

  get getRentalBuildingByRealEstateAssetIds() {
    return (realEstateAssetIds: string[]): RentalBuilding[] => {
      return this.rentalBuildings.filter(
        (rentalBuilding) =>
          !!rentalBuilding.realEstateAssetIds.find((realEstateAssetId) =>
            realEstateAssetIds.includes(realEstateAssetId)
          )
      );
    };
  }

  get getRealEstateAssetsNotAssignedByAccountingPeriodId() {
    return (
      accountingPeriodId: string,
      rentalBuildingId: string
    ): RealEstateAsset[] => {
      return realEstateAssetsStore.realEstateAssets.filter(
        (realEstateAsset) => {
          for (const rentalBuilding of this.getRentalBuildingByAccountingPeriodId(
            accountingPeriodId
          )) {
            if (
              rentalBuilding.id !== rentalBuildingId &&
              rentalBuilding.realEstateAssetIds.includes(realEstateAsset.id)
            ) {
              return false;
            }
          }
          return true;
        }
      );
    };
  }

  get getRealEstateAssetsNotAssignedWithGoodDateByAccountingPeriodId() {
    return (
      accountingPeriodId: string,
      rentalBuildingId: string
    ): RealEstateAsset[] => {
      return this.getRealEstateAssetsNotAssignedByAccountingPeriodId(
        accountingPeriodId,
        rentalBuildingId
      ).filter((realEstateAsset) => {
        if (
          realEstateAsset.boughtAt &&
          getMoment(realEstateAsset.boughtAt) >
            getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt)
        ) {
          return false;
        }
        return true;
      });
    };
  }
  // Create
  @Action
  async createRentalBuilding({
    newRentalBuilding,
    customProductId = productsStore.currentId,
    customAccountingPeriodId = accountingPeriodsStore.currentId,
  }: {
    newRentalBuilding: Omit<
      NewRentalBuilding,
      "productId" | "accountingPeriodId"
    >;
    customAccountingPeriodId?: typeof accountingPeriodsStore.currentId;
    customProductId?: typeof productsStore.currentId;
  }): Promise<RentalBuilding> {
    this.setLoading(true);
    const rentalBuilding = await rentalsService.buildings.create({
      ...newRentalBuilding,
      productId: customProductId,
      accountingPeriodId: customAccountingPeriodId,
    });
    this.setRentalBuilding(rentalBuilding);
    this.setLoading(false);
    return rentalBuilding;
  }

  // Update
  @Action
  async updateRentalBuilding(
    rentalBuildingUpdate: RentalBuildingUpdate
  ): Promise<RentalBuilding> {
    this.setLoading(true);
    const rentalBuilding = await rentalsService.buildings.update(
      rentalBuildingUpdate
    );
    await this.setRentalBuilding(rentalBuilding);
    this.setLoading(false);
    return rentalBuilding;
  }

  // Delete
  @Action
  async deleteRentalBuilding(id: string): Promise<boolean> {
    this.setLoading(true);
    const isDeletedRentalBuilding = await rentalsService.buildings.remove({
      id,
    });
    if (isDeletedRentalBuilding) {
      await this.delRentalBuilding(id);
    }
    this.setLoading(false);
    return isDeletedRentalBuilding;
  }
}
