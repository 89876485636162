






































































































































































































































































import SCISearch from "@/components/sign/signup/steps/SCISearch.vue";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { VForm } from "@/models";
import { productsStore, usersStore } from "@/store";
import { onlyNumber } from "@/utils";
import {
  ActivitiesModel,
  Etablissement,
  ProductsModel,
  ProductsService,
} from "@edmp/api";
import { defineComponent, ref, Ref, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "RegisterActivityLMNP",
  components: {
    SCISearch,
  },
  props: {
    type: {
      type: String as PropType<ProductsModel.Tags>,
      require: false,
    },
  },
  setup(props, context) {
    const dialog: Ref<boolean> = ref(false);
    const validateInProgress: Ref<boolean> = ref(false);

    const activityType: Ref<"SCI" | "LMNP"> = ref("LMNP");
    const activityRegistered: Ref<boolean> = ref(true);
    const activityAddressIsSameAsOperatorAddress: Ref<boolean> = ref(true);

    const productForm = ref<
      Omit<
        ProductsModel.ProductCreate<ProductsModel.ProductTypes.LMNP>,
        "type" | "accountingPeriods" | "dedicatedBankAccount" | "activity"
      > & {
        activity: Omit<
          ActivitiesModel.ActivityCreate<
            ActivitiesModel.ActivityTypes.OPERATOR,
            ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
          >,
          "type" | "operatorType" | "hasCga"
        >;
      }
    >({
      name: "",
      activity: {
        lastName: "",
        firstName: "",
        siret: "",
        indieChecked: false,
        address: { street: "", city: "", zip: "" },
        activityAddress: { street: "", city: "", zip: "" },
      },
    });

    function autocompleteEnterprise(e: Etablissement): void {
      productForm.value.activity.siret = e.siret;
      productForm.value.activity.address = {
        street: `${e.adresseEtablissement.numeroVoieEtablissement ?? ""}${
          e.adresseEtablissement.indiceRepetitionEtablissement ?? ""
        } ${e.adresseEtablissement.typeVoieEtablissement ?? ""} ${
          e.adresseEtablissement.libelleVoieEtablissement ?? ""
        }`,
        zip: e.adresseEtablissement.codePostalEtablissement ?? "",
        city: e.adresseEtablissement.libelleCommuneEtablissement ?? "",
      };
      dialog.value = false;
      // focus on last autocompleted field
      requestAnimationFrame(() =>
        (context.refs.siretInput as HTMLElement).focus()
      );
    }

    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;
        try {
          const product: ProductsService.CreateIn = {
            type: ProductsModel.ProductTypes.LMNP,
            name: productForm.value.name,
            activity: {
              type: ActivitiesModel.ActivityTypes.OPERATOR,
              operatorType:
                ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON,
              firstName: productForm.value.activity.firstName,
              lastName: productForm.value.activity.lastName,
              siret: productForm.value.activity.siret,
              address: productForm.value.activity.address,
              activityAddress: activityAddressIsSameAsOperatorAddress.value
                ? productForm.value.activity.address
                : productForm.value.activity.activityAddress,
              indieChecked: false,
              hasCga: false,
            },
            dedicatedBankAccount: false,
            accountingPeriods: [],
            ...(props.type ? { tags: [props.type] } : {}),
          };

          await productsStore.createProduct(product);

          dispatchOnBoardingEvent({
            step: OnBoardingEventSteps.ACTIVITY,
            userId: usersStore.loggedInUser.id,
            activityType: "LMNP",
          });

          context.emit(
            activityRegistered.value ? "validate" : "validateNonRegistered"
          );
        } catch (err) {
          validateInProgress.value = false;
        }
      }
    }

    return {
      productForm,
      dialog,
      activityType,
      activityRegistered,
      activityAddressIsSameAsOperatorAddress,
      onlyNumberRule: onlyNumber,
      autocompleteEnterprise,
      validate,
      validateInProgress,
    };
  },
});
