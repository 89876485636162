




























































































































































































































































import { useBudgetInsight } from "@/composables";
import { productsStore, usersStore } from "@/store";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import router from "@/router";
import { ProductsModel, TaxRegime } from "@edmp/api";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "RegisterBankAccount",
  props: {
    step: {
      type: Number,
      required: false,
    },
    taxRegime: {
      type: String as PropType<TaxRegime>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDedicatedBankAccount = ref(
      productsStore?.currentProduct?.type === ProductsModel.ProductTypes.LMNP
        ? productsStore?.currentProduct?.dedicatedBankAccount
        : false
    );

    const showCalendly = ref(false);
    const openCalendly = (value: boolean) => {
      showCalendly.value = value;
      if (value) {
        requestForAccompanied.value = true;
      }
    };

    const {
      requestForAccompanied,
      url,
      getUrl,
      iframeLoadChange,
      displayIframe,
      biIframe,
      providerBI,
      providerMocks,
      fetchAvailableBank,
      fetchSynchronizeBankAccount,
      changeAccounts,
      stopSynchro,
    } = useBudgetInsight(productsStore.currentId as string, !props.step);

    //Init
    fetchAvailableBank();
    fetchSynchronizeBankAccount();
    // TODO MESSAGE AVANT DE SYNCHRONISER UN NOUVEAU COMPTE CORRIGER ....
    const user = computed(() => usersStore.loggedInUser);

    function cancelAddBankAccount() {
      stopSynchro();
      dispatchOnBoardingEvent({
        step: OnBoardingEventSteps.BANK_ACCOUNT,
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        isCanceled: true,
        requestForAccompanied: requestForAccompanied.value,
      });
      if (props.step)
        router.push({ name: ROUTE_NAMES.Dashboard, query: { welcome: "new" } });
      else emit("cancel");
    }

    function updateDedicatedBankAccount(boolean: boolean | undefined) {
      isDedicatedBankAccount.value = !!boolean;
      const data = {
        ...productsStore.currentProduct,
        id: productsStore.currentId,
        dedicatedBankAccount: boolean,
      };
      productsStore.updateProduct(data);
      stopSynchro();
    }

    watch(changeAccounts, (newVal) => {
      if (newVal) emit("accountsChange");
    });

    return {
      updateDedicatedBankAccount,
      isDedicatedBankAccount,
      openCalendly,
      showCalendly,
      productsStore,
      displayIframe,
      getUrl,
      url,
      iframeLoadChange,
      biIframe,
      providerBI,
      providerMocks,
      cancelAddBankAccount,
      user,
      TaxRegime,
    };
  },
  // mounted () {
  //   const recaptchaScript = document.createElement('script')
  //   recaptchaScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
  //   recaptchaScript.async = true
  //   document.head.appendChild(recaptchaScript)
  // }
});
