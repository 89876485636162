







































































































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  accountingPeriodsStore,
  productsStore,
  coreStore,
  realEstateAssetsStore,
  rentalBuildingsStore,
} from "@/store";
import {
  getMoment,
  IR2072Deductions,
  IR2072NatureA,
  NewRentalBuilding,
  RentalState,
  RentalBuildingUpdate,
  DPE,
} from "@edmp/api";
import { VForm } from "@/models";
import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "RentalBuilding",
  components: {},
  props: {
    rentalBuildingId: {
      type: String as PropType<string | "new">,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    /**
     * Data
     */
    const isNew = computed(() => props.rentalBuildingId === "new");
    const isLoading = computed(() => rentalBuildingsStore.loading);
    const isLoadingValidate = ref(false);
    const newRentalBuilding: Ref<
      Omit<NewRentalBuilding, "productId" | "accountingPeriodId">
    > = ref({
      name: "",
      realEstateAssetIds: [],
      address: {
        street: "",
        city: "",
        zip: "",
      },
      taxDeduction: IR2072Deductions.NONE,
      nature: IR2072NatureA.AUTRE,
      premises: 0,
      newAcquisition: false,
      dpe: { dpeGrade: DPE.UNKNOWN },
    });

    const rentalBuildingCreateOrUpdate: Ref<
      typeof newRentalBuilding.value | RentalBuildingUpdate
    > = ref(cloneDeep(newRentalBuilding.value));

    const realEstateAssets = computed(() =>
      rentalBuildingsStore
        .getRealEstateAssetsNotAssignedByAccountingPeriodId(
          accountingPeriodsStore.currentId,
          props.rentalBuildingId
        )
        .map((realEstateAsset) => {
          const temp = {
            text: `${realEstateAsset.name}`,
            value: realEstateAsset.id,
            disabled: false,
            alert: false,
          };
          if (
            realEstateAsset.boughtAt &&
            getMoment(realEstateAsset.boughtAt) >
              getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt)
          ) {
            temp.alert = true;
          }
          return temp;
        })
    );

    const selectedRealEstateAssetRules = (): {
      valid: boolean;
      name: string;
      message: string;
    } => {
      if (
        !(
          rentalBuildingCreateOrUpdate.value.realEstateAssetIds &&
          rentalBuildingCreateOrUpdate.value.realEstateAssetIds.length
        )
      ) {
        return {
          valid: false,
          name: "EMPTY",
          message: "Le champ ne peut pas être vide",
        };
      } else {
        const realEstateAssetsNotAssignedWithWrongDateSelected =
          realEstateAssetsStore.realEstateAssets.filter((realEstateAsset) => {
            if (
              rentalBuildingCreateOrUpdate.value.realEstateAssetIds &&
              rentalBuildingCreateOrUpdate.value.realEstateAssetIds.includes(
                realEstateAsset.id
              ) &&
              realEstateAsset.boughtAt &&
              getMoment(realEstateAsset.boughtAt) >
                getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt)
            ) {
              return true;
            }
            return false;
          });

        if (realEstateAssetsNotAssignedWithWrongDateSelected.length) {
          return {
            valid: false,
            name: "WRONG DATE",
            message: `La date d'achat du bien "${realEstateAssetsNotAssignedWithWrongDateSelected[0].name}" est plus récente que l'année d'exercice.`,
          };
        }
      }

      const realEstateAssets = realEstateAssetsStore.realEstateAssets.filter(
        (realEstateAsset) =>
          rentalBuildingCreateOrUpdate.value.realEstateAssetIds?.includes(
            realEstateAsset.id
          )
      );
      const isEqualRealEstateAssetTaxDeduction = realEstateAssets.every(
        (realEstateAsset) =>
          realEstateAsset.taxDeduction === realEstateAssets[0].taxDeduction
      );
      if (!isEqualRealEstateAssetTaxDeduction) {
        return {
          valid: false,
          name: "NOT_EQUAL_TAX_DEDUCTION",
          message: "Les biens sélèctionnés doivent avoir le même régime fiscal",
        };
      }

      const isEqualRealEstateAssetNature = realEstateAssets.every(
        (realEstateAsset) =>
          realEstateAsset.nature === realEstateAssets[0].nature
      );
      if (!isEqualRealEstateAssetNature) {
        const isEqualRealEstateAssetNatureImmeuble = realEstateAssets.every(
          (realEstateAsset) =>
            realEstateAsset.nature === IR2072NatureA.IMMEUBLE_URBAIN ||
            realEstateAsset.nature === IR2072NatureA.IMMEUBLE_RURAL ||
            realEstateAsset.nature === IR2072NatureA.IMMEUBLE_DE_RAPPORT
        );
        if (!isEqualRealEstateAssetNatureImmeuble) {
          return {
            valid: false,
            name: "NOT_EQUAL_NATURE",
            message: "Les biens sélèctionnés doivent avoir la même nature",
          };
        }
      }

      const isFirstRealEstateHasBeenBoughtDuringThisAccountingPeriod =
        getMoment(realEstateAssets[0].boughtAt).isBetween(
          accountingPeriodsStore.currentAccountingPeriod?.startAt,
          accountingPeriodsStore.currentAccountingPeriod?.endAt,
          "day",
          "[]"
        );
      const isEqualRealEstateAssetNewAcquisition = realEstateAssets.every(
        (realEstateAsset) =>
          getMoment(realEstateAsset.boughtAt).isBetween(
            accountingPeriodsStore.currentAccountingPeriod?.startAt,
            accountingPeriodsStore.currentAccountingPeriod?.endAt,
            "day",
            "[]"
          ) === isFirstRealEstateHasBeenBoughtDuringThisAccountingPeriod
      );
      if (!isEqualRealEstateAssetNewAcquisition) {
        return {
          valid: false,
          name: "NOT_EQUAL_NEW_ACQUISITION",
          message:
            "Un bien acquis cette année ne peut pas être regrouper avec un bien acquis les années précédentes",
        };
      }

      return { valid: true, name: "OK", message: "Aucune erreur" };
    };

    const isShowSelectRentalBuildingAdress = ref(false);
    const realEstateAssetsAdress = computed(() =>
      rentalBuildingCreateOrUpdate.value.realEstateAssetIds
        ?.map((realEstateAssetId) => {
          const realEstateAsset =
            realEstateAssetsStore.getRealEstateAsset(realEstateAssetId);
          if (realEstateAsset) {
            return {
              text: `${
                realEstateAsset.address ? realEstateAsset.address.street : ""
              }, ${
                realEstateAsset.address ? realEstateAsset.address.zip : ""
              } ${
                realEstateAsset.address ? realEstateAsset.address.city : ""
              } (${realEstateAsset.name})`,
              value: realEstateAsset.address,
              disabled: false,
            };
          }
        })
        .filter((realEstateAssetAdress) => realEstateAssetAdress !== undefined)
    );

    const isShowSelectRentalBuildingNature = ref(false);
    const realEstateAssetsNature = computed(() =>
      Object.entries(IR2072NatureA)
        .filter(
          ([, value]) =>
            value === IR2072NatureA.IMMEUBLE_URBAIN ||
            value === IR2072NatureA.IMMEUBLE_RURAL ||
            value === IR2072NatureA.IMMEUBLE_DE_RAPPORT
        )
        .map(([key, value]) => ({
          text:
            key.toLowerCase().charAt(0).toUpperCase() +
            key.toLowerCase().slice(1).split("_").join(" ") +
            ` (${value})`,
          value,
          disabled: false,
        }))
    );

    watch(
      () => rentalBuildingCreateOrUpdate.value.realEstateAssetIds,
      () => {
        const realEstateAssets = realEstateAssetsStore.realEstateAssets.filter(
          (realEstateAsset) =>
            rentalBuildingCreateOrUpdate.value.realEstateAssetIds?.includes(
              realEstateAsset.id
            )
        );
        if (realEstateAssets.length) {
          const isEqualRealEstateAssetAdresse = realEstateAssets.every(
            (realEstateAsset) =>
              realEstateAsset.address?.street ===
                realEstateAssets[0].address?.street &&
              realEstateAsset.address?.zip ===
                realEstateAssets[0].address?.zip &&
              realEstateAsset.address?.city ===
                realEstateAssets[0].address?.city
          );
          if (isEqualRealEstateAssetAdresse) {
            rentalBuildingCreateOrUpdate.value.address =
              realEstateAssets[0].address;
            isShowSelectRentalBuildingAdress.value = false;
          } else {
            isShowSelectRentalBuildingAdress.value = true;
          }

          const isEqualRealEstateAssetTaxDeduction = realEstateAssets.every(
            (realEstateAsset) =>
              realEstateAsset.taxDeduction === realEstateAssets[0].taxDeduction
          );
          if (isEqualRealEstateAssetTaxDeduction) {
            rentalBuildingCreateOrUpdate.value.taxDeduction =
              realEstateAssets[0].taxDeduction;
          }

          const isEqualRealEstateAssetNature = realEstateAssets.every(
            (realEstateAsset) =>
              realEstateAsset.nature === realEstateAssets[0].nature
          );
          if (isEqualRealEstateAssetNature) {
            rentalBuildingCreateOrUpdate.value.nature =
              realEstateAssets[0].nature;
            isShowSelectRentalBuildingNature.value = false;
          } else {
            const isImmeubleRealEstateAssetNature = realEstateAssets.every(
              (realEstateAsset) =>
                realEstateAsset.nature === IR2072NatureA.IMMEUBLE_URBAIN ||
                realEstateAsset.nature === IR2072NatureA.IMMEUBLE_RURAL ||
                realEstateAsset.nature === IR2072NatureA.IMMEUBLE_DE_RAPPORT
            );
            if (isImmeubleRealEstateAssetNature) {
              isShowSelectRentalBuildingNature.value = true;
            }
          }

          rentalBuildingCreateOrUpdate.value.premises =
            realEstateAssetsStore.rentalUnits.filter((rentalUnit) => {
              const realEstateAsset = realEstateAssets.find(
                (realEstateAsset) =>
                  realEstateAsset.id === rentalUnit.realEstateAssetId &&
                  rentalUnit.rentalState !== RentalState.NOT_RENTAL
              );
              if (realEstateAsset) {
                return true;
              }
            }).length;

          rentalBuildingCreateOrUpdate.value.newAcquisition =
            realEstateAssets.every((realEstateAsset) =>
              getMoment(realEstateAsset.boughtAt).isBetween(
                accountingPeriodsStore.currentAccountingPeriod?.startAt,
                accountingPeriodsStore.currentAccountingPeriod?.endAt,
                "day",
                "[]"
              )
            );
        }
      },
      { deep: true }
    );

    const formatNature = () => {
      const iR2072NatureA = Object.entries(IR2072NatureA).find(
        ([, value]) => value === rentalBuildingCreateOrUpdate.value.nature
      );
      if (iR2072NatureA) {
        return (
          iR2072NatureA[0].toLowerCase().charAt(0).toUpperCase() +
          iR2072NatureA[0].toLowerCase().slice(1).split("_").join(" ") +
          ` (${iR2072NatureA[1]})`
        );
      }
    };

    /**
     * Init
     */
    const init = async () => {
      if (isNew.value) {
        rentalBuildingCreateOrUpdate.value = cloneDeep(newRentalBuilding.value);
      } else {
        const rentalBuilding = cloneDeep(
          rentalBuildingsStore.getRentalBuilding(props.rentalBuildingId)
        );
        if (rentalBuilding) {
          rentalBuildingCreateOrUpdate.value = rentalBuilding;
        }
      }
    };

    const test = computed((value: string) => {
      return !!rentalBuildingCreateOrUpdate.value.realEstateAssetIds?.includes(
        value
      );
    });
    watch(
      () => [props, rentalBuildingsStore.rentalBuildings],
      () => init(),
      {
        deep: true,
      }
    );

    onBeforeMount(async () => await init());

    /**
     * Event
     */
    const updateIsEditing = (value: boolean) => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "update",
          subject("RentalBuilding", { productId: productsStore.currentId })
        );
        context.emit("update:isEditing", value);
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };

    /**
     * Action
     */
    const validate = async () => {
      if ((context.refs.form as VForm).validate()) {
        isLoadingValidate.value = true;
        if (isNew.value) {
          await rentalBuildingsStore.createRentalBuilding({
            newRentalBuilding:
              rentalBuildingCreateOrUpdate.value as NewRentalBuilding,
          });
        } else {
          await rentalBuildingsStore.updateRentalBuilding(
            rentalBuildingCreateOrUpdate.value as RentalBuildingUpdate
          );
        }
        updateIsEditing(false);
        isLoadingValidate.value = false;
      }
    };

    const cancel = async () => {
      if (isNew.value) {
        rentalBuildingCreateOrUpdate.value = cloneDeep(newRentalBuilding.value);
      } else {
        await init();
      }
      updateIsEditing(false);
    };

    const remove = async () => {
      await rentalBuildingsStore.deleteRentalBuilding(props.rentalBuildingId);
    };

    return {
      isNew,
      isLoading,
      isLoadingValidate,
      rentalBuildingCreateOrUpdate,
      realEstateAssets,
      isShowSelectRentalBuildingAdress,
      realEstateAssetsAdress,
      selectedRealEstateAssetRules,
      isShowSelectRentalBuildingNature,
      realEstateAssetsNature,
      IR2072NatureA,
      IR2072Deductions,
      validate,
      cancel,
      remove,
      updateIsEditing,
      formatNature,
      test,
    };
  },
});
