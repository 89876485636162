








































import { defineComponent, computed } from "@vue/composition-api";
import {
  accountingPeriodsStore,
  bankAccountsStore,
  productsStore,
  coreStore,
  documentsStore,
  fixedAssetsStore,
  assembliesStore,
  partnersStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  rentalBuildingsStore,
  subscriptionsStore,
  taskActivityStore,
  transactionsStore,
  usersStore,
} from "@/store";
import { User } from "@edmp/api";
import { userAccountService } from "@/services";
import { PROFILE_NAVIGATION_ITEMS } from "./ProfileNavigation.const";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "Profile",
  setup(props, context) {
    function disconnect(): void {
      usersStore.setLoggedInUser({} as User);
      userAccountService.logout();
      coreStore.reset();
      subscriptionsStore.reset();
      usersStore.reset();
      bankAccountsStore.reset();
      productsStore.reset();
      accountingPeriodsStore.reset();
      realEstateAssetsStore.reset();
      rentalBuildingsStore.reset();
      rentalAgreementsStore.reset();
      partnersStore.reset();
      assembliesStore.reset();
      documentsStore.reset();
      taskActivityStore.reset();
      transactionsStore.reset();
      fixedAssetsStore.reset();
      context.root.$router.replace({ name: ROUTE_NAMES.SignIn });
    }

    return {
      disconnect,
      loggedInUser: computed(() => usersStore.loggedInUser),
      PROFILE_NAVIGATION_ITEMS,
    };
  },
});
