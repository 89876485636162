


































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
import {
  RealEstateAsset,
  RentalAgreement,
  NewRentalAgreement,
  TenantTypeEnum,
} from "@edmp/api";
import Modal from "@/components/atom/Modal.vue";
import {
  coreStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import RentalAgreementSettingsModal from "./RentalAgreementSettingsModal.vue";
import RentalAgreementServiceModal from "./RentalAgreementServiceModal.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "RentalAgreementModal",
  components: {
    Modal,
    RentalAgreementSettingsModal,
    RentalAgreementServiceModal,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
    selectedRentalAgreement: {
      type: Object as () => RentalAgreement,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    stepSelected: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    const rentalAgreement: Ref<Partial<RentalAgreement>> = ref({
      product: {
        id: props.productId,
        realEstateAsset: {
          id: props.realEstateAsset.id,
          rentalUnit: {
            id: "",
          },
        },
      },
      tenant: {
        type: "natural_person",
        representative: {
          address: {},
        },
        address: { ...props.realEstateAsset.address },
        email: "",
      },
      options: {
        rentCallActivated: false,
        rentReceiptActivated: false,
        reminderUnpaidRentActivated: false,
        rentCallHasToCopyActivity: false,
        rentCallNumberDayBeforeRent: undefined,
        rentPaymentDayOfTheMonth: undefined,
        rentReceiptHasToCopyActivity: false,
        reminderRentCallNumberDayAfter: undefined,
        unpaidRentCallHasToCopyActivity: false,
      },
      ...cloneDeep(props.selectedRentalAgreement),
    } as NewRentalAgreement);

    const step: Ref<number | undefined> = ref(props.stepSelected);
    const validateInProgress: Ref<boolean> = ref(false);
    const isEditable: Ref<boolean> = ref(props.editable);

    async function validate() {
      validateInProgress.value = true;

      // START: There must only be one rental agreement per rental unit so we automatically set it if not already set
      if (!rentalAgreement.value.product?.realEstateAsset?.rentalUnit?.id) {
        if (!rentalAgreement.value.product) {
          throw new Error("No rental agreement or product found");
        }

        const rentalUnit =
          realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
            props.realEstateAsset.id
          );
        const rentalUnitId = rentalUnit ? rentalUnit.id : null;

        if (!rentalUnitId) {
          throw new Error("No rental unit found for this real estate asset");
        }

        if (!rentalAgreement.value.product.realEstateAsset) {
          rentalAgreement.value.product.realEstateAsset = {
            id: props.realEstateAsset.id,
            rentalUnit: { id: rentalUnitId },
          };
        } else if (!rentalAgreement.value.product.realEstateAsset?.rentalUnit) {
          rentalAgreement.value.product.realEstateAsset.rentalUnit = {
            id: rentalUnitId,
          };
        } else if (
          !rentalAgreement.value.product.realEstateAsset?.rentalUnit?.id
        )
          rentalAgreement.value.product.realEstateAsset.rentalUnit.id =
            rentalUnitId;
      }
      //Set unwanted information to undefined
      if (
        rentalAgreement.value.tenant?.type === TenantTypeEnum.NATURAL_PERSON
      ) {
        rentalAgreement.value.tenant.denomination = undefined;
        rentalAgreement.value.tenant.numSIREN = undefined;
        rentalAgreement.value.tenant.numTVA = undefined;
      } else if (
        rentalAgreement.value.tenant?.type === TenantTypeEnum.COMPANY ||
        rentalAgreement.value.tenant?.type === TenantTypeEnum.ASSOCIATION
      ) {
        rentalAgreement.value.tenant.name = undefined;
        rentalAgreement.value.tenant.firstName = undefined;
      }
      // END
      let validForm = true;
      if (
        (rentalAgreement.value.options?.rentCallActivated ||
          rentalAgreement.value.options?.rentReceiptActivated ||
          rentalAgreement.value.options?.reminderUnpaidRentActivated) &&
        !rentalAgreement.value.tenant?.email
      ) {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.ERROR,
          message:
            "Pour activer les options de suivi du loyer, vous devez spécifier l'email du locataire",
        });
        validForm = false;
      }

      if (validForm) {
        try {
          if (rentalAgreement.value.id) {
            try {
              await rentalAgreementsStore.updateRentalAgreement(
                rentalAgreement.value as RentalAgreement
              );
              coreStore.displayFeedback({
                message:
                  "La mise à jour de votre locataire a bien été prise en compte",
              });
            } catch (err) {
              coreStore.displayFeedback({
                type: FeedbackTypeEnum.ERROR,
                message:
                  "Une erreur est survenue lors de la mise à jour de votre locataire",
              });
            }
          } else {
            try {
              await rentalAgreementsStore.createRentalAgreement(
                rentalAgreement.value as NewRentalAgreement
              );
              coreStore.displayFeedback({
                message:
                  "La création de votre locataire a bien été prise en compte",
              });
            } catch (err) {
              coreStore.displayFeedback({
                type: FeedbackTypeEnum.ERROR,
                message:
                  "Une erreur est survenue lors de la création de votre locataire",
              });
            }
          }
          context.emit("validate");
        } catch (error) {
          console.error(error);
        }
      }
      validateInProgress.value = false;
    }
    const changeRealEstateAsset = () => {
      if (rentalAgreement.value.product?.realEstateAsset?.id) {
        rentalAgreement.value.product.realEstateAsset.id =
          props.realEstateAsset.id;
      }
      if (rentalAgreement.value.tenant?.address) {
        rentalAgreement.value.tenant.address = {
          ...props.realEstateAsset.address,
        };
      }
    };
    watch(
      () => props.realEstateAsset,
      () => changeRealEstateAsset(),
      { deep: true }
    );
    return {
      step,
      rentalAgreement,
      validate,
      validateInProgress,
      isEditable,
    };
  },
});
