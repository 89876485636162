

















































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";

import Title from "../title/Title.vue";
import DialogRight from "@/components/core/DialogRight.vue";
import RentalAgreementsFilterModal from "./RentalAgreementsFilter.vue";
import RentalAgreementCard from "./RentalAgreementCard.vue";
import RentalAgreementWithoutRealEstateAssetModal from "./RentalAgreementWithoutRealEstateAssetModal.vue";
import {
  accountingPeriodsStore,
  productsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  subscriptionsStore,
} from "@/store";
import {
  getMoment,
  RealEstateAsset,
  RentalAgreement,
  RentalAgreementsFilter,
} from "@edmp/api";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "RentalAgreements",
  components: {
    DialogRight,
    Title,
    RentalAgreementsFilterModal,
    RentalAgreementCard,
    RentalAgreementWithoutRealEstateAssetModal,
  },

  setup(_, context) {
    const displayRentalAgreementModalWithoutRealEstateAsset: Ref<
      Boolean | undefined
    > = ref(false);
    // A boolean value that is set to true.
    const selectedRealEstateAsset: Ref<RealEstateAsset | undefined> = ref();
    //const isOpenFilterRentalAgreements: Ref<boolean> = ref(false);
    // A reference to an array of numbers.
    const selectedRentalAgreement: Ref<RentalAgreement | undefined> = ref();
    const selectedRentalAgreementRealEstateAsset: Ref<
      RealEstateAsset | undefined
    > = ref();
    const displayRentalAgreementModal: Ref<boolean | undefined> = ref();
    const rentalAgreementsFilter: Ref<RentalAgreementsFilter | undefined> = ref(
      { rentalAgreementActive: undefined, realEstateAssetId: undefined }
    );
    const rentalAgreementsFilterByRealEstateAsset: Ref<Boolean | undefined> =
      ref(false);
    const rentalAgreementsByRealEstateAsset: Ref<
      RentalAgreement[][] | undefined
    > = ref([]);
    const selectFilter = [
      { name: "Tout afficher", value: 0 },
      {
        name: "Locataires actifs",
        value: 1,
      },
      {
        name: "Locataires expirés",
        value: 2,
      },
    ];

    const selectDisplay = [
      { name: "Affichage par défaut", value: 0 },
      {
        name: "Affichage par bien",
        value: 1,
      },
    ];

    const filterSelected: Ref<{ name: string; value: Number } | undefined> =
      ref({ name: "Filtres", value: 0 });
    const displaySelected: Ref<{ name: string; value: Number } | undefined> =
      ref({ name: "Options d'affichage", value: 0 });

    const productId = computed(() => {
      return productsStore.currentId;
    });
    const rentalAgreementsFiltered = computed(() => {
      return rentalAgreementsStore.rentalAgreementsFiltered;
    });
    const realEstateAssets = computed(() => {
      return realEstateAssetsStore.realEstateAssets;
    });
    const getRealEstateAssetFromTenant = (rentalAgreement: RentalAgreement) => {
      if (rentalAgreement.product.realEstateAsset?.id)
        return realEstateAssetsStore.getRealEstateAsset(
          rentalAgreement.product.realEstateAsset?.id
        );
      else return "";
    };

    /* return 0 si actif, 1 si contrat expiré, 2 si contrat pas encore actif*/
    const getStatusOfTenant = (rentalAgreement: RentalAgreement) => {
      const dateOfTheDay = getMoment();
      if (
        rentalAgreement.endAt &&
        getMoment(rentalAgreement.endAt).isBefore(dateOfTheDay)
      ) {
        return 1;
      }
      if (
        rentalAgreement.startAt &&
        getMoment(rentalAgreement.startAt).isAfter(dateOfTheDay)
      ) {
        return 2;
      }
      return 0;
    };
    function openDetails(ra: RentalAgreement) {
      selectedRentalAgreement.value = ra;
      if (ra.product.realEstateAsset?.id) {
        selectedRentalAgreementRealEstateAsset.value =
          realEstateAssetsStore.getRealEstateAsset(
            ra.product.realEstateAsset?.id
          );

        displayRentalAgreementModal.value = true;
      }
    }
    const closeDetails = () => {
      displayRentalAgreementModal.value = false;
      selectedRentalAgreement.value = undefined;
    };
    const changeFilterSelected = (item: { name: string; value: Number }) => {
      if (filterSelected.value !== item) filterSelected.value = item;
    };

    const changeDisplaySelected = (item: { name: string; value: Number }) => {
      if (displaySelected.value !== item) displaySelected.value = item;
    };
    const majDisplay = () => {
      if (displaySelected.value?.value === 0) {
        rentalAgreementsFilterByRealEstateAsset.value = false;
      } else if (displaySelected.value?.value === 1) {
        rentalAgreementsFilterByRealEstateAsset.value = true;
      }
    };
    // Init
    const init = async () => {
      if (subscriptionsStore.getCurrentSubscription?.plan.type === "Solo") {
        context.root.$router.push({ name: ROUTE_NAMES.Dashboard });
      } else {
        if (filterSelected.value?.value === 0) {
          Object.assign(rentalAgreementsFilter.value, {
            rentalAgreementActive: undefined,
          });
        } else if (filterSelected.value?.value === 1) {
          Object.assign(rentalAgreementsFilter.value, {
            rentalAgreementActive: true,
          });
        } else if (filterSelected.value?.value === 2) {
          Object.assign(rentalAgreementsFilter.value, {
            rentalAgreementActive: false,
          });
        }
        await rentalAgreementsStore.fetchRentalAgreementsWithFilter({
          productId: productsStore.currentId,
          realEstateAssetId: rentalAgreementsFilter.value?.realEstateAssetId,
          rentalAgreementActive:
            rentalAgreementsFilter.value?.rentalAgreementActive,
        });
        rentalAgreementsByRealEstateAsset.value = [];
        const tabTabRentalAgreements: RentalAgreement[][] = [];
        const realEstateAssets = realEstateAssetsStore.realEstateAssets;
        const rentalAgreementsFiltered =
          rentalAgreementsStore.rentalAgreementsFiltered;
        realEstateAssets.forEach((rea) => {
          const tabRentalAgreements: RentalAgreement[] = [];
          rentalAgreementsFiltered.forEach((raf) => {
            if (
              raf.product.realEstateAsset &&
              rea.id === raf.product.realEstateAsset.id
            ) {
              tabRentalAgreements.push(raf);
            }
          });
          tabTabRentalAgreements.push(tabRentalAgreements);
        });
        rentalAgreementsByRealEstateAsset.value = tabTabRentalAgreements;
      }
    };

    watch(
      () => [
        subscriptionsStore.getCurrentSubscription,
        rentalAgreementsStore.rentalAgreements,
        filterSelected.value,
      ],
      async () => await init(),
      {
        deep: true,
      }
    );

    watch(
      () => displaySelected,
      () => majDisplay(),
      { deep: true }
    );

    onMounted(async () => await init());
    return {
      changeDisplaySelected,
      displaySelected,
      selectDisplay,
      changeFilterSelected,
      selectFilter,
      filterSelected,
      displayRentalAgreementModalWithoutRealEstateAsset,
      productId,
      selectedRealEstateAsset,
      init,
      rentalAgreementsFilterByRealEstateAsset,
      rentalAgreementsByRealEstateAsset,
      selectedRentalAgreementRealEstateAsset,
      selectedRentalAgreement,
      displayRentalAgreementModal,
      openDetails,
      closeDetails,
      rentalAgreementsFilter,
      rentalAgreementsFiltered,
      //isOpenFilterRentalAgreements,
      realEstateAssets,
      getRealEstateAssetFromTenant,
      getStatusOfTenant,
      isLMNP: computed(() => accountingPeriodsStore.isLMNP),
    };
  },
});
