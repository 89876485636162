











































import RegisterActivitySCI from "./RegisterActivitySCI.vue";
import RegisterActivityLMNP from "./RegisterActivityLMNP.vue";
import SCISearch from "@/components/sign/signup/steps/SCISearch.vue";
import { defineComponent, PropType } from "@vue/composition-api";
import { ProductsModel } from "@edmp/api";

export default defineComponent({
  name: "RegisterActivity",
  components: { RegisterActivitySCI, RegisterActivityLMNP, SCISearch },
  props: {
    step: {
      type: String,
      required: true,
    },
    activityType: {
      type: String as PropType<"SCI" | "LMNP">,
      required: true,
    },
    type: {
      type: String as PropType<ProductsModel.Tags>,
      require: false,
    },
  },
  setup() {
    //
  },
});
