






















































































































































































































import DatePicker from "@/components/atom/DatePicker.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import { VForm } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  productsStore,
  coreStore,
  fixedAssetsStore,
  realEstateAssetsStore,
} from "@/store";
import { PROPERTY_STATUS, PROPERTY_TYPES, TAX_DEDUCTIONS } from "@/utils";
import {
  FixedAsset,
  FixedAssetType,
  FixedAssetsService,
  getFixedAssetTypeTexts,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { format } from "date-fns";

export default defineComponent({
  name: "FixedAssetForm",
  components: {
    YesNoSwitch,
    DatePicker,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    fixedAssetId: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    smallDisplay: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const fixedAssetTypeText: {
      text: string;
      value: FixedAssetType;
    }[] = getFixedAssetTypeTexts();
    const defaultFixedAsset: FixedAssetsService.CreateIn = {
      name: "",
      boughtAt: "",
      commissioningAt: "",
      realEstateAssetIds: [],
      boughtPrice: 0,
      type: "roof",
      productId: productsStore.currentId,
    };

    const existingFixedAsset: Ref<FixedAsset | undefined> = ref();

    const displayedFixedAsset: Ref<FixedAsset> = ref(
      defaultFixedAsset as FixedAsset
    );

    //In case of "à renseigner", complete realEstateAsset with defaultRealEstate
    const validateInProgress: Ref<boolean> = ref(false);
    const today = ref(format(new Date(), "yyyy-MM-dd"));

    const onEditClick = () => {
      context.emit("setEditing", true);
    };
    const realEstateAssets = computed(() =>
      realEstateAssetsStore.realEstateAssets.map((realEstateAsset) => {
        const temp = {
          text: `${realEstateAsset.name}`,
          value: realEstateAsset.id,
          disabled: false,
          alert: false,
        };

        return temp;
      })
    );
    const gotoRealEstateAssets = () => {
      router.push({
        name: ROUTE_NAMES.Activities,
        query: { focus: "assets-section" },
      });
    };
    async function validate() {
      validateInProgress.value = true;
      if ((context.refs.form as VForm).validate()) {
        try {
          if (props.fixedAssetId === null || props.fixedAssetId === "new") {
            displayedFixedAsset.value = await fixedAssetsStore.createFixedAsset(
              {
                ...displayedFixedAsset.value,
              }
            );
            context.emit("setEditing", false);
            context.emit("validate", displayedFixedAsset.value.id);
            coreStore.displayFeedback({
              message:
                "La création de votre immobilisation a bien été prise en compte",
            });
          } else {
            await fixedAssetsStore.updateFixedAsset(displayedFixedAsset.value);

            coreStore.displayFeedback({
              message:
                "La mise à jour de votre immobilisation a bien été prise en compte",
            });
          }
          context.emit("setEditing", false);
        } catch (error) {
          console.error(error);
        }
      }
      validateInProgress.value = false;
    }

    const modalTitle = computed(
      () => `${props.fixedAssetId ? "Editer" : "Ajouter"} une immobilisation`
    );

    const init = async () => {
      await fixedAssetsStore.fetchFixedAssets(productsStore.currentId);
      existingFixedAsset.value = await fixedAssetsStore.getFixedAsset(
        props.fixedAssetId
      );
      if (existingFixedAsset.value) {
        displayedFixedAsset.value = structuredClone(existingFixedAsset.value);
      }
    };

    const cancelEdition = async () => {
      if (existingFixedAsset.value) {
        displayedFixedAsset.value = structuredClone(existingFixedAsset.value);
      }
      context.emit("setEditing", false);
    };

    watch(
      [props],
      async () => {
        await init();
      },
      { deep: true }
    );

    onBeforeMount(async () => await init());

    return {
      realEstateAssets,
      TAX_DEDUCTIONS,
      PROPERTY_TYPES,
      PROPERTY_STATUS,
      existingFixedAsset,
      displayedFixedAsset,
      onEditClick,
      cancelEdition,
      validateInProgress,
      validate,
      today,
      modalTitle,
      fixedAssetTypeText,
      gotoRealEstateAssets,
    };
  },
});
