
























































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AnomalyCode,
  AnomalyOptions,
  RealEstateAsset,
  RentalAgreement,
  TenantTypeEnum,
  TypeReference,
} from "@edmp/api";
import { useEmail } from "@/composables";
import Moment from "moment";
import { format } from "date-fns";
import { set } from "lodash";
import { CustomLabelButton } from "@/components/atom/button";
import { VForm } from "@/models";

import { YesNoSwitch } from "@/components/atom/switch";
import Anomalies from "../anomalies/Anomalies.vue";
import DatePicker from "@/components/atom/DatePicker.vue";
import {
  accountingPeriodsStore,
  rentalAgreementsStore,
  subscriptionsStore,
} from "@/store";
import eventBus from "@/events/eventBus";

export default defineComponent({
  name: "RentalAgreementModal",
  components: {
    CustomLabelButton,
    YesNoSwitch,
    Anomalies,
    DatePicker,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
    selectedRentalAgreement: {
      type: Object as () => RentalAgreement,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    rentalAgreement: {
      type: Object as () => Partial<RentalAgreement>,
      required: true,
    },
    validateInProgress: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    const today = ref(format(new Date(), "yyyy-MM-dd"));

    const legalRepresentative: Ref<boolean> = ref(
      !!(
        props?.selectedRentalAgreement?.tenant?.representative?.name ||
        props?.selectedRentalAgreement?.tenant?.representative?.firstName ||
        props?.selectedRentalAgreement?.tenant?.representative?.email ||
        props?.selectedRentalAgreement?.tenant?.representative?.phone
      )
    );
    watch(legalRepresentative, (newVal) => {
      if (!newVal) {
        // Clean tenant
        set(props.rentalAgreement, "tenant.representative", { address: {} });
      } else {
        // Init default representative & address to bind v-model
        set(props.rentalAgreement, "tenant.representative", {
          address: { street: "", city: "", zip: "" },
        });
      }
    });

    const legalFormList: {
      text: string;
      value: string;
      disabled?: boolean;
    }[] = [
      { text: "Personne physique", value: "natural_person" },
      { text: "Association", value: "association" },
      { text: "Société", value: "company" },
    ];
    const { validEmailRule } = useEmail();

    async function validate() {
      if ((context.refs.form as VForm).validate()) {
        context.emit("validate");
      }
    }

    async function next() {
      if (props.editable && (context.refs.form as VForm).validate()) {
        context.emit("next");
      } else if (!props.editable) {
        context.emit("next");
      }
    }

    const computedEndDateMin = computed(() =>
      props.rentalAgreement.startAt
        ? Moment(props.rentalAgreement.startAt).format("yyyy-MM-DD")
        : ""
    );

    const pickerEndMenuError = computed(() =>
      props.rentalAgreement.startAt
        ? ""
        : "Renseignez d'abord la date de début du contrat"
    );

    const ruleEndDate = (): boolean =>
      Moment(props.rentalAgreement.startAt).isAfter(
        props.rentalAgreement.endAt
      );

    const tenantEmailLowerCase = computed({
      get: () => {
        if (props.rentalAgreement.tenant?.email) {
          return props.rentalAgreement.tenant.email.toLowerCase().trim();
        } else {
          return "";
        }
      },

      set: (value: string) => {
        if (!props.rentalAgreement.tenant) {
          props.rentalAgreement.tenant = {};
        }
        props.rentalAgreement.tenant.email = value.toLowerCase().trim();
      },
    });

    const representantEmailLowerCase = computed({
      get: () => {
        if (props.rentalAgreement.tenant?.representative?.email) {
          return props.rentalAgreement.tenant.representative.email
            .toLowerCase()
            .trim();
        } else {
          return "";
        }
      },

      set: (value: string) => {
        if (!props.rentalAgreement.tenant) {
          props.rentalAgreement.tenant = {};
        }
        if (!props.rentalAgreement.tenant.representative) {
          props.rentalAgreement.tenant.representative = {};
        }
        props.rentalAgreement.tenant.representative.email = value
          .toLowerCase()
          .trim();
      },
    });

    const anomalyOptions = computed(() => {
      const anomalyOptions: AnomalyOptions = {
        [AnomalyCode.referenceType]: {},
        [AnomalyCode.objectIdLink]: {},
      };
      if (props.rentalAgreement.id) {
        anomalyOptions[AnomalyCode.referenceType] = {
          referenceId: props.rentalAgreement.id,
        };
      }
      if (props.rentalAgreement.product?.realEstateAsset?.rentalUnit?.id) {
        anomalyOptions[AnomalyCode.objectIdLink] = {
          referenceId:
            props.rentalAgreement.product.realEstateAsset.rentalUnit.id,
        };
      }
      return anomalyOptions;
    });

    const displayDeleteModal = ref(false);
    function openDeleteModal() {
      displayDeleteModal.value = true;
    }
    function closeDeleteModal() {
      displayDeleteModal.value = false;
    }
    function deleteRentalAgreement(rentalAgreementId: string) {
      rentalAgreementsStore.deleteRentalAgreement(rentalAgreementId);
      closeDeleteModal();
      eventBus.$emit("closeRentalAgreementModal");
    }

    return {
      pickerEndMenuError,
      legalFormList,
      legalRepresentative,
      tenantEmailLowerCase,
      representantEmailLowerCase,
      computedEndDateMin,
      ruleEndDate,
      validEmailRule,
      validate,
      next,
      today,
      anomalyOptions,
      TypeReference,
      TenantTypeEnum,
      displayDeleteModal,
      openDeleteModal,
      closeDeleteModal,
      deleteRentalAgreement,
      subscriptionsStore,
      isLMNP: computed(() => accountingPeriodsStore.isLMNP),
    };
  },
});
