var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rentalAgreement)?_c('v-card',{staticClass:"vCardStyle d-flex flex-column container",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex flex-column align-center mb-5 mt-5"},[_c('v-avatar',{attrs:{"color":"primary"}},[(
          _vm.rentalAgreement.tenant.firstName &&
          _vm.rentalAgreement.tenant.name &&
          _vm.rentalAgreement.tenant.type === _vm.TenantTypeEnum.NATURAL_PERSON
        )?_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.rentalAgreement.tenant.firstName[0])+" "+_vm._s(_vm.rentalAgreement.tenant.name[0])+" ")]):_vm._e(),(
          _vm.rentalAgreement.tenant.denomination &&
          _vm.rentalAgreement.tenant.type !== _vm.TenantTypeEnum.NATURAL_PERSON
        )?_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.rentalAgreement.tenant.denomination[0])+" "+_vm._s(_vm.rentalAgreement.tenant.denomination[1])+" ")]):_vm._e()])],1),(_vm.rentalAgreement.tenant.type === _vm.TenantTypeEnum.NATURAL_PERSON)?_c('div',{staticClass:"d-flex flex-column align-center mb-5"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(
            _vm.rentalAgreement &&
            _vm.rentalAgreement.tenant.firstName &&
            _vm.rentalAgreement.tenant.name
          )?_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold textOverflow"},'span',attrs,false),on),[_c('h3',[_vm._v(" "+_vm._s(_vm.rentalAgreement.tenant.firstName)+" "+_vm._s(_vm.rentalAgreement.tenant.name)+" ")])]):_vm._e()]}}],null,false,2450596625)},[_c('span',[_vm._v(" "+_vm._s(_vm.rentalAgreement.tenant.firstName)+" "+_vm._s(_vm.rentalAgreement.tenant.name)+" ")])])],1):_vm._e(),(_vm.rentalAgreement.tenant.type !== _vm.TenantTypeEnum.NATURAL_PERSON)?_c('div',{staticClass:"d-flex flex-column align-center mb-5"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.rentalAgreement && _vm.rentalAgreement.tenant.denomination)?_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold textOverflow"},'span',attrs,false),on),[_c('h3',[_vm._v(" "+_vm._s(_vm.rentalAgreement.tenant.denomination)+" ")])]):_vm._e()]}}],null,false,2999534940)},[_c('span',[_vm._v(" "+_vm._s(_vm.rentalAgreement.tenant.denomination)+" ")])])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-center"},[(
        _vm.rentalAgreement.tenant.type !== _vm.TenantTypeEnum.NATURAL_PERSON &&
        _vm.rentalAgreement.tenant.numSIREN
      )?_c('div',[_vm._v(" SIREN : "+_vm._s(_vm.rentalAgreement.tenant.numSIREN)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.rentalAgreement.tenant.email)?_c('div',[_vm._v(" "+_vm._s(_vm.rentalAgreement.tenant.email)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.rentalAgreement.tenant.phone)?_c('div',[_vm._v(" "+_vm._s(_vm.rentalAgreement.tenant.phone)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-center mb-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.rentalAgreement)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" Bien : "+_vm._s(_vm.getRealEstateAssetNameFromTenant(_vm.rentalAgreement))+" ")]):_vm._e()]}}],null,false,751861664)},[_c('span',[_vm._v(" "+_vm._s(_vm.getRealEstateAssetNameFromTenant(_vm.rentalAgreement))+" ")])]),_c('div')],1),_c('div',{staticClass:"d-flex flex-column align-center"},[(!_vm.rentalAgreement.tenant.email)?_c('div',[_c('br')]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-center"},[(!_vm.rentalAgreement.tenant.phone)?_c('div',[_c('br')]):_vm._e(),(
        _vm.rentalAgreement.tenant.type === _vm.TenantTypeEnum.NATURAL_PERSON ||
        !_vm.rentalAgreement.tenant.numSIREN
      )?_c('div',[_c('br')]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-center mb-3"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openDetails(_vm.rentalAgreement, 0)}}},[_c('span',[_c('u',[_vm._v("Voir les détails")]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-eye-outline")])],1)])],1),(!_vm.isLMNP)?_c('div',[_c('v-divider',{staticClass:"mb-3"}),_c('div',{staticClass:"d-flex flex-column align-center mb-5"},[_c('span',[_c('b',[_vm._v("Service gestion locative:")])])]),(_vm.getStatusOfTenant(_vm.rentalAgreement) === 2)?_c('div',{staticClass:"d-flex flex-column align-center mb-5"},[_c('div',[_vm._v("contrat pas encore actif")])]):_vm._e(),(_vm.getStatusOfTenant(_vm.rentalAgreement) === 1)?_c('div',{staticClass:"d-flex flex-column align-center mb-5"},[_c('span',{staticClass:"ml-6 mr-6 text-center"},[_vm._v("Contrat de location expiré depuis le"),_c('br'),_vm._v(" "+_vm._s(_vm.getMoment(_vm.rentalAgreement.endAt).format("LL")))])]):_vm._e(),_c('div',{staticClass:"serviceDisplay"},[_c('div',{staticClass:"breakpoint d-flex flex-column align-end"},[(_vm.getStatusOfTenant(_vm.rentalAgreement) === 0)?_c('div',[_c('span',[_c('span',[_vm._v("Appel de loyer: ")]),(
                _vm.subscription &&
                (_vm.subscription.plan.type === _vm.PlanType.Premium ||
                  _vm.subscription.plan.type === _vm.PlanType.Optimum) &&
                _vm.rentalAgreement.options &&
                _vm.rentalAgreement.options.rentCallActivated &&
                _vm.bankCorrect
              )?_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]):_vm._e(),(_vm.getStatusOfTenant(_vm.rentalAgreement) === 0)?_c('div',[_c('span',[_c('span',[_vm._v("Quittance de loyer: ")]),(
                _vm.subscription &&
                (_vm.subscription.plan.type === _vm.PlanType.Premium ||
                  _vm.subscription.plan.type === _vm.PlanType.Optimum) &&
                _vm.rentalAgreement.options &&
                _vm.rentalAgreement.options.rentReceiptActivated &&
                _vm.bankCorrect
              )?_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]):_vm._e(),(_vm.getStatusOfTenant(_vm.rentalAgreement) === 0)?_c('div',{staticClass:"mb-5"},[_c('span',[_c('span',[_vm._v("Relance impayé: ")]),(
                _vm.subscription &&
                (_vm.subscription.plan.type === _vm.PlanType.Premium ||
                  _vm.subscription.plan.type === _vm.PlanType.Optimum) &&
                _vm.rentalAgreement.options &&
                _vm.rentalAgreement.options.reminderUnpaidRentActivated &&
                _vm.bankCorrect
              )?_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]):_vm._e()])]),(_vm.getStatusOfTenant(_vm.rentalAgreement) === 0)?_c('div',{staticClass:"serviceButton d-flex flex-column align-center"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openDetails(_vm.rentalAgreement, 1)}}},[_c('span',[_c('u',[_vm._v("Gérer les services")]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(" mdi-eye-outline")])],1)])],1):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":_vm.$vuetify.breakpoint.lgAndUp ? '55%' : '80%',"persistent":""},model:{value:(_vm.displayRentalAgreementModal),callback:function ($$v) {_vm.displayRentalAgreementModal=$$v},expression:"displayRentalAgreementModal"}},[(_vm.displayRentalAgreementModal && _vm.selectedRentalAgreement)?_c('RentalAgreementModal',{attrs:{"productId":_vm.selectedRentalAgreement.product.id,"realEstateAsset":_vm.selectedRentalAgreementRealEstateAsset,"rentalAgreementId":_vm.selectedRentalAgreement.id,"selectedRentalAgreement":_vm.selectedRentalAgreement,"editable":!_vm.selectedRentalAgreement,"stepSelected":_vm.stepSelected},on:{"validate":function($event){_vm.displayRentalAgreementModal = false;
        _vm.selectedRentalAgreement = undefined;
        _vm.selectedRentalAgreementRealEstateAsset = undefined;},"close":_vm.closeDetails}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }