import {
  AnomalyCode,
  AnomalyError,
  AnomalyErrorResult,
  categorizationRules,
  CheckNewAnomaliesParams,
  CheckNewAnomaliesType,
  Direction,
  JournalComposedEntry,
  JournalEntryLine,
  LedgerAccountEnum,
  TypeReference,
} from "..";
import { anomaliesHelpers } from "./anomaliesHelper.lib";

export type CheckLoanAnomaliesParams = Omit<
  CheckNewAnomaliesParams<CheckNewAnomaliesType.transaction>,
  "checkNewAnomaliesType" | "params"
> & {
  operation: JournalComposedEntry;
  line: JournalEntryLine;
};

export const checkLoanAnomalies = ({
  operation,
  line,
  data,
  options,
}: CheckLoanAnomaliesParams): AnomalyErrorResult[] => {
  const { getRealEstateLoan, hasOptionAnomalyParamsKey, getOptionAnomalyParamsKey } = anomaliesHelpers({
    data,
    options,
  });
  const anomaliesErrorsLine: AnomalyErrorResult[] = [];

  /* Add anomaly if the line account not respect rules. */
  const { loanCapitalBorrowedRules } = categorizationRules;

  if (line.account === LedgerAccountEnum.N616600) {
    const realEstateLoanRef = line.refs?.find((ref) => ref.type === TypeReference.realEstateLoan);
    if (hasOptionAnomalyParamsKey(AnomalyCode.loan, "insuranceEnable")) {
      if (realEstateLoanRef && !getOptionAnomalyParamsKey(AnomalyCode.loan, "insuranceEnable")) {
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.loanInsuranceEnableRequired,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: realEstateLoanRef.type,
          toReferenceId: realEstateLoanRef.referredId,
        });
      }
    } else {
      if (
        realEstateLoanRef &&
        !loanCapitalBorrowedRules.isEnableInsuranceForRealEstateLoan(realEstateLoanRef.referredId, getRealEstateLoan)
      ) {
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.loanInsuranceEnableRequired,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: realEstateLoanRef.type,
          toReferenceId: realEstateLoanRef.referredId,
        });
      }
    }
  }

  if (line.account === LedgerAccountEnum.N164100) {
    /**
     * * Add anomaly if Loan Interest 661110 is not present
     * Checking if the line 661110 exists in the journal entry.
     */
    const line661110Exist = !!operation?.journalEntry.lines?.filter(
      (line) => line.account === LedgerAccountEnum.N661110
    ).length;
    if (!line661110Exist) {
      anomaliesErrorsLine.push({
        anomalyError: AnomalyError.loanLedgerAccount661110Required,
        fromReferenceType: "transaction",
        fromReferenceId: operation.transactionId,
      });
    }

    const realEstateLoanRef = line.refs?.find((ref) => ref.type === TypeReference.realEstateLoan);
    if (realEstateLoanRef) {
      /**
       * * Add anomaly if loan insurance is enable but no category Loan Insurance 616600 is present
       * Checking if the line 616600 exists in the journal entry.
       */
      const line616600Exist = !!operation?.journalEntry.lines?.filter(
        (line) => line.account === LedgerAccountEnum.N616600
      ).length;
      if (hasOptionAnomalyParamsKey(AnomalyCode.loan, "insuranceEnable")) {
        if (getOptionAnomalyParamsKey(AnomalyCode.loan, "insuranceEnable") && !line616600Exist) {
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.loanLedgerAccount616600Required,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: realEstateLoanRef.type,
            toReferenceId: realEstateLoanRef.referredId,
          });
        }
      } else if (
        realEstateLoanRef &&
        loanCapitalBorrowedRules.isEnableInsuranceForRealEstateLoan(realEstateLoanRef.referredId, getRealEstateLoan) &&
        !line616600Exist
      ) {
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.loanLedgerAccount616600Required,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: realEstateLoanRef.type,
          toReferenceId: realEstateLoanRef.referredId,
        });
      }

      const realEstateLoan = getRealEstateLoan(realEstateLoanRef.referredId);
      if (realEstateLoan) {
        if (hasOptionAnomalyParamsKey(AnomalyCode.loan, "insuranceEnable")) {
          // Because is use for calculate loan amount in live on Vue
          realEstateLoan.insuranceIncludedInLoan = getOptionAnomalyParamsKey(AnomalyCode.loan, "insuranceEnable");
        }

        /**
         * * Add anomaly if sum of Loan Capital Borrowed 616600, Loan Interest 661110 and Loan Insurance 616600 is not equal to repayment deadline amount
         * Checking if the transaction amount is equal to the loan repayment deadline amount.
         */
        const transactionAmount =
          operation.journalEntry.lines?.reduce((previousAmount, line) => {
            if (
              line.account === LedgerAccountEnum.N164100 ||
              line.account === LedgerAccountEnum.N661110 ||
              line.account === LedgerAccountEnum.N616600
            ) {
              let result = 0;
              if (line.direction === Direction.debit) {
                // because if line.direction equal 'debit', in front line.mount is négatif (-1.00) and in back line.amount is positif (1.00)
                result = previousAmount - Math.abs(line.amount);
              } else {
                result = previousAmount + Math.abs(line.amount);
              }
              return result;
            }
            return previousAmount;
          }, 0) || 0;
        if (
          !categorizationRules.loanCapitalBorrowedRules.isEqualLoanRepaymentDeadlineAmount(
            realEstateLoan,
            transactionAmount
          )
        ) {
          /**
           * ! Disable anomalies on amount check
           */
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.loanRepaymentDeadlineAmountEqualTransactionAmountRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: realEstateLoanRef.type,
            toReferenceId: realEstateLoanRef.referredId,
          });
        }

        /**
         * * Add anomaly if transaction date is not equal to repayment deadline date
         * Checking if the loan repayment deadline date is equal to the transaction date.
         */
        if (
          !(realEstateLoan.amortisationLines
            ? !!categorizationRules.loanCapitalBorrowedRules.isEqualLoanRepaymentDeadlineDate(
                realEstateLoan.amortisationLines,
                operation.journalEntry.date
              )
            : false)
        ) {
          /**
           * ! Disable anomalies on date check
           */
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.loanRepaymentDeadlineDateEqualTransactionDateRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: realEstateLoanRef.type,
            toReferenceId: realEstateLoanRef.referredId,
          });
        }
      }
    }
  }

  return anomaliesErrorsLine;
};
