

















































































































import { HelpingMessage } from "@/components/atom/helping";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import TaskActivityGroups from "@/components/core/taskActivity/TaskActivityGroups.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { TaskActivityLocal, VConfirmDialog } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingCarryForwardsStore,
  accountingPeriodsStore,
  assembliesStore,
  coreStore,
  documentsStore,
  partnersStore,
  productsStore,
  realEstateAssetsStore,
  rentalBuildingsStore,
  taskActivityStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { downloadFileFromUrl } from "@/utils";
import {
  AccountingCarryForwardLineType,
  AccountingPeriod,
  getReferredIdByTypeWithReferences,
  OmitField,
  TaskActivityService,
  TaskActivityTypeReference,
  TaskCode,
  TaxRegime,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";

import EventsYearEndModal from "./EventsYearEndModal.vue";
import TitleNew from "../../title/TitleNew.vue";

export default defineComponent({
  name: "EventsYearEnd",
  components: {
    AccountingPeriodChoice,
    TaskActivityGroups,
    HelpingMessage,
    Confirm,
    EventsYearEndModal,
    TitleNew,
  },
  setup(props, context) {
    const taskActivityGroups = computed(() => {
      return taskActivityStore.closureTaskGroup;
    });

    const definitionsYearsEndPeriod: Ref<AccountingPeriod> = ref(
      {} as AccountingPeriod
    );

    async function getDefinitionsYearsEndPeriod(): Promise<void> {
      definitionsYearsEndPeriod.value =
        (accountingPeriodsStore.currentAccountingPeriod ||
          {}) as AccountingPeriod;
    }

    const closeModal = () => {
      taskActivityStore.closeYearEndModal();
    };

    const resolveTaskActivity = async (
      taskActivity: TaskActivityLocal,
      data?: OmitField<Required<TaskActivityService.ValidateIn>, "id">
    ) => {
      const assemblyId = getReferredIdByTypeWithReferences(
        taskActivity.references,
        TaskActivityTypeReference.assembly
      );
      if (
        taskActivity.code === TaskCode.TransactionsCategorized &&
        transactionsStore.numberOfTransactionsToBeCategorized
      ) {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.ERROR,
          message: "Catégorisez vos transactions avant de valider la tâche",
        });
      } else if (
        taskActivity.code === TaskCode.SupportingDocuments &&
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
          TaxRegime.IR_2072
      ) {
        await taskActivityStore.validateTaskActivity({
          taskActivityLocal: taskActivity,
        });
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.FEEDBACK,
          message:
            "2 nouveaux documents ont été générés par Ownily et sont disponibles dans l'espace Mes documents",
          timeout: 8000,
        });
      } else if (
        [
          TaskCode.BalanceSimple,
          TaskCode.ViewAndControlResultsAccount,
        ].includes(taskActivity.code)
      ) {
        await taskActivityStore.validateTaskActivity({
          taskActivityLocal: taskActivity,
        });
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.FEEDBACK,
          message:
            "1 nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents",
          timeout: 8000,
        });
      } else if (
        [
          TaskCode.FillCerfa2033,
          TaskCode.FillCerfa2031,
          TaskCode.FillCerfa2065,
          TaskCode.FillCerfa2072,
        ].includes(taskActivity.code)
      ) {
        if (
          taskActivity.code === TaskCode.FillCerfa2033 &&
          accountingPeriodsStore.isIS
        ) {
          await accountingCarryForwardsStore.fetchAccountingCarryForwards();
          const carryForwardRecovery =
            accountingCarryForwardsStore.getAccountingCarryForwardRecovery;
          if (
            carryForwardRecovery &&
            carryForwardRecovery.lines.some(
              (line) =>
                line.type === AccountingCarryForwardLineType.TOTAL_DEFICIT &&
                line.amount === -1
            )
          ) {
            router.push({
              name: ROUTE_NAMES.TaxDeclaration2033,
              query: {
                openAddDeferrableDeficit: "true",
              },
            });
          }
        }
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          "Cette action est définitive, aucune modification ne pourra être appliquée sur vos transactions. Êtes-vous sûr de vouloir valider ?"
        );

        if (openConfirm) {
          await taskActivityStore.validateTaskActivity({
            taskActivityLocal: taskActivity,
            data,
          });
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.FEEDBACK,
            message:
              "1 nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents",
            timeout: 8000,
          });
        }
      } else if (taskActivity.code === TaskCode.FillFec) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          "Cette action est définitive, aucune modification ne pourra être appliquée sur vos transactions. Êtes-vous sûr de vouloir valider ?"
        );

        if (openConfirm) {
          await taskActivityStore.validateTaskActivity({
            taskActivityLocal: taskActivity,
          });
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.FEEDBACK,
            message:
              "1 nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents",
            timeout: 8000,
          });
          router.push({ name: ROUTE_NAMES.Documents });
        }
      } else if (taskActivity.code === TaskCode.SendPartnersResults) {
        await taskActivityStore.validateTaskActivity({
          taskActivityLocal: taskActivity,
        });
        const nbPartners = partnersStore.partners.length;
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.FEEDBACK,
          message: `${nbPartners} nouveaux documents ont été générés par Ownily et sont disponibles dans l'espace Mes documents`,
          timeout: 8000,
        });
      } else if (taskActivity.code === TaskCode.BuildingConfigured) {
        const rentalBuildings = await rentalBuildingsStore.fetchRentalBuildings(
          {
            accountingPeriodId: accountingPeriodsStore.currentId,
          }
        );
        const realEstateAssetsNumber = rentalBuildings.reduce(
          (previousValue, currentValue) => {
            return previousValue + currentValue.realEstateAssetIds.length;
          },
          0
        );
        if (
          realEstateAssetsNumber !==
          realEstateAssetsStore.realEstateAssets.length
        ) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: "Il reste des biens à affecter à vos immeubles.",
          });
        } else {
          await taskActivityStore.validateTaskActivity({
            taskActivityLocal: taskActivity,
          });
        }
      } else if (
        taskActivity.code ===
        TaskCode.AmortizationsResumptionPostAmortizationsUpdate
      ) {
        router.push({
          name: ROUTE_NAMES.Transactions,
          query: {
            openModalAmortization: "true",
          },
        });
      } else if (
        [TaskCode.InviteYearEnd, TaskCode.ReportYearEnd].includes(
          taskActivity.code
        ) &&
        assemblyId
      ) {
        const event = assembliesStore.getGeneralAssemblyEvent(assemblyId);
        if (!event) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "L'assemblée générale n'a pas été récupérée, veuillez contacter le support.",
          });
          return;
        }

        if (taskActivity.code === TaskCode.InviteYearEnd) {
          const partnersList = computed(() => partnersStore.partners);
          const generalAssemblyEvent = {
            ...event,
            invitation: partnersList.value.map((p) => p.id),
          };
          const assemblyUpdated = await assembliesStore.invite(
            generalAssemblyEvent
          );

          await documentsStore.fetchDocuments(productsStore.currentId);
          taskActivityStore.fetchTaskActivityGroups({});
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.FEEDBACK,
            message:
              "Un nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents ou dans la section document du détail de votre Assemblée Générale",
            timeout: 8000,
          });

          const document = assemblyUpdated.document;
          if (document?.href) {
            downloadFileFromUrl(document.href, document.name ?? "Convocations");
          }
        } else if (taskActivity.code === TaskCode.ReportYearEnd) {
          router.push({
            name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
            params: {
              productId: productsStore.currentId,
              id: assemblyId,
            },
            query: {
              openAgPVModal: "true",
            },
          });
        }
      } else if (taskActivity.code === TaskCode.TeletransmitMyTaxReturn) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          "Votre déclaration va être envoyée au centre des impôts, vous devrez attendre une réponse ou contacter le support pour effectuer une modification. Êtes-vous sûr de vouloir valider ?"
        );

        if (openConfirm) {
          await taskActivityStore.validateTaskActivity({
            taskActivityLocal: taskActivity,
          });
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.FEEDBACK,
            message: "Votre déclaration à été envoyé",
            timeout: 8000,
          });
        }
      } else {
        await taskActivityStore.validateTaskActivity({
          taskActivityLocal: taskActivity,
        });
      }
    };

    const init = async () => {
      await getDefinitionsYearsEndPeriod();
      await transactionsStore.updateCategoriesList();
      await rentalBuildingsStore.fetchRentalBuildings({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
      await taskActivityStore.fetchTaskActivityGroups({});
    };

    watch(
      () => accountingPeriodsStore.currentAccountingPeriod,
      () => init(),
      {
        deep: true,
      }
    );
    onBeforeMount(async () => await init());

    return {
      taskActivityGroups,
      resolveTaskActivity,
      getDefinitionsYearsEndPeriod,
      definitionsYearsEndPeriod,
      isClosed: computed(
        () => !!accountingPeriodsStore.currentAccountingPeriod?.closed
      ),
      closedAt: computed(
        () => accountingPeriodsStore.currentAccountingPeriod?.closedAt
      ),
      isIS: computed(() => accountingPeriodsStore.isIS),
      openArticle: () => {
        let taxRegimeArticleMap = new Map([
          [TaxRegime.IR_2072, ArticleEnum.EVENTS_YEAR_END],
          [TaxRegime.IS_2065, ArticleEnum.EVENTS_YEAR_END_IS],
        ]);
        const taxRegime =
          accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
          TaxRegime.IR_2072;

        useCrisp().openArticle(
          taxRegimeArticleMap.get(taxRegime) ?? ArticleEnum.EVENTS_YEAR_END
        );
      },
      showYearEndModal: computed(() => taskActivityStore.showYearEndModal),
      closeModal,
      ROUTE_NAMES,
    };
  },
});
