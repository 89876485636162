import {
  AnomalyCode,
  AnomalyParameters,
  BankAccount,
  Document,
  CheckNewAnomaliesParams,
  CheckNewAnomaliesType,
  Partner,
  RealEstateAsset,
  RealEstateLoan,
  RentalAgreement,
  RentalUnit,
  TaxRegime,
} from "..";

export type AnomaliesHelpersParams = Omit<
  CheckNewAnomaliesParams<CheckNewAnomaliesType>,
  "checkNewAnomaliesType" | "params"
>;

export const anomaliesHelpers = ({ data, options }: AnomaliesHelpersParams) => {
  const getBankAccount = (bankAccountId: string): BankAccount | undefined =>
    data.bankAccounts.find((bankAccount) => bankAccount.id === bankAccountId);
  const isIR = data.taxRegime === TaxRegime.IR_2072
  const isIS = data.taxRegime === TaxRegime.IS_2065
  const isLMNP = data.taxRegime === TaxRegime.LMNP_2031
  const getRealEstateAsset = (realEstateAssetId: string): RealEstateAsset | undefined =>
    data.realEstateAssets.find((realEstateAsset) => realEstateAsset.id === realEstateAssetId);
  const getRentalUnit = (rentalUnitId: string): RentalUnit | undefined =>
    data.rentalUnits.find((rentalUnit) => rentalUnit.id === rentalUnitId);
  const getRentalAgreement = (rentalAgreementId: string): RentalAgreement | undefined =>
    data.rentalAgreements.find((rentalAgreement) => rentalAgreement.id === rentalAgreementId);
  const getPartner = (partnerId: string): Partner | undefined =>
    data.partners.find((partner) => partner.id === partnerId);
  const getRealEstateLoan = (realEstateLoanId: string): RealEstateLoan | undefined =>
    data.realEstateLoans.find((realEstateLoan) => realEstateLoan.id === realEstateLoanId);
  const getSupportingDocument = (documentId: string): Document | undefined =>
    data.documents.find((document) => document.id === documentId);

  /**
   * If there are no options or the anomaly code is in the options, return true
   * @param {AnomalyCode} anomalyCode - AnomalyCode - The anomaly code to check for.
   */
  const hasNoOptionOrOptionAnomalyCode = (anomalyCode: AnomalyCode): boolean => !options || !!options[anomalyCode];

  /**
   * "If the options object exists, and the anomaly code exists in the options object, and the anomaly
   * parameter exists in the anomaly code's params object, then return true."
   *
   * @param {Code} anomalyCode - The anomaly code that we're checking for.
   * @param anomalyParam - The name of the anomaly parameter that we're checking for.
   */
  const hasOptionAnomalyParamsKey = <Code extends AnomalyCode>(
    anomalyCode: Code,
    anomalyParamKey: keyof AnomalyParameters[Code]
  ): boolean => {
    return (
      (options?.[anomalyCode]?.params as unknown as keyof AnomalyParameters[AnomalyCode])?.[anomalyParamKey] !==
      undefined
    );
  };

  /**
   * If the `params` object has an `options` property, and that property has a property with the same
   * name as the `anomalyCode` parameter, and that property has a `params` property, and that `params`
   * property has a property with the same name as the `anomalyParamKey` parameter, then return that
   * property
   * @param {Code} anomalyCode - The anomaly code for the anomaly you want to get the parameter for.
   * @param anomalyParamKey - The key of the parameter you want to get.
   * @returns The value of the anomalyParamKey property of the params object of the options object of the
   * anomalyCode property of the params object.
   */
  const getOptionAnomalyParamsKey = <Code extends AnomalyCode>(
    anomalyCode: Code,
    anomalyParamKey: keyof AnomalyParameters[Code]
  ): AnomalyParameters[Code][keyof AnomalyParameters[Code]] | undefined => {
    if (
      (options?.[anomalyCode]?.params as unknown as keyof AnomalyParameters[AnomalyCode])?.[anomalyParamKey] !==
      undefined
    ) {
      return (options?.[anomalyCode]?.params as unknown as keyof AnomalyParameters[AnomalyCode])[anomalyParamKey];
    }
  };

  const getOptionAnomalyReference = <Code extends AnomalyCode>(anomalyCode: Code): string | undefined => {
    if (options?.[anomalyCode]?.referenceId !== undefined) {
      return options?.[anomalyCode]?.referenceId;
    }
  };

  return {
    getBankAccount,
    isIR,
    isIS,
    isLMNP,
    getRealEstateAsset,
    getRentalUnit,
    getRentalAgreement,
    getPartner,
    getRealEstateLoan,
    getSupportingDocument,
    hasNoOptionOrOptionAnomalyCode,
    hasOptionAnomalyParamsKey,
    getOptionAnomalyParamsKey,
    getOptionAnomalyReference,
  };
};
