import { JournalComposedEntry, LedgerAccountEnum, TypeReference } from "../models/JournalComposedEntry";
import { RentalAgreement } from "../models/RentalAgreement.model";
import { getMoment } from "./moment";

export namespace RentsLib {
  export const isRentCallToday = (rental: RentalAgreement) => {
    // Check if options is activated
    if (
      !rental.options?.rentCallActivated ||
      !rental.options?.rentCallNumberDayBeforeRent ||
      !rental.options?.rentPaymentDayOfTheMonth
    )
      return false;

    // If in "rentCallNumberDayBeforeRent" days it is the same day as rentPaymentDayOfTheMonth for this rental agreement
    if (
      getMoment().add(rental.options.rentCallNumberDayBeforeRent, "day").get("date") ===
      rental.options.rentPaymentDayOfTheMonth
    )
      return true;

    return false;
  };

  export const isUnpaidRentReminderToday = (rental: RentalAgreement) => {
    // Check if options is activated
    if (
      !rental.options?.reminderUnpaidRentActivated ||
      !rental.options?.reminderRentCallNumberDayAfter ||
      !rental.options?.rentPaymentDayOfTheMonth
    )
      return false;
    if (rental.endAt) {
      if (!(getMoment().add(-rental.options.reminderRentCallNumberDayAfter, "day") < getMoment(rental.endAt))) {
        return false;
      }
    }
    // If the day is "reminderRentCallNumberDayAfter" days after rentPaymentDayOfTheMonth for this rental agreement
    if (
      getMoment().add(-rental.options.reminderRentCallNumberDayAfter, "day").get("date") ===
      rental.options.rentPaymentDayOfTheMonth
    )
      return true;

    return false;
  };

  export const getLastRentReceipt = (listRent: JournalComposedEntry[]) => {
    // To secure, get the first line with Rent Receipt
    const rentReceiptLinesSelector = (journalEntry: JournalComposedEntry) =>
      journalEntry.journalEntry?.lines?.find(({ account }) => account.startsWith(LedgerAccountEnum.N706000));

    const lastJournalCreated = listRent.find((journalEntry) => !!rentReceiptLinesSelector(journalEntry));
    if (!lastJournalCreated) return;

    const lastRentReceiptLines = rentReceiptLinesSelector(lastJournalCreated);
    if (!lastRentReceiptLines) return;

    const getAmountByLine = (ledgerAccount: LedgerAccountEnum | string) => {
      const targetedLine = lastJournalCreated.journalEntry.lines?.find(
        (line) => line.account.substring(0, 6) === ledgerAccount
      );
      if (targetedLine) return targetedLine.amount;
      return;
    };

    const rentAmount = (lastRentReceiptLines ? lastRentReceiptLines.amount : null) || 0;
    const rentAmountCharge = getAmountByLine(LedgerAccountEnum.N708399) || 0;
    const rentAmountTVA = getAmountByLine(LedgerAccountEnum.N445720);
    const rentAmountTotal = rentAmount + (rentAmountCharge || 0) + (rentAmountTVA || 0);
    let bankAccountId: string | undefined;
    if (lastJournalCreated.journalEntry?.lines && lastJournalCreated.journalEntry?.lines[0].id)
      bankAccountId = lastJournalCreated.journalEntry.lines[0].refs?.find(
        (ref) => ref.type === TypeReference.bankAccount
      )?.referredId;

    return {
      rentAmount,
      rentAmountCharge,
      rentAmountTVA,
      rentAmountTotal,
      bankAccountId,
    };
  };
}
