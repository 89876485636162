import { model, Schema, Document, ToObjectOptions } from "mongoose";
import { ulid } from "ulid";

import { AccountMap } from "./Ledger.model";
import { Address, Amount, OmitField, PartialField, RequireField } from "./Common.model";
import {
  AccountingPeriod,
  ActivitiesModel,
  Partner,
  ProductsModel,
  TaxDeclaration2031,
  TaxDeclaration2033,
  TaxDeclaration2033DataArchive,
  TaxDeclaration2065,
  TaxDeclarationProviderTeledecModel,
  TaxRegime,
  User,
} from "..";

export enum TaxDeclarationStatus {
  NotSend = "not-send",
  Processing = "processing",
  Success = "success",
  Error = "error",
}

/**
 * `SummaryRow` — A summary row.
 *
 * This interface was referenced by `undefined`'s JSON-Schema definition
 * via the `patternProperty` "^.+$".
 */
export interface SummaryRow {
  /**
   * Metadata about this row.
   *
   * The fields are specific to each SummaryTable.
   */
  meta: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` "^.+$".
     */
    [k: string]: string;
  };
  value?: Amount;
  /**
   * Indicates whether more details are available via an `.operations.list`
   * API.
   */
  hasSource: boolean;
  /**
   * Indicates that no valid value can be provided for this line
   * at this time.
   */
  invalidFormula: boolean;
  /**
   * A mapping of references names to amount.
   *
   * The names are a combination of the reference type, `/`, and the
   * reference identifier.
   */
  columns: AccountMap;
}

/**
 * `SummaryTable` — A summary table is a matrix providing metadata and values.
 *
 * The indices are the names of the rows in the matrix.
 */
export type SummaryTable = Record<string, AccountMap> & {
  /**
   * A mapping from a row's name to the row's content.
   */
  rows: {
    champ_code2072: SummaryRow;
    champ_libell__2072: SummaryRow;
    champ_r_gle_calcul: SummaryRow;
    [k: string]: SummaryRow;
  };
};

/**
 * * Tax declaration by regime
 * Use this section for add or remove an from
 */
export enum TaxDeclarationFromType {
  FORM_2065 = "form2065",
  FORM_2033 = "form2033",
  FORM_2031 = "form2031",
}

export type TaxDeclarationRegime<
  Status extends TaxDeclarationStatus = TaxDeclarationStatus,
  WithReference extends boolean = boolean,
  Regime extends TaxRegime = TaxRegime
> = { status: Status; taxRegime: Regime } & (
  | ({
      status: TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success | TaxDeclarationStatus.Error;
    } & true extends WithReference
      ? {
          taxDeclarationReferences: {
            type: TaxDeclarationFromType;
            // Regime extends TaxRegime.IS_2065
            //   ? TaxDeclarationFromType.FORM_2065 | TaxDeclarationFromType.FORM_2033
            //   : Regime extends TaxRegime.LMNP_2031
            //   ? TaxDeclarationFromType.FORM_2031
            //   : Regime extends TaxRegime.IR_2072
            //   ? never
            //   : never;
            referredId: string;
          }[];
        }
      :
          | {
              taxRegime: TaxRegime.IS_2065;
              [TaxDeclarationFromType.FORM_2065]: TaxDeclaration2065<true, false>;
              [TaxDeclarationFromType.FORM_2033]: TaxDeclaration2033<true, false, TaxRegime.IS_2065>;
            }
          | {
              taxRegime: TaxRegime.LMNP_2031;
              [TaxDeclarationFromType.FORM_2031]: TaxDeclaration2031<true, false>;
              [TaxDeclarationFromType.FORM_2033]: TaxDeclaration2033<true, false, TaxRegime.LMNP_2031>;
            }
          | {
              taxRegime: TaxRegime.IR_2072;
            })
  | ({ status: TaxDeclarationStatus.NotSend } & (
      | {
          taxRegime: TaxRegime.IS_2065;
          [TaxDeclarationFromType.FORM_2065]: TaxDeclaration2065<false, false>;
          [TaxDeclarationFromType.FORM_2033]: TaxDeclaration2033<false, false, TaxRegime.IS_2065>;
        }
      | {
          taxRegime: TaxRegime.LMNP_2031;
          [TaxDeclarationFromType.FORM_2031]: TaxDeclaration2031<false, false>;
          [TaxDeclarationFromType.FORM_2033]: TaxDeclaration2033<false, false, TaxRegime.LMNP_2031>;
        }
      | {
          taxRegime: TaxRegime.IR_2072;
        }
    ))
);

export type TaxDeclarationRegimeGenerated<Regime extends TaxRegime = TaxRegime> = {
  taxRegime: Regime;
} & (
  | {
      taxRegime: TaxRegime.IS_2065;
      [TaxDeclarationFromType.FORM_2065]: Pick<TaxDeclaration2065<true, true>, "dataArchived" | "lines">; // ! Use pick because this tax declaration is not validated
      [TaxDeclarationFromType.FORM_2033]: Pick<
        TaxDeclaration2033<true, true, TaxRegime.IS_2065>,
        "dataArchived" | "lines"
      >; // ! Use pick because this tax declaration is not validated
    }
  | {
      taxRegime: TaxRegime.LMNP_2031;
      [TaxDeclarationFromType.FORM_2031]: Pick<TaxDeclaration2031<true, true>, "dataArchived" | "lines">; // ! Use pick because this tax declaration is not validated
      [TaxDeclarationFromType.FORM_2033]: Pick<
        TaxDeclaration2033<true, true, TaxRegime.LMNP_2031>,
        "dataArchived" | "lines"
      >; // ! Use pick because this tax declaration is not validated
    }
  | { taxRegime: TaxRegime.IR_2072 }
);

export type TaxDeclarationRegimeValidated<Regime extends TaxRegime = TaxRegime> = {
  taxRegime: Regime;
} & (
  | {
      taxRegime: TaxRegime.IS_2065;
      [TaxDeclarationFromType.FORM_2065]: TaxDeclaration2065<true, true>;
      [TaxDeclarationFromType.FORM_2033]: TaxDeclaration2033<true, true, TaxRegime.IS_2065>;
    }
  | {
      taxRegime: TaxRegime.LMNP_2031;
      [TaxDeclarationFromType.FORM_2031]: TaxDeclaration2031<true, true>;
      [TaxDeclarationFromType.FORM_2033]: TaxDeclaration2033<true, true, TaxRegime.LMNP_2031>;
    }
  | { taxRegime: TaxRegime.IR_2072 }
);

/**
 * * Global data for generate or send declaration to provider
 * This is use for archive data by from (by regime)
 */
export type TaxDeclarationHeaderData = {
  user: Pick<User, "id" | "firstName" | "lastName" | "email">;
  product: Pick<ProductsModel.Product<ProductsModel.ProductTypes.SCI>, "id" | "email"> & {
    email: NonNullable<ProductsModel.Product["email"]>;
  };
  activity: Required<Pick<ActivitiesModel.Activity, "id" | "type" | "address" | "siret">> & {
    addressPrevious?: RequireField<Address, "street" | "city" | "zip">;
  } & (
      | Pick<ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.COMPANY>, "type" | "name">
      | Pick<
          ActivitiesModel.Activity<
            ActivitiesModel.ActivityTypes.OPERATOR,
            ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
          >,
          "type" | "operatorType" | "firstName" | "lastName"
        >
      | Pick<
          ActivitiesModel.Activity<
            ActivitiesModel.ActivityTypes.OPERATOR,
            ActivitiesModel.ActivityOperatorTypes.LEGAL_PERSON
          >,
          "type" | "operatorType" | "denomination" | "legalStatus"
        >
    );
  accountingPeriod: Pick<AccountingPeriod, "id" | "startAt" | "endAt" | "taxRegime" | "firstYear">;
  accountingPeriodPrevious?: Pick<AccountingPeriod, "startAt" | "endAt">;
  partnerOwned: Pick<Partner, "id" | "type" | "firstName" | "lastName" | "denomination" | "role" | "createdAt">;
};

/**
 * * Tax declaration global
 * Is use for consolidate declaration for the fiscal regime (multi-from) and if send, follow status in provider (Ownily <-> Provider <-> DGFIP)
 */
export type TaxDeclaration<
  Regime extends TaxRegime = TaxRegime,
  Status extends TaxDeclarationStatus = TaxDeclarationStatus,
  WasSendToDgfip extends boolean = boolean,
  WithReference extends boolean = boolean
> = {
  productId: string;
  accountingPeriodId: string;
  taxRegime: Regime;
  status: Status;
} & TaxDeclarationRegime<Status, WithReference, Regime> &
  (
    | {
        status: TaxDeclarationStatus.NotSend;
      }
    | ({
        id: string;
        status: TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success | TaxDeclarationStatus.Error;
        documentPdfCerfaId: string;
        wasSendToDgfip: WasSendToDgfip; // Indicate choice to send declaration to dgfip or just archive document
        createdAt: string;
        updatedAt: string;
      } & (
        | {
            status: TaxDeclarationStatus.Success;
            wasSendToDgfip: false;
          }
        | {
            status: TaxDeclarationStatus.Processing;
            wasSendToDgfip: true;
            sendAt: string;
          }
        | {
            status: TaxDeclarationStatus.Success;
            wasSendToDgfip: true;
            sendAt: string;
            dgfip: TaxDeclarationProviderTeledecModel.WebHook.Event;
            documentPdfDgfipId: string;
          }
        | {
            status: TaxDeclarationStatus.Error;
            wasSendToDgfip: true;
            sendAt: string;
            dgfip: TaxDeclarationProviderTeledecModel.WebHook.Event;
          }
        | {
            status: TaxDeclarationStatus.Success | TaxDeclarationStatus.Error;
            wasSendToDgfip: true;
            sendAt: string;
            dgfip: TaxDeclarationProviderTeledecModel.WebHook.Event;
            documentPdfDgfipId?: string;
          }
        | {
            status: TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success;
            wasSendToDgfip: boolean;
            sendAt?: string;
            dgfip?: TaxDeclarationProviderTeledecModel.WebHook.Event;
            documentPdfDgfipId?: string;
          }
      ))
  );

export type TaxDeclarationGenerated<Regime extends TaxRegime = TaxRegime> = {
  productId: string;
  accountingPeriodId: string;
  status: TaxDeclarationStatus.NotSend;
  taxRegime: Regime;
  documentPdf: Buffer;
} & TaxDeclarationRegimeGenerated<Regime>;

export type TaxDeclarationValidated<Regime extends TaxRegime = TaxRegime> = PartialField<
  OmitField<TaxDeclarationGenerated<Regime>, "status">,
  "documentPdf"
> &
  TaxDeclarationRegimeValidated &
  TaxDeclarationRegime<TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success, true, Regime>;

export type TaxDeclarationRepository<
  Regime extends TaxRegime = TaxRegime,
  Status extends TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success | TaxDeclarationStatus.Error =
    | TaxDeclarationStatus.Processing
    | TaxDeclarationStatus.Success
    | TaxDeclarationStatus.Error,
  WasSendToDgfip extends boolean = boolean
> = OmitField<TaxDeclaration<Regime, Status, WasSendToDgfip, true>, "taxRegime">;
export type TaxDeclarationRepositoryCreate = {
  status: TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success; // ! Problème d'évualuation de type, obliger de forcé malgré `TaxDeclarationRepository` sur `TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success`
} & OmitField<
  TaxDeclarationRepository<TaxRegime, TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success>,
  "status" | "createdAt" | "updatedAt"
>;
export type TaxDeclarationRepositoryUpdate = OmitField<
  RequireField<
    Partial<
      TaxDeclarationRepository<
        TaxRegime,
        TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success | TaxDeclarationStatus.Error
      >
    >,
    "id"
  >,
  "productId" | "accountingPeriodId" | "createdAt" | "updatedAt"
>;
export type TaxDeclarationRepositoryUpdateStatus = OmitField<
  RequireField<
    Partial<TaxDeclarationRepository<TaxRegime, TaxDeclarationStatus.Success | TaxDeclarationStatus.Error, true>>,
    "id" | "status" | "wasSendToDgfip" | "dgfip"
  >,
  "productId" | "accountingPeriodId" | "createdAt" | "updatedAt"
>;
// export type TaxDeclarationRepositoryUpdateStatus = OmitField<
//   TaxDeclarationRepository<TaxRegime, TaxDeclarationStatus.Success | TaxDeclarationStatus.Error, true>,
//   "productId" | "accountingPeriodId" | "createdAt" | "updatedAt"
// >[""];

// Mongo
export type TaxDeclarationDocument = TaxDeclarationRepository & Document<string>;

const TaxDeclarationSchema = new Schema<TaxDeclarationRepository>(
  {
    _id: { type: String, default: (): string => ulid() },
    productId: { type: String, index: true, required: true },
    accountingPeriodId: { type: String, index: true, required: true },
    status: { type: String },
    taxDeclarationReferences: [
      new Schema<TaxDeclarationRepository["taxDeclarationReferences"] & Document<undefined>>(
        {
          type: { type: String, enum: Object.values(TaxDeclarationFromType) },
          referredId: { type: String },
        },
        { _id: false, timestamps: false }
      ),
    ],
    wasSendToDgfip: { type: Boolean },
    sendAt: { type: String },
    documentPdfCerfaId: { type: String },
    documentPdfDgfipId: { type: String },
    dgfip: {
      declarationId: { type: Number },
      status: { type: String },
      declarationStatus: { type: String },
      reference: { type: String },
      siren: { type: String },
      nom: { type: String },
      dateHeureDGFiP: { type: String },
      dateDebut: { type: String },
      dateFin: { type: String },
      statusLibelle: { type: String },
      compte: { type: String },
      referenceDGFiP: { type: String },
      numeroTraitementDGFiP: { type: String },
      rof: { type: String },
      pdf: { type: String },
      dateEcheance: { type: String },
      libelle: { type: String },
      declarationType: { type: String },
      formulaire: { type: String },
      declarationErreurs: {
        type: [
          new Schema<TaxDeclarationProviderTeledecModel.WebHook.ErrorDeclarationErreur & Document<undefined>>(
            {
              formulaire: { type: String },
              champ: { type: String },
              champLibelle: { type: String },
              champValeur: { type: String },
              code: { type: String },
              libelle: { type: String },
            },
            { _id: false, timestamps: false }
          ),
        ],
      },
    },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(
        doc: Omit<TaxDeclarationDocument, "createdAt" | "updatedAt"> & {
          _id: string;
          createdAt: Date;
          updatedAt: Date;
        },
        ret: TaxDeclarationRepository & { _id?: string },
        options: ToObjectOptions
      ) {
        delete ret._id;
        ret.id = doc._id;
        return ret;
      },
    },
  }
);

export const TaxDeclarationModel = model<TaxDeclarationDocument>(
  "TaxDeclaration",
  TaxDeclarationSchema,
  "TaxDeclarations"
);

// API
export namespace TaxDeclarationsService {
  export type ListIn = Partial<Pick<TaxDeclaration, "productId" | "accountingPeriodId">>;
  export type ListOut = TaxDeclaration<TaxRegime, TaxDeclarationStatus, boolean, true>[];

  export type GetIn = Pick<TaxDeclaration, "accountingPeriodId">;
  export type GetOut = TaxDeclaration<TaxRegime, TaxDeclarationStatus, boolean, true>;

  export type ExportIn<Regime extends TaxRegime = TaxRegime> = Pick<TaxDeclaration, "accountingPeriodId"> &
    Pick<TaxDeclarationHeaderData, "accountingPeriodPrevious"> &
    Pick<TaxDeclarationHeaderData["activity"], "addressPrevious"> &
    (Regime extends TaxRegime.IS_2065
      ? {
          partners?: Pick<TaxDeclaration2033DataArchive<Regime>["partners"][number], "id" | "birthAddress">[];
        }
      : Record<never, never>);
  export type ExportOut = Buffer;

  export type ValidateIn<Regime extends TaxRegime = TaxRegime> = Pick<TaxDeclaration, "accountingPeriodId"> &
    Pick<TaxDeclarationHeaderData, "accountingPeriodPrevious"> &
    Pick<TaxDeclarationHeaderData["activity"], "addressPrevious"> &
    (Regime extends TaxRegime.IS_2065
      ? {
          partners?: Pick<TaxDeclaration2033DataArchive<Regime>["partners"][number], "id" | "birthAddress">[];
        }
      : Record<never, never>);
  export type ValidateOut = TaxDeclaration<
    TaxRegime,
    TaxDeclarationStatus.Processing | TaxDeclarationStatus.Success,
    boolean,
    true
  >;

  export type GetReporting2072In = { accountingPeriodId: string };
  export type GetReporting2072Out = SummaryTable;

  export type GetReporting2044In = { accountingPeriodId: string; partnerId: string };
  export type GetReporting2044Out = Buffer;

  export type Get2033In = PartialField<Pick<TaxDeclaration2033<true>, "id" | "accountingPeriodId">, "id">;
  export type Get2033Out = TaxDeclaration2033;

  export type Get2065In = PartialField<Pick<TaxDeclaration2065<true>, "id" | "accountingPeriodId">, "id">;
  export type Get2065Out = TaxDeclaration2065;

  export type Get2031In = PartialField<Pick<TaxDeclaration2031<true>, "id" | "accountingPeriodId">, "id">;
  export type Get2031Out = TaxDeclaration2031;

  export type SearchTransactionsIn = {
    accountingPeriodId: string;
    name: string;
    refs: string[];
  };
  export type SearchTransactionsOut = string[];

  export type GetTemplateIn = never;
  export type GetTemplateOut = SummaryTable;
}

/**
 * * Lib
 */
export namespace TaxDeclarationsLib {
  export const isTaxRegime =
    <Regime extends TaxRegime, Declaration extends TaxDeclaration>(taxRegime: Regime) =>
    (taxDeclaration: Declaration): taxDeclaration is Declaration & { taxRegime: Regime } =>
      taxDeclaration.taxRegime === taxRegime;
}
