






























































































import Confirm from "@/components/core/modals/Confirm.vue";
import { VConfirmDialog } from "@/models";
import { transactionsStore } from "@/store";
import {
  CategorizationEntry,
  CheckNewAnomaliesType,
  Direction,
  isPlanBankAccount,
  JournalEntryLine,
  Transaction,
  TransactionImportType,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
  PropType,
} from "@vue/composition-api";
import { useAnomalies } from "../../anomalies/anomalies.usable";
import DialogRight from "../../DialogRight.vue";
import RentReceipt from "../../rent/RentReceipt.vue";
import Categories from "../categorization/Categories.vue";
import { useCategorization } from "../categorization/categorization.usable";
import Categorization from "../categorization/Categorization.vue";
import CategorizationStep1 from "../categorization/CategorizationStep1.vue";
import CategorizationStep2 from "../categorization/CategorizationStep2.vue";
import { TransactionState, useTransaction } from "./transaction.usable";
import TransactionActions from "./TransactionActions.vue";
import TransactionAttachments from "./TransactionAttachments.vue";
import TransactionCategories from "./TransactionCategories.vue";
import TransactionHeader from "./TransactionHeader.vue";

export default defineComponent({
  name: "Transaction",
  components: {
    TransactionHeader,
    TransactionCategories,
    TransactionActions,
    TransactionAttachments,
    RentReceipt,
    Categories,
    Categorization,
    CategorizationStep1,
    CategorizationStep2,
    DialogRight,
    Confirm,
  },
  props: {
    transactionId: {
      type: String,
      required: true,
    },
    isOnAddManualTransaction: {
      type: Boolean,
      default: false,
    },
    transactionSource: {
      type: Object as PropType<Transaction>,
    },
    isCategorized: {
      type: Boolean,
      default: false,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    const { checkDisableAnomalies, checkOnNewAnomalies } = useAnomalies();
    const transaction = computed(
      () => transactionsStore.transactions[props.transactionId]
    );
    const isOpenRentReceiptStep: Ref<number> = ref(0);

    // TODO in back to simplify here... Back should return CategoryEntry in controller. (front should work with category and no lines)
    const formatLinesTransaction = (
      categories: JournalEntryLine[]
    ): CategorizationEntry[] =>
      categories
        .filter(
          (line: JournalEntryLine) =>
            // Account 512000 and 512100
            !isPlanBankAccount(line.account) && line.account !== "512100"
        )
        .map((line: JournalEntryLine) => {
          const category: CategorizationEntry = {
            account: line.account,
            accountName: line.accountName,
            amount:
              line.direction === Direction.credit ? line.amount : -line.amount,
          };

          if (line.refs) {
            //Add each ref type (realEstateAsset, rentalUnit, partner, client) and id associated to object category
            line.refs.forEach((ref) => (category[ref.type] = ref.referredId));
          }
          return category;
        });

    const initTransactionState = (): TransactionState => ({
      transaction: transaction.value,

      savedCategories:
        transaction.value.operations?.journalEntry?.lines &&
        transaction.value.operations?.journalEntry?.lines.length > 0
          ? formatLinesTransaction(
              transaction.value.operations?.journalEntry?.lines
            )
          : [],
      lines:
        transaction.value.operations?.journalEntry?.lines &&
        transaction.value.operations?.journalEntry?.lines.length > 0
          ? formatLinesTransaction(
              transaction.value.operations?.journalEntry?.lines
            ).reverse()
          : [],
      suggestedLines:
        transaction.value.operations?.journalEntry?.suggestedLines &&
        transaction.value.operations?.journalEntry?.suggestedLines.length > 0
          ? formatLinesTransaction(
              transaction.value.operations?.journalEntry?.suggestedLines
            )
          : [],
      suggestedTransactionIds: [],
      anomaliesErrors: [],

      isOnAddManualTransaction: props.isOnAddManualTransaction,
      isOnDuplicateTransaction: false,
      isUpdatingCategorization: false,
      isOpenCategorizationList: false,
      isOpenCategorizationDetailStep: false,
      isOpenCategorizationDuplicate: false,
      isCategorized:
        (transaction.value.operations?.journalEntry?.lines &&
          transaction.value.operations?.journalEntry?.lines.length > 0) ||
        false,
      isManualTransaction:
        transaction.value.source.provider === TransactionImportType.MANUAL ||
        false,
      isImportTransaction:
        transaction.value.source.provider === TransactionImportType.IMPORT ||
        false,
      isSystemTransaction:
        transaction.value.source.provider === TransactionImportType.SYSTEM ||
        false,
      isAmortizationTransaction:
        transaction.value.source.provider ===
          TransactionImportType.AMORTIZATION || false,
      isAcquisitionTransaction:
        transaction.value.source.provider ===
          TransactionImportType.ACQUISITION || false,
      isExpenseTransaction:
        transaction.value.source.provider ===
          TransactionImportType.EXPENSE_REPORT || false,
    });

    const transactionState: Ref<TransactionState> = ref(initTransactionState());

    const checkCategorizationAnomalies = async () => {
      const { anomaliesErrorsSortByPriority } = await checkOnNewAnomalies({
        checkNewAnomaliesType: CheckNewAnomaliesType.transaction,
        transactions: [transactionState.value.transaction],
      });
      transactionState.value.anomaliesErrors =
        anomaliesErrorsSortByPriority.filter((anomaly) =>
          checkDisableAnomalies(anomaly.anomalyError)
        );
    };

    watch(
      transaction,
      async () => {
        transactionState.value = initTransactionState();
        await checkCategorizationAnomalies();
      },
      { deep: true }
    );

    watch(
      () => transactionState.value.isOnDuplicateTransaction,
      () => {
        if (transactionState.value.isOnDuplicateTransaction) {
          context.emit("open:duplication", transaction.value.id);
        }
      },
      { deep: true }
    );

    const { isManualTransaction, getSummary } = computed(() =>
      useTransaction(transactionState.value, context)
    ).value;
    const {
      isOpenCategorizationList,
      isOpenCategorizationDetailStep,
      addManualTransactionFlow,
    } = computed(() =>
      useCategorization(transactionState.value, context)
    ).value;

    const summary = computed(() => {
      return getSummary();
    });

    const closeCategorizationDetails = () => {
      transactionState.value.lines = transactionState.value.savedCategories;
      isOpenCategorizationDetailStep.value = false;
      isOpenCategorizationList.value = false;
    };

    const computedAvatar = computed(() => {
      // if (
      //   transactionState.value.transaction.source.provider ===
      //   TransactionImportType.MANUAL
      // ) {
      //   if (transactionState.value.transaction.value.amount >= 0) {
      //     return "#fbb8d7";
      //   } else {
      //     return "#d17085";
      //   }
      // }
      // if (
      //   transactionState.value.transaction.source.provider ===
      //   TransactionImportType.IMPORT
      // ) {
      //   if (transactionState.value.transaction.value.amount >= 0) {
      //     return "#b8fbd7";
      //   } else {
      //     return "#70d185";
      //   }
      // }
      if (
        transaction.value.value?.amount &&
        transaction.value.value.amount >= 0
      ) {
        return "#b8d7fb";
      } else {
        return "#7085d1";
      }
    });

    watch(
      // For validate categorization on add manual transaction
      () => transactionState.value.isCategorized,
      (isCategorized) => context.emit("update:isCategorized", isCategorized),
      { deep: true }
    );

    onBeforeMount(async () => {
      if (props.isOnAddManualTransaction) {
        isManualTransaction.value = true;
        addManualTransactionFlow(context.refs.confirmDialog as VConfirmDialog);
      }
      if (props.transactionSource) {
        isManualTransaction.value = true;
        transactionState.value.isOpenCategorizationDuplicate = true;
        transactionState.value.suggestedLines = [];
        transactionState.value.lines = formatLinesTransaction(
          props.transactionSource?.operations?.journalEntry?.lines?.map(
            (line) => {
              const filteredRefs = line.refs?.filter(
                (ref) => ref.type !== "supportingDocument"
              );
              return {
                ...line,
                refs: filteredRefs,
              };
            }
          ) ?? []
        ).reverse();
      }
      await checkCategorizationAnomalies();
    });

    return {
      summary,
      transactionState,
      transaction,
      computedAvatar,
      isOpenRentReceiptStep,
      closeCategorizationDetails,
    };
  },
});
