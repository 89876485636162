


















































































































import Vue from "vue";
import {
  accountingPeriodsStore,
  productsStore,
  coreStore,
  realEstateAssetsStore,
  rentalBuildingsStore,
} from "@/store";
import { getMoment, RentalBuilding } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { ForbiddenError, subject } from "@casl/ability";

import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ability } from "@/services";

import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

import Title from "../../title/Title.vue";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import { HelpingMessage } from "@/components/atom/helping";
import RentalBuildingView from "./RentalBuilding.vue";

export default defineComponent({
  name: "RentalBuildings",
  components: {
    Title,
    AccountingPeriodChoice,
    HelpingMessage,
    RentalBuildingView,
  },
  props: {
    new: { type: Boolean, required: false },
  },
  setup(props, context) {
    const rentalBuildings: Ref<
      (
        | (RentalBuilding & { isEditing: boolean })
        | { id: "new"; isEditing: boolean }
      )[]
    > = ref([]);
    const showDuplicateButonDuringProcess: Ref<boolean> = ref(false);

    const realEstateAssetsNotAssign = computed(() =>
      realEstateAssetsStore.realEstateAssets.filter((realEstateAsset) => {
        for (const rentalBuilding of rentalBuildingsStore.getRentalBuildingByAccountingPeriodId(
          accountingPeriodsStore.currentId
        )) {
          if (rentalBuilding.realEstateAssetIds.includes(realEstateAsset.id)) {
            return false;
          }
        }
        return true;
      })
    );

    const lastAccountingPeriod =
      accountingPeriodsStore.accountingPeriods.length >= 2 &&
      accountingPeriodsStore.accountingPeriods[1];
    const year =
      lastAccountingPeriod && lastAccountingPeriod.endAt
        ? new Date(lastAccountingPeriod.endAt).getFullYear()
        : "--";

    /**
     * Action
     */
    const createRentalBuilding = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "add",
          subject("RentalBuilding", { productId: productsStore.currentId })
        );
        const rentalBuildingIndex = rentalBuildings.value.findIndex(
          (rentalBuilding) => rentalBuilding.id === "new"
        );
        if (rentalBuildingIndex !== -1) {
          Vue.set(rentalBuildings.value, rentalBuildingIndex, {
            ...rentalBuildings.value[rentalBuildingIndex],
            isEditing: true,
          });
        } else {
          rentalBuildings.value.push({ id: "new", isEditing: true });
        }
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };

    const previousYearPeriod = computed(() => {
      const accountingPeriodList = accountingPeriodsStore.accountingPeriods.map(
        ({ startAt, endAt }, index) => ({
          startAt: getMoment(startAt),
          endAt: getMoment(endAt),
          index,
        })
      );
      // On trie du plus vieux au plus récent
      accountingPeriodList.sort((a, b) => b.startAt.unix() - a.startAt.unix());
      const currentAccountingPeriodEndAt = getMoment(
        accountingPeriodsStore.currentAccountingPeriod?.endAt
      );
      accountingPeriodList.filter(({ endAt }) =>
        currentAccountingPeriodEndAt.isBefore(endAt)
      );

      if (accountingPeriodList.length > 1) {
        return accountingPeriodsStore.accountingPeriods[
          accountingPeriodList[1].index
        ];
      }

      return null;
    });
    const rentalBuildingsForPreviousYear = computed(() => {
      const rentalBuildingsForPreviousYear =
        rentalBuildingsStore.getRentalBuildingByAccountingPeriodId(
          previousYearPeriod.value?.id
        );
      return rentalBuildingsForPreviousYear;
    });

    const rentalBuildingsForCurrentYear = computed(() =>
      rentalBuildingsStore.getRentalBuildingByAccountingPeriodId(
        accountingPeriodsStore.currentId
      )
    );

    const duplicatePreviousAccountingPeriodOnCurrent = async () => {
      showDuplicateButonDuringProcess.value = false;

      await Promise.all(
        rentalBuildingsForPreviousYear.value.map(async (rentalBuilding) => {
          return rentalBuildingsStore.createRentalBuilding({
            newRentalBuilding: rentalBuilding,
            customAccountingPeriodId: accountingPeriodsStore.currentId,
          });
        })
      );
    };

    /**
     * Event
     */
    const showCerfaEvent = (rentalBuildingId) =>
      context.emit("showCerfa", rentalBuildingId);

    watch(
      () => [
        accountingPeriodsStore.currentId,
        rentalBuildingsForPreviousYear,
        rentalBuildingsForCurrentYear,
      ],
      () => {
        if (
          rentalBuildingsForPreviousYear.value.length &&
          !rentalBuildingsForCurrentYear.value.length &&
          !previousYearPeriod.value?.closed
        ) {
          showDuplicateButonDuringProcess.value = true;
        } else {
          showDuplicateButonDuringProcess.value = false;
        }
      },
      { deep: true }
    );

    /**
     * Init
     */
    const init = async () => {
      const rentalBuildingsList =
        rentalBuildingsStore.getRentalBuildingByAccountingPeriodId(
          accountingPeriodsStore.currentId
        );
      rentalBuildings.value = [
        ...rentalBuildingsList.map((value) => ({ ...value, isEditing: false })),
        { id: "new", isEditing: props.new },
      ];
    };

    watch(
      () =>
        rentalBuildingsStore.getRentalBuildingByAccountingPeriodId(
          accountingPeriodsStore.currentId
        ),
      () => init(),
      { deep: true }
    );

    onBeforeMount(async () => {
      await rentalBuildingsStore.fetchRentalBuildings({});

      await init();

      // Check if the next year's configuration is empty or not
    });

    return {
      rentalBuildings,
      realEstateAssetsNotAssign,
      openArticleRentalBuildings: () =>
        useCrisp().openArticle(ArticleEnum.RENTAL_BUILDINGS),
      createRentalBuilding,
      showCerfaEvent,
      year,
      duplicatePreviousAccountingPeriodOnCurrent,
      showDuplicateButonDuringProcess,
    };
  },
});
