
















































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { TaxRegime } from "@edmp/api";

import RegisterActivityDetailsIR from "./RegisterActivityDetailsIR.vue";
import RegisterActivityDetailsIS from "./RegisterActivityDetailsIS.vue";
import RegisterActivityDetailsLMNP from "./RegisterActivityDetailsLMNP.vue";

export default defineComponent({
  name: "RegisterActivityDetails",
  components: {
    RegisterActivityDetailsIR,
    RegisterActivityDetailsIS,
    RegisterActivityDetailsLMNP,
  },
  props: {
    step: {
      type: String,
      required: true,
    },
    activityType: {
      type: String as PropType<"SCI" | "LMNP">,
      required: true,
    },
    taxRegime: {
      type: String as PropType<TaxRegime>,
      required: true,
    },
  },
  setup(props) {
    const isRegimeIR = computed(() => props.taxRegime === TaxRegime.IR_2072);
    const isRegimeIS = computed(() => props.taxRegime === TaxRegime.IS_2065);
    const isRegimeLMNP = computed(
      () => props.taxRegime === TaxRegime.LMNP_2031
    );

    const taxRegimeItems = computed(() =>
      props.activityType === "SCI"
        ? [
            {
              text: "Imposition sur les revenus des Associés (2072)",
              value: TaxRegime.IR_2072,
            },
            {
              text: "Imposition sur les Sociétés (2065)",
              value: TaxRegime.IS_2065,
            },
          ]
        : [
            {
              text: "Réel simplifié (comptabilité et amortissements) ",
              value: TaxRegime.LMNP_2031,
            },
          ]
    );

    return { taxRegimeItems, isRegimeIR, isRegimeIS, isRegimeLMNP };
  },
});
