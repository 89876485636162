import { AccountingPeriod } from "../models/AccountingPeriod.model";
import { getMoment } from ".";
import { FixedAssetAmortisation } from "../models/FixedAssetAmortisation.model";
import { FixedAsset } from "../models/FixedAsset.model";

type amortizationDaysStatsForThisAccountingPeriod = {
  amortizedDays: number;
  daysInThisAccountingPeriod: number;
  remainingDaysToAmortize: number;
};

type amortizationValuesStatsForThisAccountingPeriod = {
  valueAmortized: number;
  valueToAmortizedForThisAccountingPeriod: number;
  remainingValueToAmortize: number;
  details: ({
    valueAmortized: number;
    valueToAmortizedForThisAccountingPeriod: number;
    remainingValueToAmortize: number;
  } & Pick<FixedAssetAmortisation, "type" | "fixedAssetId">)[];
};

const amortizationDaysStatsForAnAccountingPeriodFixedAsset = (
  fixedAsset: FixedAsset,
  accountingPeriod: AccountingPeriod,
  yearsAmortization: number
) => {
  const res: amortizationDaysStatsForThisAccountingPeriod = {
    amortizedDays: 0,
    daysInThisAccountingPeriod: 0,
    remainingDaysToAmortize: 0,
  };

  if (fixedAsset?.commissioningAt) {
    if (getMoment(fixedAsset.commissioningAt) < getMoment(accountingPeriod.startAt)) {
      res.daysInThisAccountingPeriod =
        getMoment(accountingPeriod.endAt).diff(getMoment(accountingPeriod.startAt), "days") + 1;
    } else {
      res.daysInThisAccountingPeriod =
        getMoment(accountingPeriod.endAt).diff(getMoment(fixedAsset.commissioningAt), "days") + 1;
    }

    res.amortizedDays = getMoment(accountingPeriod.startAt).diff(getMoment(fixedAsset.commissioningAt), "days");
    if (res.amortizedDays < 0) {
      res.amortizedDays = 0;
    }
    res.remainingDaysToAmortize = getMoment(fixedAsset.commissioningAt)
      .add(yearsAmortization, "years")
      .diff(getMoment(fixedAsset.commissioningAt).add(res.amortizedDays, "days"), "days");
    if (res.remainingDaysToAmortize < 0) {
      res.remainingDaysToAmortize = 0;
    }
    if (res.daysInThisAccountingPeriod < 0) {
      res.daysInThisAccountingPeriod = 0;
    }
    return res;
  }
};

export const sumAllAmortizationValueStatsForAnAccountingPeriodFixedAsset = (
  accountingPeriods: AccountingPeriod[],
  accountingPeriodIdToSum: string,
  fixedAssets: FixedAsset[],
  fixedAssetAmortisations: FixedAssetAmortisation[]
) => {
  const targetedAccountingPeriod = accountingPeriods.find(
    (accountingPeriod) => accountingPeriod.id === accountingPeriodIdToSum
  );
  if (!targetedAccountingPeriod) throw new Error("Accounting period not found");

  const res: amortizationValuesStatsForThisAccountingPeriod = {
    valueAmortized: 0,
    valueToAmortizedForThisAccountingPeriod: 0,
    remainingValueToAmortize: 0,
    details: [],
  };
  for (const fixedAsset of fixedAssets) {
    if (fixedAsset.commissioningAt) {
      const amortizations = fixedAssetAmortisations.filter(({ fixedAssetId }) => fixedAssetId === fixedAsset.id);
      if (amortizations.length > 0) {
        for (const amortization of amortizations) {
          const detail: typeof res["details"][number] = {
            type: amortization.type,
            fixedAssetId: amortization.fixedAssetId,
            valueAmortized: 0,
            valueToAmortizedForThisAccountingPeriod: 0,
            remainingValueToAmortize: 0,
          };

          const daysStats = amortizationDaysStatsForAnAccountingPeriodFixedAsset(
            fixedAsset,
            targetedAccountingPeriod,
            amortization.durationInYear
          );
          if (daysStats) {
            const allDays = daysStats.amortizedDays + daysStats.remainingDaysToAmortize;
            res.valueAmortized =
              res.valueAmortized + Math.round((amortization.value / allDays) * daysStats.amortizedDays);
            detail.valueAmortized = Math.round((amortization.value / allDays) * daysStats.amortizedDays);
            if (daysStats.daysInThisAccountingPeriod < daysStats.remainingDaysToAmortize) {
              res.valueToAmortizedForThisAccountingPeriod =
                res.valueToAmortizedForThisAccountingPeriod +
                Math.round((amortization.value / allDays) * daysStats.daysInThisAccountingPeriod);
              detail.valueToAmortizedForThisAccountingPeriod = Math.round(
                (amortization.value / allDays) * daysStats.daysInThisAccountingPeriod
              );
            } else {
              res.valueToAmortizedForThisAccountingPeriod =
                res.valueToAmortizedForThisAccountingPeriod +
                Math.round((amortization.value / allDays) * daysStats.remainingDaysToAmortize);
              detail.valueToAmortizedForThisAccountingPeriod = Math.round(
                (amortization.value / allDays) * daysStats.remainingDaysToAmortize
              );
            }
            res.remainingValueToAmortize =
              res.remainingValueToAmortize +
              Math.round((amortization.value / allDays) * daysStats.remainingDaysToAmortize);
            detail.remainingValueToAmortize = Math.round(
              (amortization.value / allDays) * daysStats.remainingDaysToAmortize
            );
          }

          res.details.push(detail);
        }
      }
    }
  }
  return res;
};

export const sumAmortizationValueStatsForAFixedAsset = ({
  fixedAssetAmortisations,
  accountingPeriods,
  accountingPeriodIdToSum,
  fixedAssets,
  fixedAssetIdToSum,
}: {
  fixedAssetAmortisations: FixedAssetAmortisation[];
  accountingPeriods: AccountingPeriod[];
  accountingPeriodIdToSum: string;
  fixedAssets: FixedAsset[];
  fixedAssetIdToSum: string;
}) => {
  const fixedAssetsTemp = fixedAssets.filter(({ id }) => id === fixedAssetIdToSum);
  const fixedAssetAmortisationsTemp = fixedAssetAmortisations.filter(
    ({ fixedAssetId }) => fixedAssetId === fixedAssetIdToSum
  );
  return sumAllAmortizationValueStatsForAnAccountingPeriodFixedAsset(
    accountingPeriods,
    accountingPeriodIdToSum,
    fixedAssetsTemp,
    fixedAssetAmortisationsTemp
  );
};
export const sumAllAmortisationsExpenseFixedAsset = ({
  fixedAssetAmortisations,
  accountingPeriods,
  accountingPeriodIdToSum,
  fixedAssets,
}: {
  fixedAssetAmortisations: FixedAssetAmortisation[];
  accountingPeriods: AccountingPeriod[];
  accountingPeriodIdToSum: string;
  fixedAssets: FixedAsset[];
}): number => {
  const targetedAccountingPeriod = accountingPeriods.find(
    (accountingPeriod) => accountingPeriod.id === accountingPeriodIdToSum
  );
  if (!targetedAccountingPeriod) throw new Error("Accounting period not found");

  const targetedAccountingPeriodStartAt = getMoment(targetedAccountingPeriod.startAt);
  const targetedAccountingPeriodEndAt = getMoment(targetedAccountingPeriod.endAt);

  // Sum all amortisations for the current accounting periods
  return fixedAssetAmortisations
    .filter((amortisation) => {
      const fixedAsset = fixedAssets.find(({ id }) => id === amortisation.fixedAssetId);
      const amortisationStartAt =
        (amortisation.startAt || fixedAsset?.commissioningAt) &&
        getMoment(amortisation.startAt || fixedAsset?.commissioningAt);

      // Must not exist
      if (!amortisationStartAt) {
        console.warn(
          "Amortisation without startAt and fixedAssets without commissioningAt",
          amortisation.id,
          fixedAsset?.id
        );
        return false;
      }

      // We add 1 year because the amortisation should be valid one year after the end of the amortisation if the first year is half amortisation
      const amortisationEndAt = getMoment(amortisationStartAt).add(amortisation.durationInYear + 1, "years");

      // Check if the amortisation is in the targeted accounting period
      return (
        amortisationStartAt.isSameOrBefore(targetedAccountingPeriodEndAt) &&
        amortisationEndAt.isSameOrAfter(targetedAccountingPeriodStartAt)
      );
    })
    .reduce((acc, amortisation) => {
      const fixedAsset = fixedAssets.find(({ id }) => id === amortisation.fixedAssetId);
      const amortisationStartAt =
        (amortisation.startAt || fixedAsset?.commissioningAt) &&
        getMoment(amortisation.startAt || fixedAsset?.commissioningAt);
      if (!amortisationStartAt) return acc;

      const amortisationEndAt = getMoment(amortisationStartAt).add(amortisation.durationInYear, "years");

      // Get the accounting period when declare, could be different from the startAt
      const accountingPeriod = accountingPeriods.find(({ id }) => id === amortisation.accountingPeriodIdAllowedToEdit);

      const currentAccountingPeriodStartAt = getMoment(accountingPeriod?.startAt);
      const currentAccountingPeriodEndAt = getMoment(accountingPeriod?.endAt);

      // Calculate the date of the middle of the accounting period
      const currentAccountingPeriodHalfYear = currentAccountingPeriodStartAt.add(
        currentAccountingPeriodEndAt.diff(currentAccountingPeriodStartAt, "days") / 2,
        "days"
      );
      const firstYearMustBeHalfAmortisation =
        amortisationStartAt &&
        currentAccountingPeriodHalfYear.isValid() &&
        amortisationStartAt.isValid() &&
        amortisationStartAt.isBetween(
          getMoment(currentAccountingPeriodHalfYear).set("year", amortisationStartAt.year()),
          getMoment(currentAccountingPeriodEndAt).set("year", amortisationStartAt.year()),
          undefined,
          "[]"
        );

      // If the amortisation is not finished, we sum it
      if (targetedAccountingPeriodStartAt.isSameOrBefore(amortisationEndAt)) {
        // If it is the first year, we must take into account only half of the amortisation
        if (
          amortisationStartAt.isBetween(
            currentAccountingPeriodStartAt,
            currentAccountingPeriodEndAt,
            undefined,
            "[]"
          ) &&
          firstYearMustBeHalfAmortisation
        ) {
          return acc + amortisation.value / (2 * amortisation.durationInYear);
        }
        return acc + amortisation.value / amortisation.durationInYear;
      }

      // If we are at the last + 1 year of the amortisation, we must check if first year has been half amortisation
      if (firstYearMustBeHalfAmortisation && targetedAccountingPeriodStartAt.year() === amortisationEndAt.year() + 1) {
        return acc + amortisation.value / (2 * amortisation.durationInYear);
      }

      return acc;
    }, 0);
};

export const sumAmortisationsExpenseForFixedAsset = ({
  fixedAssetAmortisations,
  accountingPeriods,
  accountingPeriodIdToSum,
  fixedAssets,
  fixedAssetIdToSum,
}: {
  fixedAssetAmortisations: FixedAssetAmortisation[];
  accountingPeriods: AccountingPeriod[];
  accountingPeriodIdToSum: string;
  fixedAssets: FixedAsset[];
  fixedAssetIdToSum: string;
}): number => {
  return sumAllAmortisationsExpenseFixedAsset({
    fixedAssetAmortisations: fixedAssetAmortisations.filter(({ fixedAssetId }) => fixedAssetId === fixedAssetIdToSum),
    accountingPeriods,
    fixedAssets: fixedAssets.filter(({ id }) => id === fixedAssetIdToSum),
    accountingPeriodIdToSum,
  });
};
