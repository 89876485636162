var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"addElementText":"Ajouter une immobilisation","displayAccountingPeriod":"","displayHelperWithId":_vm.ArticleEnum.FIXED_ASSETS_RECOMMENDATION},on:{"add":function($event){return _vm.createFixedAsset()}}},[_vm._v(" Mes immobilisations ")]),_c('HelpingMessage',{staticClass:"mt-3 mb-5",attrs:{"title":"En savoir plus"}},[_c('p',{staticClass:"mb-4"},[_vm._v(" Veuillez ajouter ici les immobilisations que vous aviez avant votre arrivée sur Ownily. "),_c('br'),_c('br'),_vm._v(" 2 types d’immobilisations existe : "),_c('br'),_vm._v(" les travaux immobilisés effectué sur un ou plusieurs biens, d’une valeur de plus de 500€ "),_c('br'),_vm._v(" l’achat de meubles out tout agencement intérieurs concernant un ou plusieurs biens d’une valeur totale supérieur à 500€ "),_c('br'),_c('br'),_vm._v(" Exemple d’immobilisation : "),_c('br'),_vm._v(" Vous faite des travaux de peinture intérieure dans un de vos biens d’une valeur totale supérieure ou égale à 500€, "),_c('br'),_vm._v(" vous devez alors les immobiliser l’année de la fin des travaux (date de mise en service) ")])]),(!_vm.isLoading)?_c('v-row',{attrs:{"id":"fixedAssets-list"}},_vm._l((_vm.fixedAssets),function(fixedAsset){return _c('v-col',{key:fixedAsset.id,attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly
          ? '12'
          : _vm.$vuetify.breakpoint.smOnly
          ? '4'
          : _vm.$vuetify.breakpoint.mdOnly
          ? '6'
          : _vm.$vuetify.breakpoint.lgOnly
          ? '3'
          : '2'}},[_c('v-card',{staticClass:"container",attrs:{"loading":_vm.isLoading,"outlined":""}},[_c('v-card-text',{staticClass:"section"},[_c('div',{staticClass:"section-img"},[_c('v-icon',[_vm._v("mdi-image-frame")])],1),_c('p',{attrs:{"id":"fixedAssets-card-name"}},[_c('b',[_vm._v(_vm._s(fixedAsset.name))])]),(fixedAsset.type)?_c('p',{attrs:{"id":"fixedAssets-card-type"}},[_vm._v(" Type d'immobilisation : "+_vm._s(_vm.getFixedAssetTypeText(fixedAsset.type))+" ")]):_vm._e(),(fixedAsset.commissioningAt)?_c('p',{attrs:{"id":"fixedAssets-card-address"}},[_vm._v(" Date de mise en service : "+_vm._s(_vm.getMoment(fixedAsset.commissioningAt).format("DD/MM/YYYY"))+" ")]):_vm._e(),(fixedAsset.realEstateAssetIds)?_c('p',{attrs:{"id":"fixedAssets-real-estate-asset"}},[_vm._v(" Biens liés : "+_vm._s(_vm.formatRealEstateAssets(fixedAsset.realEstateAssetIds))+" ")]):_vm._e(),_c('div',{staticClass:"section-action"},[_c('v-btn',{staticClass:"btn-link",attrs:{"id":"fixedAsset-card-detail","text":"","color":""},on:{"click":function () { return _vm.gotoFixedAsset(fixedAsset); }}},[_c('span',[_vm._v(" Voir le détail "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye-outline")])],1)])],1)])],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }