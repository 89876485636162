import {
  AnomalyCode,
  AnomalyError,
  AnomalyErrorResult,
  categorizationRules,
  CheckNewAnomaliesParams,
  CheckNewAnomaliesType,
  JournalComposedEntry,
  JournalEntryLine,
  LedgerAccountEnum,
  TypeReference,
} from "..";
import { anomaliesHelpers } from "./anomaliesHelper.lib";

export type CheckTaxTvaAnomaliesParams = Omit<
  CheckNewAnomaliesParams<CheckNewAnomaliesType.transaction>,
  "checkNewAnomaliesType" | "params"
> & {
  operation: JournalComposedEntry;
  line: JournalEntryLine;
};

export const checkTaxTvaAnomalies = ({
  operation,
  line,
  data,
  options,
}: CheckTaxTvaAnomaliesParams): AnomalyErrorResult[] => {
  const { getRentalUnit, hasOptionAnomalyParamsKey, getOptionAnomalyParamsKey } = anomaliesHelpers({ data, options });
  const anomaliesErrorsLine: AnomalyErrorResult[] = [];

  /* Add anomaly if the line account not respect rules. */
  const { taxTvaRules } = categorizationRules;

  if (line.account === LedgerAccountEnum.N445720) {
    const rentalUnitRef = line.refs?.find((ref) => ref.type === TypeReference.rentalUnit);
    /**
     * * Add anomaly if taxTva is not enable but category TVA Collected 445720 is present
     * Checking if the rental unit is enabled for tax tva.
     */
    if (hasOptionAnomalyParamsKey(AnomalyCode.taxTva, "taxTvaEnable")) {
      if (rentalUnitRef && !getOptionAnomalyParamsKey(AnomalyCode.taxTva, "taxTvaEnable")) {
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.taxTvaEnableRequired,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: rentalUnitRef.type,
          toReferenceId: rentalUnitRef.referredId,
        });
      }
    } else {
      if (rentalUnitRef && !taxTvaRules.isEnableForRentalUnit(rentalUnitRef.referredId, getRentalUnit)) {
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.taxTvaEnableRequired,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: rentalUnitRef.type,
          toReferenceId: rentalUnitRef.referredId,
        });
      }
    }

    /**
     * * Add anomaly if amount of category is negative
     * Checking if the amount is positive.
     * ! Disable : https://gitlab.com/edmp/fonctionnel/-/issues/2359
     */
    // if (rentalUnitRef && (!taxTvaRules.tvaCollectedIsPositive(line.amount) || line.direction === "debit")) {
    //   anomaliesErrorsLine.push({
    //     anomalyError: AnomalyError.taxTvaPositiveRequired,
    //     fromReferenceType: "transaction",
    //     fromReferenceId: operation.transactionId,
    //     toReferenceType: rentalUnitRef.type,
    //     toReferenceId: rentalUnitRef.referredId,
    //   });
    // }
  }

  if (line.account === LedgerAccountEnum.N706000) {
    const rentalUnitRef = line.refs?.find((ref) => ref.type === TypeReference.rentalUnit);
    const line445720Exist = !!operation?.journalEntry.lines?.filter(
      (line) => line.account === LedgerAccountEnum.N445720
    ).length;
    /**
     * * Add anomaly if taxTva is enable but no category TVA Collected 445720 is present
     * Checking if the rental unit has a tax tva enabled and if the line 445720 does not exist.
     */
    if (hasOptionAnomalyParamsKey(AnomalyCode.taxTva, "taxTvaEnable")) {
      if (rentalUnitRef && getOptionAnomalyParamsKey(AnomalyCode.taxTva, "taxTvaEnable") && !line445720Exist) {
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.taxTvaLedgerAccount445720Required,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: rentalUnitRef.type,
          toReferenceId: rentalUnitRef.referredId,
        });
      }
    } else {
      const rentalUnitRef = line.refs?.find((ref) => ref.type === TypeReference.rentalUnit);
      if (
        rentalUnitRef &&
        taxTvaRules.isEnableForRentalUnit(rentalUnitRef.referredId, getRentalUnit) &&
        !line445720Exist
      ) {
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.taxTvaLedgerAccount445720Required,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: rentalUnitRef.type,
          toReferenceId: rentalUnitRef.referredId,
        });
      }
    }
  }

  return anomaliesErrorsLine;
};
