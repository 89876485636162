




















































































































































import SCISearch from "@/components/sign/signup/steps/SCISearch.vue";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { VForm } from "@/models";
import { productsStore, usersStore } from "@/store";
import { onlyNumber } from "@/utils";
import {
  ActivitiesModel,
  Address,
  Etablissement,
  ProductsModel,
  ProductsService,
  RequireField,
} from "@edmp/api";
import { defineComponent, ref, Ref, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "RegisterActivitySCI",
  components: {
    SCISearch,
  },
  props: {
    type: {
      type: String as PropType<ProductsModel.Tags>,
      require: false,
    },
  },
  setup(props, context) {
    const dialog: Ref<boolean> = ref(false);
    const validateInProgress: Ref<boolean> = ref(false);

    const activityRegistered: Ref<boolean> = ref(true);
    const name: Ref<string> = ref("");
    const siret: Ref<string> = ref("");
    const address: Ref<RequireField<Address, "street" | "city" | "zip">> = ref({
      street: "",
      city: "",
      zip: "",
    });

    function autocompleteEnterprise(e: Etablissement): void {
      name.value = e.uniteLegale.denominationUniteLegale;
      siret.value = e.siret;
      address.value = {
        street: `${e.adresseEtablissement.numeroVoieEtablissement ?? ""}${
          e.adresseEtablissement.indiceRepetitionEtablissement ?? ""
        } ${e.adresseEtablissement.typeVoieEtablissement ?? ""} ${
          e.adresseEtablissement.libelleVoieEtablissement ?? ""
        }`,
        zip: e.adresseEtablissement.codePostalEtablissement ?? "",
        city: e.adresseEtablissement.libelleCommuneEtablissement ?? "",
      };
      dialog.value = false;
      // focus on last autocompleted field
      requestAnimationFrame(() =>
        (context.refs.siretInput as HTMLElement).focus()
      );
    }

    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;
        try {
          const product: ProductsService.CreateIn = {
            type: ProductsModel.ProductTypes.SCI,
            name: name.value,
            activity: {
              type: ActivitiesModel.ActivityTypes.COMPANY,
              name: name.value,
              siret: siret.value,
              address: address.value,
            },
            accountingPeriods: [],
            ...(props.type ? { tags: [props.type] } : {}),
          };

          await productsStore.createProduct(product);

          dispatchOnBoardingEvent({
            step: OnBoardingEventSteps.ACTIVITY,
            userId: usersStore.loggedInUser.id,
            activityType: "SCI",
          });

          context.emit(
            activityRegistered.value ? "validate" : "validateNonRegistered"
          );
        } catch (err) {
          validateInProgress.value = false;
        }
      }
    }

    return {
      dialog,
      name,
      siret,
      address,
      activityRegistered,
      onlyNumberRule: onlyNumber,
      autocompleteEnterprise,
      validate,
      validateInProgress,
    };
  },
});
