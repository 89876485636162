









































































import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { VForm } from "@/models";
import { usersService } from "@/services";
import { usersStore } from "@/store";
import { UserTags } from "@edmp/api";
import {
  computed,
  defineComponent,
  Ref,
  ref,
  PropType,
} from "@vue/composition-api";

export default defineComponent({
  name: "RegisterUserDetails",
  props: {
    step: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<UserTags>,
      require: false,
    },
  },
  setup(props, context) {
    const firstname: Ref<string> = ref("");
    const lastname: Ref<string> = ref("");
    const phone: Ref<string> = ref("");
    const validateInProgress: Ref<boolean> = ref(false);

    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;
        try {
          await usersService
            .updateUser({
              id: "me",
              firstName: firstname.value,
              lastName: lastname.value,
              phone: phone.value,
              newEmail: usersStore.loggedInUser.newEmail,
              ...(props.type ? { tags: [props.type] } : {}),
            })
            .then((user) => {
              dispatchOnBoardingEvent({
                step: OnBoardingEventSteps.USER_DETAILS,
                userId: user.id,
              });
              usersStore.fetchLoggedInUser();
              context.emit("validate");
            });
        } catch (err) {
          validateInProgress.value = false;
        }
      }
    }

    const phoneValid = computed({
      get: () => phone.value.toLowerCase().trim(),
      set: (value: string) => (phone.value = value.replace(/[^\d]/g, "")),
    });

    return {
      firstname,
      lastname,
      phone,
      phoneValid,
      validate,
      validateInProgress,
    };
  },
});
