var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"container",attrs:{"loading":_vm.isLoading,"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),(!_vm.isNew && !_vm.isEditing)?_c('div',{staticClass:"header"},[_c('h4',{staticClass:"header-title"},[_vm._v(_vm._s(_vm.rentalBuildingCreateOrUpdate.name))]),_c('div',[(!_vm.isEditing)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"header-action",attrs:{"id":"rental-building-update","color":"primary","icon":""},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.updateIsEditing(true)}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,false,3279030949)},[_c('span',[_vm._v("Modifier")])]):_vm._e()],1)]):_vm._e(),(!_vm.isNew || _vm.isEditing)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('v-card-text',{staticClass:"section-body"},[_c('div',{style:({ width: '100%' })},[(_vm.isEditing)?_c('v-text-field',{attrs:{"rules":[
              function () { return !!_vm.rentalBuildingCreateOrUpdate.name ||
                'Le champ ne peut pas être vide'; } ],"label":"Nom de immeuble","id":"rental-building-name","outlined":"","required":"","type":"string"},model:{value:(_vm.rentalBuildingCreateOrUpdate.name),callback:function ($$v) {_vm.$set(_vm.rentalBuildingCreateOrUpdate, "name", $$v)},expression:"rentalBuildingCreateOrUpdate.name"}}):_vm._e(),_c('div',{attrs:{"id":"rental-building-realEstateAssetIds"}},[_c('v-select',{staticClass:"section-body-select",attrs:{"items":_vm.realEstateAssets,"rules":[
                function () { return _vm.selectedRealEstateAssetRules().valid ||
                  _vm.selectedRealEstateAssetRules().message; } ],"deletable-chips":"","label":_vm.isEditing ? 'Sélectionner des biens' : 'Biens sélectionnés',"multiple":"","outlined":"","small-chips":"","disabled":!_vm.isEditing},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('v-checkbox',{staticClass:"mr-1",attrs:{"color":"primary","input-value":_vm.rentalBuildingCreateOrUpdate.realEstateAssetIds
                      ? _vm.rentalBuildingCreateOrUpdate.realEstateAssetIds.includes(
                          item.value
                        )
                      : false}}),_c('div',[_vm._v(" "+_vm._s(item.text)+" ")]),(item.alert)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({style:({
                        position: 'relative',
                        'margin-left': 'auto',
                      }),attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},[_c('span',[_vm._v(" La date d'achat du bien est plus récente que l'année d'exercice. ")])]):_vm._e()]}}],null,false,1889814460),model:{value:(_vm.rentalBuildingCreateOrUpdate.realEstateAssetIds),callback:function ($$v) {_vm.$set(_vm.rentalBuildingCreateOrUpdate, "realEstateAssetIds", $$v)},expression:"rentalBuildingCreateOrUpdate.realEstateAssetIds"}})],1),(_vm.isShowSelectRentalBuildingAdress && _vm.isEditing)?_c('v-select',{staticClass:"section-body-select",attrs:{"items":_vm.realEstateAssetsAdress,"rules":[
              function () { return !!_vm.rentalBuildingCreateOrUpdate.address ||
                'Le champ ne peut pas être vide'; } ],"label":"Sélectionner une adresse","outlined":""},model:{value:(_vm.rentalBuildingCreateOrUpdate.address),callback:function ($$v) {_vm.$set(_vm.rentalBuildingCreateOrUpdate, "address", $$v)},expression:"rentalBuildingCreateOrUpdate.address"}}):_vm._e(),(_vm.isShowSelectRentalBuildingNature && _vm.isEditing)?_c('v-select',{staticClass:"section-body-select",attrs:{"items":_vm.realEstateAssetsNature,"rules":[
              function () { return !!_vm.rentalBuildingCreateOrUpdate.nature ||
                'Le champ ne peut pas être vide'; } ],"label":"Sélectionner une nature","outlined":""},model:{value:(_vm.rentalBuildingCreateOrUpdate.nature),callback:function ($$v) {_vm.$set(_vm.rentalBuildingCreateOrUpdate, "nature", $$v)},expression:"rentalBuildingCreateOrUpdate.nature"}}):_vm._e()],1)]),(_vm.isEditing)?_c('v-card-text',{staticClass:"section-action pt-0"},[(!_vm.isNew)?_c('v-btn',{staticClass:"position-left",attrs:{"id":"rental-building-remove","color":"error","depressed":"","outlined":""},on:{"click":_vm.remove}},[_vm._v(" Supprimer ")]):_vm._e(),_c('v-btn',{staticClass:"position-middle",attrs:{"id":"rental-building-cancel","color":"primary","depressed":"","outlined":""},on:{"click":_vm.cancel}},[_vm._v(" Annuler ")]),_c('v-btn',{staticClass:"position-right",attrs:{"id":"rental-building-validate","loading":_vm.isLoadingValidate,"color":"primary","depressed":"","type":"submit"}},[_vm._v(" Valider ")])],1):_vm._e()],1):_vm._e(),(!_vm.isNew && !_vm.isEditing)?_c('div',{staticClass:"header"},[_c('h4',{staticClass:"header-title"},[_vm._v("Détails de l'immeuble")])]):_vm._e(),(!_vm.isNew && !_vm.isEditing)?_c('v-card-text',{staticClass:"section-table pt-0"},[_c('tr',[_c('td',[_vm._v("Adresse postale")]),_c('td',[(_vm.rentalBuildingCreateOrUpdate.address)?_c('span',[_vm._v(" "+_vm._s(_vm.rentalBuildingCreateOrUpdate.address.street)+", "+_vm._s(_vm.rentalBuildingCreateOrUpdate.address.zip)+" "+_vm._s(_vm.rentalBuildingCreateOrUpdate.address.city)+" ")]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Regime fiscal")]),_c('td',[(_vm.rentalBuildingCreateOrUpdate.taxDeduction)?_c('span',[_vm._v(" "+_vm._s(_vm.rentalBuildingCreateOrUpdate.taxDeduction === _vm.IR2072Deductions.NONE ? "Aucun" : _vm.rentalBuildingCreateOrUpdate.taxDeduction .charAt(0) .toUpperCase() + _vm.rentalBuildingCreateOrUpdate.taxDeduction .slice(1) .split("_") .join(" "))+" "),(
                _vm.selectedRealEstateAssetRules().name ===
                'NOT_EQUAL_TAX_DEDUCTION'
              )?_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.updateIsEditing(true)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-alert-outline ")])],1):_vm._e()],1):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Nature")]),_c('td',[(_vm.rentalBuildingCreateOrUpdate.nature)?_c('span',[_vm._v(" "+_vm._s(_vm.formatNature())+" "),(
                _vm.selectedRealEstateAssetRules().name === 'NOT_EQUAL_NATURE'
              )?_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.updateIsEditing(true)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-alert-outline ")])],1):_vm._e()],1):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Locaux")]),_c('td',[(_vm.rentalBuildingCreateOrUpdate.premises !== undefined)?_c('span',[_vm._v(" "+_vm._s(_vm.rentalBuildingCreateOrUpdate.premises)+" ")]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Nouvelle acquisition")]),_c('td',[(_vm.rentalBuildingCreateOrUpdate.newAcquisition !== undefined)?_c('span',[_vm._v(" "+_vm._s(_vm.rentalBuildingCreateOrUpdate.newAcquisition ? "Oui" : "Non")+" "),(
                _vm.selectedRealEstateAssetRules().name ===
                'NOT_EQUAL_NEW_ACQUISITION'
              )?_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.updateIsEditing(true)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-alert-outline ")])],1):_vm._e()],1):_vm._e()])])]):_vm._e(),(_vm.isNew && !_vm.isEditing)?_c('v-card-text',{staticClass:"section-action fill-height"},[_c('v-btn',{staticClass:"section-action-new",attrs:{"id":"rental-building-add","text":"","color":"grey"},on:{"click":function($event){return _vm.updateIsEditing(true)}}},[_c('v-icon',{attrs:{"x-large":"","color":"grey"}},[_vm._v("mdi-plus-circle")]),_vm._v(" Ajouter un immeuble ")],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }