import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { DocumentFileType, ProductsModel, RequireField, SubscriptionsModel } from "..";
import { Address, addressSchema } from "./Common.model";
import { RealEstateAsset } from "./RealEstateAsset.model";
import { RentalUnit } from "./RentalUnit.model";

export enum TenantTypeEnum {
  NATURAL_PERSON = "natural_person",
  ASSOCIATION = "association",
  COMPANY = "company",
}

export enum DocumentModelType {
  RENT_RECEIPT = "Quittance de loyer",
  RENT_RECEIPT_WITH_VAT = "Quittance de loyer avec TVA",
  RENT_RECEIPT_WITH_APL = "Quittance de loyer avec APL",
  RENT_RECEIPT_WITH_VAT_AND_APL = "Quittance de loyer avec TVA et APL",

  RENT_CALL = "Appel de loyer",
  RENT_CALL_WITH_VAT = "Appel de loyer avec TVA",
  RENT_CALL_WITH_APL = "Appel de loyer avec APL",
  RENT_CALL_WITH_VAT_AND_APL = "Appel de loyer avec TVA et APL",

  NOTICE_ADJUSTMENT_OF_CHARGES_LESSOR = "Préavis de régularisation des charges - Bailleur",
  NOTICE_ADJUSTMENT_OF_CHARGES_TENANT = "Préavis de régularisation des charges - Locataire", 
  NOTICE_RENT_REVIEW = "Préavis de révision du loyer",

  MOBILITY_LEASE = "Bail mobilité",
  FURNISHED_LEASE = "Bail location meuble", 
  UNFURNISHED_LEASE = "Bail location nue", 
  
  FORMAL_NOTICE = "Mise en demeure", 

  REMINDER_INSURANCE_CERTIFICATE = "Relance attestation d'assurance habitation",
  REMINDER_OF_UNPAID_RENT = "Relance de loyer impayé",

  DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_A_FIXED_TERM = "Acte de cautionnement solidaire à durée déterminée", 
  DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_AN_INDEFINITE_TERM  = "Acte de cautionnement solidaire à durée indéterminée", 
  DEED_OF_SIMPLE_SURETY_FOR_A_FIXED_TERM  = "Acte de cautionnement simple à durée déterminée", 
  DEED_OF_SIMPLE_SURETY_FOR_AN_INDEFINITE_TERM  = "Acte de cautionnement simple à durée indéterminée",

  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT = "Avenant au bail - Arrivée d'un nouveau locataire",
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE = "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité",
  LEASE_AMENDMENT_CHANGE_OF_TENANT = "Avenant au bail - Changement de locataire",
  LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT = "Avenant au bail - Départ d'un locataire",
  LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE = "Avenant au bail - Prolongation d'un bail mobilité",
  LEASE_AMENDMENT_PAYMENT_DATE_CHANGE = "Avenant au bail - Décalage de date de paiement",
  LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD = "Avenant au bail - Franchise temporaire de loyer",
}

export const isRentReceiptModelType = (modelType: DocumentModelType): boolean =>
  [
    DocumentModelType.RENT_RECEIPT,
    DocumentModelType.RENT_RECEIPT_WITH_VAT,
    DocumentModelType.RENT_RECEIPT_WITH_APL,
    DocumentModelType.RENT_RECEIPT_WITH_VAT_AND_APL,

  ].includes(modelType);

  export const isRentCallModelType = (modelType: DocumentModelType): boolean =>
    [
      DocumentModelType.RENT_CALL,
      DocumentModelType.RENT_CALL_WITH_VAT,
      DocumentModelType.RENT_CALL_WITH_APL,
      DocumentModelType.RENT_CALL_WITH_VAT_AND_APL,
  
    ].includes(modelType);
export interface RentalAgreementsFilter {
  realEstateAssetId?: string;
  rentalAgreementActive?: boolean;
}

// TODO Uniformiser avec la personne morale ou physique ?
export interface Representative {
  legalStatus?: string;
  denomination?: string;
  name?: string;
  firstName?: string;
  siret?: string;
  email?: string;
  phone?: string;
  address?: Address;
}

const representativeSchema = new Schema<Representative>({
  name: String,
  firstName: String,
  email: String,
  phone: String,
  address: addressSchema,
});

export interface Tenant {
  name?: string;
  denomination?: string;
  numSIREN?: string;
  numTVA?: string;
  firstName?: string;
  email?: string;
  phone?: string;
  address?: Address;
  type?: TenantTypeEnum;
  representative?: Representative;
}

export type TenantDocument = Tenant & Document<string>;

const tenantSchema = new Schema<TenantDocument>({
  _id: { type: String, default: () => ulid() },
  denomination: String,
  siret: String,
  name: String,
  numSIREN: String,
  numTVA: String,
  firstName: String,
  email: String,
  phone: String,
  address: addressSchema,
  type: {
    type: String,
    enum: Object.values(TenantTypeEnum), // Mongoose doesn't support Typescript ENUM
  },
  representative: representativeSchema,
});

export type RentalAgreementOptions = {
  rentCallActivated: boolean;
} & {
  rentReceiptActivated: boolean;
  reminderUnpaidRentActivated: boolean;
  unpaidRentCallHasToCopyActivity: boolean;
} & (
    | {
        rentCallHasToCopyActivity: false;
        rentCallNumberDayBeforeRent?: number;
        rentPaymentDayOfTheMonth?: number;
      }
    | {
        rentCallHasToCopyActivity: true;
        rentCallNumberDayBeforeRent: number;
        rentPaymentDayOfTheMonth: number;
      }
  ) &
  (
    | {
        rentReceiptHasToCopyActivity: false;
        reminderRentCallNumberDayAfter?: number;
      }
    | {
        rentReceiptHasToCopyActivity: true;
        reminderRentCallNumberDayAfter: number;
      }
  );

export type RentalAgreementOptionsDocument = RentalAgreementOptions & Document<string>;

const RentalAgreementOptionsSchema = new Schema<RentalAgreementOptionsDocument>(
  {
    rentCallActivated: Boolean,
    rentReceiptActivated: Boolean,
    reminderUnpaidRentActivated: Boolean,
    rentCallHasToCopyActivity: Boolean,
    rentCallNumberDayBeforeRent: Number,
    rentPaymentDayOfTheMonth: Number,
    rentReceiptHasToCopyActivity: Boolean,
    reminderRentCallNumberDayAfter: Number,
    unpaidRentCallHasToCopyActivity: Boolean,
  },
  { _id: false }
);

export type RentReceiptDetails = {
  rentAmount?: number;
  rentAmountCharge?: number;
  rentAmountTVA?: number;
  rentAmountTotal?: number;
};

export type RentReceiptDetailsDocument = RentReceiptDetails & Document<string>;

export interface NewRentalAgreement {
  startAt?: string;
  endAt?: string;
  product: {
    id: string;
    realEstateAsset?: {
      id: string;
      rentalUnit?: {
        id: string;
      };
    };
  };
  tenant: Tenant;
  options?: RentalAgreementOptions;
}

/**
 * `RentalAgreement` — A rental agreement
 */
export type StatsRentalAgreementsWithNotSendNotificationRentReceipt =
  | {
      userId: string | null;
      userEmail: string | null;
      productId: string;
      productName: string | null;
      subscriptionPlan: SubscriptionsModel.PlanType;
      subscriptionEndAt: string;
      realEstateAssetId: string | null;
      realEstateAssetName: string | null;
      rentalAgreementFirstName: string | null;
      rentalAgreementName: string | null;
      rentalAgreementDenomination: string | null;
      sendRentReceiptEnable: boolean;
      "sendRentReceipt.error.message"?: string;
      "sendRentReceipt.success"?: boolean;
      [key: `transaction.${number}.id`]: string;
      [key: `transaction.${number}.date`]: string;
    }
  | { noData: "No data" };

export interface RentalAgreement extends NewRentalAgreement {
  id: string;
  // Virtual (expand=xx)
  expand?: {
    product?: ProductsModel.Product;
    realEstateAsset?: RealEstateAsset;
    rentalUnit?: RentalUnit;
  };
  createdAt: string;
  updatedAt: string;
}

export type RentalAgreementUpdate = RequireField<
  Partial<Omit<RentalAgreement, "product" | "expand" | "createdAt" | "updatedAt">>,
  "id"
>;
export type RentalAgreementUpdateInternal = RequireField<
  Partial<Omit<RentalAgreement, "expand" | "createdAt" | "updatedAt">>,
  "id" | "product"
>;

const rentalAgreementSchema = new Schema<RentalAgreementDocument>(
  {
    _id: { type: String, default: () => ulid() },
    startAt: String,
    endAt: String,
    product: {
      id: { type: String, index: true },
      realEstateAsset: {
        id: { type: String, index: true },
        rentalUnit: {
          id: { type: String, index: true },
        },
      },
    },
    tenant: tenantSchema,
    options: RentalAgreementOptionsSchema,
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RentalAgreementDocument) {
        ret.id = ret._id;

        // TODO: Delete this line when fixed directly in database
        if (ret.tenant) ret.tenant.type ??= TenantTypeEnum.NATURAL_PERSON;
        delete ret._id;

        return ret;
      },
    },
  }
);

export type RentalAgreementDocument = RentalAgreement & Document<string>;

// Name of the collection in third argument
export const RentalAgreementModel = model<RentalAgreementDocument>(
  "RentalAgreement",
  rentalAgreementSchema,
  "RentalAgreements"
);

// API
export namespace RentalAgreementsService {
  export type CreateIn = NewRentalAgreement;
  export type CreateOut = RentalAgreement;

  export type ListIn = { productId?: string; realEstateAssetId?: string; rentalAgreementActive?: boolean };
  export type ListOut = RentalAgreement[];

  export type GetIn = Pick<RentalAgreement, "id">;
  export type GetOut = RentalAgreement;

  export type UpdateIn = RentalAgreementUpdate;
  export type UpdateOut = RentalAgreement;

  export type DeleteIn = Pick<RentalAgreement, "id">;
  export type DeleteOut = boolean;

  export type ReceiptIn = Pick<RentalAgreement, "id"> & {
    productId: string;
    bankAccountId: string;
    transactionId: string;
    rentPeriod: string;
    rentAmount: number;
    rentAmountCharge: number;
    rentAmountTVA?: number | undefined;
    rentAmountTotal: number;
    dateOperation: string;
  };
  export type ReceiptOut = Buffer;

  export type DocumentModelIn = { modelType: DocumentModelType; fileType: DocumentFileType };
  export type DocumentModelOut = Buffer;
}

export namespace RentsService {
  export type SendNotificationRentCallIn = never;
  export type SendNotificationRentCallOut = void;

  export type SendUnpaidRentReminderCallIn = never;
  export type SendUnpaidRentReminderCallOut = void;

  export type SendBankIncorrectRentServicesIn = never;
  export type SendBankIncorrectRentServicesOut = void;

  export type DetectNotSendNotificationRentReceiptIn = {
    afterDate: string;
    exportType?: "json" | "csv";
    sendEmail?: boolean;
    sendRentReceipt?: boolean;
  };
  export type DetectNotSendNotificationRentReceiptOut =
    | StatsRentalAgreementsWithNotSendNotificationRentReceipt[]
    | string;
}
