



























































































































































































































































import SCISearch from "@/components/sign/signup/steps/SCISearch.vue";
import { VForm } from "@/models";
import { activitiesStore, productsStore } from "@/store";
import { onlyNumber } from "@/utils";
import { ActivitiesModel, Etablissement, ProductsModel } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { YesNoSwitch } from "@/components/atom/switch";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "RegisterMyBusinessLMNP",
  components: { SCISearch, YesNoSwitch },
  props: { actions: { type: String as PropType<"create" | "update"> } },

  setup(props, context) {
    const dialog: Ref<boolean> = ref(false);
    const isLoading: Ref<boolean> = ref(false);
    const isEditing: Ref<boolean> = ref(false);
    const isNew: Ref<boolean> = ref(false);

    const product = computed(() => productsStore.currentProduct);

    const activityAddressIsSameAsOperatorAddress: Ref<boolean> = ref(true);

    const productForm = ref<
      Omit<
        ProductsModel.ProductCreate<ProductsModel.ProductTypes.LMNP>,
        "type" | "accountingPeriods" | "dedicatedBankAccount" | "activity"
      > & {
        activity: Omit<
          ActivitiesModel.ActivityCreate<
            ActivitiesModel.ActivityTypes.OPERATOR,
            ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
          >,
          "type" | "operatorType" | "hasCga"
        >;
      }
    >({
      name: "",
      activity: {
        lastName: "",
        firstName: "",
        siret: "",
        indieChecked: false,
        address: { street: "", city: "", zip: "" },
        activityAddress: { street: "", city: "", zip: "" },
      },
    });

    const rules = computed(() => ({
      operatorLastName: () =>
        !!productForm.value.activity.lastName || "Renseignez un nom",
      operatorFirstName: () =>
        !!productForm.value.activity.firstName || "Renseignez un prénom",
      activityName: () =>
        !!productForm.value.name || "Renseignez le nom de votre activité",
      activitySiret: () =>
        !!productForm.value.activity.siret ||
        (productForm.value.activity.siret &&
          onlyNumber(productForm.value.activity.siret) &&
          productForm.value.activity.siret.length === 14) ||
        "Le format de votre SIRET est incorrect",
      operatorZip: () =>
        (!!productForm.value.activity.address?.zip &&
          onlyNumber(productForm.value.activity.address?.zip)) ||
        "Le format du code postal de votre activité est incorrect",
      operatorCity: () =>
        !!productForm.value.activity.address?.city ||
        "Renseignez la ville associée à votre activité",
      operatorStreet: () =>
        !!productForm.value.activity.address?.street ||
        "Renseignez la rue associée à votre activité",
    }));

    function autocompleteEnterprise(e: Etablissement): void {
      productForm.value.activity.siret = e.siret;
      productForm.value.activity.address = {
        street: `${e.adresseEtablissement.numeroVoieEtablissement ?? ""}${
          e.adresseEtablissement.indiceRepetitionEtablissement ?? ""
        } ${e.adresseEtablissement.typeVoieEtablissement ?? ""} ${
          e.adresseEtablissement.libelleVoieEtablissement ?? ""
        }`,
        zip: e.adresseEtablissement.codePostalEtablissement ?? "",
        city: e.adresseEtablissement.libelleCommuneEtablissement ?? "",
      };
      dialog.value = false;
      // focus on last autocompleted field
      requestAnimationFrame(() =>
        (context.refs.siretInput as HTMLElement).focus()
      );
    }

    const cancelUpdate = async () => {
      isEditing.value = false;
      await init();
    };

    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        isLoading.value = true;
        try {
          if (isNew.value || !product.value?.id) {
            await productsStore.createProduct({
              type: ProductsModel.ProductTypes.LMNP,
              name: productForm.value.name,
              activity: {
                type: ActivitiesModel.ActivityTypes.OPERATOR,
                operatorType:
                  ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON,
                firstName: productForm.value.activity.firstName,
                lastName: productForm.value.activity.lastName,
                siret: productForm.value.activity.siret,
                address: productForm.value.activity.address,
                activityAddress: activityAddressIsSameAsOperatorAddress.value
                  ? productForm.value.activity.address
                  : productForm.value.activity.activityAddress,
                indieChecked: false,
                hasCga: false,
              },
              dedicatedBankAccount: false,
              accountingPeriods: [],
            });
          } else {
            await productsStore.updateProduct({
              id: product.value.id,
              name: productForm.value.name,
            });
            await activitiesStore.updateActivity({
              id: product.value.activity.id,
              type: ActivitiesModel.ActivityTypes.OPERATOR,
              operatorType:
                ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON,
              firstName: productForm.value.activity.firstName,
              lastName: productForm.value.activity.lastName,
              siret: productForm.value.activity.siret,
              address: productForm.value.activity.address,
              activityAddress: activityAddressIsSameAsOperatorAddress.value
                ? productForm.value.activity.address
                : productForm.value.activity.activityAddress,
            });
          }
        } finally {
          isLoading.value = false;
          await init();
        }
      }
    }

    /**
     * Init
     */
    async function init() {
      isLoading.value = true;
      isEditing.value = false;
      isNew.value = false;
      let activity: ActivitiesModel.Activity<
        ActivitiesModel.ActivityTypes.OPERATOR,
        ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
      >;

      if (!props.actions || props.actions === "update") {
        activity = Object.assign(
          {
            type: ActivitiesModel.ActivityTypes.OPERATOR,
            address: { street: "", city: "", zip: "" },
          },
          {
            ...cloneDeep(product.value?.activity ?? {}),
          } as ActivitiesModel.Activity<
            ActivitiesModel.ActivityTypes.OPERATOR,
            ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
          >
        );
      } else {
        activity =
          productsStore.currentProduct?.status ===
          ProductsModel.ProductStatus.pending
            ? Object.assign(
                {
                  type: ActivitiesModel.ActivityTypes.OPERATOR,
                  address: { street: "", city: "", zip: "" },
                },
                {
                  ...JSON.parse(
                    JSON.stringify(productsStore.currentProduct.activity)
                  ),
                }
              )
            : {
                type: ActivitiesModel.ActivityTypes.OPERATOR,
                name: "",
                siret: "",
                ape: "",
                address: { street: "", zip: "", city: "" },
              };
        if (
          !(
            activity as ActivitiesModel.Activity<
              ActivitiesModel.ActivityTypes.OPERATOR,
              ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
            >
          ).id
        ) {
          isNew.value = true;
          isEditing.value = true;
        }
      }
      productForm.value = {
        name: product.value?.name || "",
        activity: {
          lastName: activity.lastName || "",
          firstName: activity.firstName || "",
          siret: activity.siret || "",
          indieChecked: activity.indieChecked || false,
          address: {
            street: activity.address?.street || "",
            city: activity.address?.city || "",
            zip: activity.address?.zip || "",
          },
          activityAddress: {
            street: activity.activityAddress?.street || "",
            city: activity.activityAddress?.city || "",
            zip: activity.activityAddress?.zip || "",
          },
        },
      };
      isLoading.value = false;
    }

    watch(
      () => [productsStore.currentProduct],
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      isEditing,
      productForm,
      dialog,
      activityAddressIsSameAsOperatorAddress,
      rules,
      autocompleteEnterprise,
      validate,
      cancelUpdate,
      isLoading,
    };
  },
});
