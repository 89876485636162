























































import { ROUTE_NAMES } from "@/router/routes";
import { accountingPeriodsStore } from "@/store";
import { TaxRegime } from "@edmp/api";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Welcome",
  setup(params, context) {
    const isLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );
    return {
      isLMNP,
      beginTour: () => {
        // Delete query param welcome
        context.root.$router.push({ name: ROUTE_NAMES.TaskActivity });
        context.emit("close");
      },
    };
  },
});
