





































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
} from "@vue/composition-api";

import Title from "../title/Title.vue";
import DialogRight from "@/components/core/DialogRight.vue";
import RentalAgreementModal from "@/components/core/realEstate/RentalAgreementModal.vue";
import RentalAgreementsFilterModal from "./RentalAgreementsFilter.vue";
import {
  accountingPeriodsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  subscriptionsStore,
  taskActivityStore,
} from "@/store";
import {
  getMoment,
  SubscriptionsModel,
  RealEstateAsset,
  RentalAgreement,
  TenantTypeEnum,
  TaskCode,
} from "@edmp/api";
import eventBus from "@/events/eventBus";

export default defineComponent({
  name: "RentalAgreementCard",
  components: {
    DialogRight,
    Title,
    RentalAgreementModal,
    RentalAgreementsFilterModal,
  },
  props: {
    rentalAgreementId: {
      type: String,
      require: true,
    },
  },

  setup(props) {
    // A boolean value that is set to true.
    const isOpenFilterRentalAgreements: Ref<boolean> = ref(false);
    // A reference to an array of numbers.
    const selectedRentalAgreement: Ref<RentalAgreement | undefined> = ref();
    const selectedRentalAgreementRealEstateAsset: Ref<
      RealEstateAsset | undefined
    > = ref();
    const displayRentalAgreementModal: Ref<boolean | undefined> = ref();
    const stepSelected: Ref<Number | undefined> = ref(0);
    // A computed property that returns an array of objects.
    // A computed property that returns a string.
    // Watching the viewAllSelected and if it changes it will set the transactionsFilter.value to
    // undefined and viewCatetoriesSelected.value to an empty array.
    const rentalAgreement = computed(() => {
      if (props.rentalAgreementId)
        return rentalAgreementsStore.getRentalAgreement(
          props.rentalAgreementId
        );
    });
    const subscription = computed(() => {
      return subscriptionsStore.getCurrentSubscription;
    });
    const getRealEstateAssetNameFromTenant = (
      rentalAgreement: RentalAgreement
    ) => {
      if (rentalAgreement.product.realEstateAsset?.id)
        return realEstateAssetsStore.getRealEstateAsset(
          rentalAgreement.product.realEstateAsset?.id
        )?.name;
    };

    /* return 0 si actif, 1 si contrat expiré, 2 si contrat pas encore actif*/
    const getStatusOfTenant = (rentalAgreement: RentalAgreement) => {
      const dateOfTheDay = getMoment();
      if (
        rentalAgreement.endAt &&
        getMoment(rentalAgreement.endAt).isBefore(dateOfTheDay)
      ) {
        return 1;
      }
      if (
        rentalAgreement.startAt &&
        getMoment(rentalAgreement.startAt).isAfter(dateOfTheDay)
      ) {
        return 2;
      }
      return 0;
    };
    function openDetails(ra: RentalAgreement, step: Number) {
      selectedRentalAgreement.value = ra;
      stepSelected.value = step;
      if (ra.product.realEstateAsset?.id) {
        selectedRentalAgreementRealEstateAsset.value =
          realEstateAssetsStore.getRealEstateAsset(
            ra.product.realEstateAsset?.id
          );

        displayRentalAgreementModal.value = true;
      }
    }
    const closeDetails = () => {
      displayRentalAgreementModal.value = false;
      selectedRentalAgreement.value = undefined;
    };
    const bankCorrect = computed(() => {
      const stepBankCorrect =
        taskActivityStore.userNotificationsTaskActivities.find(
          (taskActivity) => taskActivity.code === TaskCode.BankCorrect
        );

      if (stepBankCorrect) {
        return false;
      } else {
        return true;
      }
    });

    onMounted(() => {
      eventBus.$on("closeRentalAgreementModal", closeDetails);
    });
    return {
      bankCorrect,
      subscription,
      stepSelected,
      selectedRentalAgreementRealEstateAsset,
      selectedRentalAgreement,
      displayRentalAgreementModal,
      openDetails,
      closeDetails,
      rentalAgreement,
      isOpenFilterRentalAgreements,
      getRealEstateAssetNameFromTenant,
      getStatusOfTenant,
      PlanType: SubscriptionsModel.PlanType,
      getMoment,
      TenantTypeEnum,
      isLMNP: computed(() => accountingPeriodsStore.isLMNP),
    };
  },
});
