import { OneOf } from "@edmp/api";

export const OnBoardingEventType = "OnBoardingEvent" as const;

export enum OnBoardingEventSteps {
  USER_ACCOUNT = "userAccount",
  USER_DETAILS = "userDetails",
  ACTIVITY = "activity",
  ACTIVITY_DETAILS = "activityDetails",
  BANK_ACCOUNT = "bankAccount",
}

export type OnBoardingEventData<
  OnBoardingEventStep extends OnBoardingEventSteps = OnBoardingEventSteps
> = {
  step: OnBoardingEventStep;
  userId: string;
} & (OnBoardingEventStep extends OnBoardingEventSteps.USER_ACCOUNT
  ? { step: OnBoardingEventSteps.USER_ACCOUNT }
  : OnBoardingEventStep extends OnBoardingEventSteps.USER_DETAILS
  ? { step: OnBoardingEventSteps.USER_DETAILS }
  : OnBoardingEventStep extends OnBoardingEventSteps.ACTIVITY
  ? { step: OnBoardingEventSteps.ACTIVITY; activityType: "SCI" | "LMNP" }
  : OnBoardingEventStep extends OnBoardingEventSteps.ACTIVITY_DETAILS
  ? {
      step: OnBoardingEventSteps.ACTIVITY_DETAILS;
      productId: string;
      taxRegime: "IR" | "IS" | "LMNP";
    }
  : OnBoardingEventStep extends OnBoardingEventSteps.BANK_ACCOUNT
  ? {
      step: OnBoardingEventSteps.BANK_ACCOUNT;
      productId: string;
      requestForAccompanied: boolean;
    } & OneOf<{ isCanceled: boolean }, { isAdded: boolean }>
  : never);

export type OnBoardingEvent = CustomEvent<OnBoardingEventData>;

export function dispatchOnBoardingEvent(data: OnBoardingEventData) {
  dispatchEvent(
    new CustomEvent<OnBoardingEventData>(OnBoardingEventType, { detail: data })
  );
}
